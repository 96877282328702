import { inject, injectable } from 'inversify'
import axios from 'axios'
import { GraphQLErrorsWithExtensions, IAppCredentials, IAuthService, IPreferences } from '@/types'
import symbols from '@/symbols'
import ExpectedError from '@/errors/ExpectedError'
import { isBrowser } from '@/utils'
import UnauthenticatedError from '@/errors/UnauthenticatedError'

@injectable()
export default class AuthAPIGateway implements IAuthService {
  @inject(symbols.IAppCredentials) private credentials: IAppCredentials

  @inject(symbols.IPreferences) private preferences: IPreferences

  private async _post<T>(query: string, variables: Record<string, unknown>): Promise<T & GraphQLErrorsWithExtensions> {
    const url = `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/graphql_auth`
    const result = await axios.post<T & GraphQLErrorsWithExtensions>(
      url,
      {
        query,
        variables,
      },
      {
        headers: this._headers(),
      }
    )

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const newAccessToken = typeof result.headers['access-token'] === 'string' ? result.headers['access-token'] : ''
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    const uid = typeof result.headers.uid === 'string' ? result.headers.uid : ''
    this._handleUnauthenticated(this.credentials.accessToken, uid)
    this._setNewAccessToken(newAccessToken)

    return result.data
  }

  // TODO: AppAPIGateway からコピペしてきた処理を共通化する
  private _setNewAccessToken(newAccessToken: string): void {
    // 同時に複数 post した場合、新しい access-token はひとつのレスポンスにしか返ってこないため
    // access-token が空でないときのみ値を更新する
    if (isBrowser() && newAccessToken) {
      this.credentials.updateAccessToken(newAccessToken)
    }
  }

  // ログイン済で accessToken をつけてるのに uid が返ってこないのは
  // 保持してる accessToken が期限切れのためなので認証エラーにする
  private _handleUnauthenticated(oldAccessToken: string, uid: string | null): void {
    if (!oldAccessToken) {
      // oldAccessToken がないのはログインしてないケース
      return
    }

    if (uid) {
      // uid が返ってくるのは API に渡した oldAccessToken が有効なケース
      return
    }

    throw new UnauthenticatedError('Uid is empty on Response Header.')
  }

  // API へリクエストする際に使用する headers を返すメソッド
  private _headers(): HeadersInit {
    const credentials = this.credentials.getLatestCredentials()
    return {
      'X-PREFERRED-LANGUAGE': this.preferences.language,
      'access-token': credentials.accessToken || '',
      uid: credentials.uid || '',
      client: credentials.client || '',
    }
  }

  async signUp(
    name: string,
    username: string,
    email: string,
    password: string,
    passwordConfirmation: string,
    token?: string
  ): Promise<void> {
    const query = `
    mutation userSignUp ($email: String!, $password: String!, $passwordConfirmation: String!, $confirmSuccessUrl: String, $name: String!, $username: String, $token: String) {
      userSignUp (email: $email, password: $password, passwordConfirmation: $passwordConfirmation, confirmSuccessUrl: $confirmSuccessUrl, name: $name, username: $username, token: $token) {
        authenticatable {
          email
        }
        credentials {
          accessToken
          client
          expiry
          tokenType
          uid
        }
      }
    }
    `

    type Payload = {
      data: {
        userSignUp: {
          authenticatable: {
            email: string
          }
          credentials: IAppCredentials
        }
      }
    }

    const result = await this._post<Payload>(query, {
      name,
      username,
      email,
      password,
      passwordConfirmation,
      confirmSuccessUrl: `${process.env.NEXT_PUBLIC_APP_BASE_URL}/${this.preferences.language}/signin`, // 確認メールのリンクをクリックしたあと、バックエンドのURLからリダイレクトされるURL
      token,
    })

    // devise で confirmable を利用しているとき credentials は返ってこないのでその前提で処理する
    if (!result.data.userSignUp) {
      if (result.errors) {
        // 詳細なエラーがあればそれを throw
        // detailed_errors として「メールアドレスはすでに存在します」とかが返ってくる
        if (result.errors[0]?.extensions?.detailed_errors) {
          throw new ExpectedError(result.errors[0].extensions.detailed_errors[0])
        }
      }

      throw new Error()
    }
  }

  async signIn(email: string, password: string): Promise<IAppCredentials> {
    const query = `
    mutation userLogin ($email: String!, $password: String!) {
      userLogin (email: $email, password: $password) {
        authenticatable {
          email
        }
        credentials {
          accessToken
          client
          expiry
          tokenType
          uid
        }
      }
    }
    `

    type Payload = {
      data: {
        userLogin: {
          authenticatable: {
            email: string
          }
          credentials: IAppCredentials
        }
      }
    }

    const result = await this._post<Payload>(query, {
      email,
      password,
    })

    if (!result.data.userLogin) {
      if (result.errors[0]?.extensions?.code === 'USER_ERROR') {
        throw new ExpectedError(result.errors[0].message)
      }

      throw new Error('ログインに失敗しました。')
    }

    return result.data.userLogin.credentials
  }

  async signOut(): Promise<void> {
    const query = `
    mutation userLogout {
      userLogout {
        authenticatable {
          email
        }
      }
    }
    `

    type Payload = {
      data: {
        userLogout: {
          authenticatable: {
            email: string
          }
        }
      }
    }

    const result = await this._post<Payload>(query, {})

    if (!result.data?.userLogout?.authenticatable) {
      if (result.errors[0]?.extensions.code === 'USER_ERROR') {
        throw new UnauthenticatedError('User was not found or was not logged in.')
      }

      throw new Error('ログアウトに失敗しました。')
    }
  }

  async updatePassword(password: string, passwordConfirmation: string, currentPassword: string): Promise<void> {
    const query = `
    mutation userUpdatePassword ($password: String!, $passwordConfirmation: String!, $currentPassword: String) {
      userUpdatePassword (password: $password, passwordConfirmation: $passwordConfirmation, currentPassword: $currentPassword) {
        authenticatable {
            email
        }
      }
    }
    `

    type Payload = {
      data: {
        userUpdatePassword: {
          authenticatable: {
            email: string
          }
        }
      }
    }

    const result = await this._post<Payload>(query, {
      password,
      passwordConfirmation,
      currentPassword,
    })

    if (!result.data.userUpdatePassword.authenticatable) {
      if (result.errors) {
        throw new Error(result.errors[0].message)
      }

      throw new Error('パスワードの変更に失敗しました。')
    }
  }

  async updatePasswordWithToken(
    resetPasswordToken: string,
    password: string,
    passwordConfirmation: string
  ): Promise<void> {
    const query = `
    mutation userUpdatePasswordWithToken ($password: String!, $passwordConfirmation: String!, $resetPasswordToken: String!) {
      userUpdatePasswordWithToken (password: $password, passwordConfirmation: $passwordConfirmation, resetPasswordToken: $resetPasswordToken) {
        authenticatable {
          email
        }
      }
    }
    `

    type Payload = {
      data: {
        userUpdatePasswordWithToken: {
          authenticatable: {
            email: string
          }
        }
      }
    }

    const result = await this._post<Payload>(query, {
      resetPasswordToken,
      password,
      passwordConfirmation,
    })

    if (!result.data.userUpdatePasswordWithToken.authenticatable) {
      if (result.errors) {
        throw new Error(result.errors[0].message)
      }

      throw new Error('パスワードのリセットに失敗しました。')
    }
  }

  async sendPasswordResetWithToken(email: string, redirectUrl = ''): Promise<void> {
    const query = `
    mutation userSendPasswordResetWithToken ($email: String!, $redirectUrl: String!) {
      userSendPasswordResetWithToken (email: $email, redirectUrl: $redirectUrl) {
        message
      }
    }
    `

    type Payload = {
      data: {
        userSendPasswordResetWithToken: {
          message: string
        }
      }
    }

    const result = await this._post<Payload>(query, {
      email,
      redirectUrl:
        redirectUrl || `${process.env.NEXT_PUBLIC_APP_BASE_URL}/${this.preferences.language}/password-recovery/step2`,
    })

    if (!result.data.userSendPasswordResetWithToken) {
      if (result.errors) {
        throw new Error(result.errors[0].message)
      }

      throw new Error('パスワードのリセットに失敗しました。')
    }
  }
}
