import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchOffersRequestInput,
  FetchOffersUseCaseOutput,
  IAppErrorFactory,
  IFetchOffersUseCase,
  IOffersService,
} from '../../types'

@injectable()
export default class FetchOffersInteractor implements IFetchOffersUseCase {
  @inject(symbols.IOffersService) private offersService: IOffersService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchOffersRequestInput): Promise<FetchOffersUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const { offers, hasNextPage } = await this.offersService.fetchOffers(input)

      output.offers = offers
      output.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchOffersUseCaseOutput {
    return {
      offers: [],
      hasNextPage: true,
      error: null,
    }
  }
}
