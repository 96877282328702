import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  CreateMyOfferUseCaseInput,
  CreateMyOfferUseCaseOutput,
  IAppErrorFactory,
  IOffersService,
  ICreateMyOfferUseCase,
  IUserOfferFactory,
  IUseCaseOutputFactory,
  IUserOffer,
} from '../../types'

@injectable()
export default class CreateMyOfferInteractor implements ICreateMyOfferUseCase {
  @inject(symbols.IOffersService) private offersService: IOffersService

  @inject(symbols.IUserOfferFactory) private userOfferFactory: IUserOfferFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    offer: null | IUserOffer
  }>

  async handle(input: CreateMyOfferUseCaseInput): Promise<CreateMyOfferUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        offer: null,
      },
    })
    try {
      const base = await this.offersService.createMyOffer(input)
      output.data.offer = this.userOfferFactory.create({ base })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
