import { inject, injectable } from 'inversify'
import {
  FetchFeaturedStartupsUseCaseInput,
  FetchFeaturedStartupsUseCaseOutput,
  IAppErrorFactory,
  ICompaniesService,
  IFetchFeaturedStartupsUseCase,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FetchFeaturedStartupsInteractor implements IFetchFeaturedStartupsUseCase {
  @inject(symbols.ICompaniesService) private companiesService: ICompaniesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchFeaturedStartupsUseCaseInput): Promise<FetchFeaturedStartupsUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      // 現在、fetchFeaturedStartups メソッドは引数不要。しかし今後、データの取得数を指定することを考慮して、引数（input）を記述しておく。
      output.startups = await this.companiesService.fetchFeaturedStartups(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchFeaturedStartupsUseCaseOutput {
    return {
      startups: [],
      error: null,
    }
  }
}
