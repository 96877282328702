import { inject, injectable } from 'inversify'
import {
  FetchMagazineArchivePageContentUseCaseInput,
  FetchMagazineArchivePageContentUseCaseOutput,
  IAppErrorFactory,
  IArticlesService,
  IFetchMagazineArchivePageContentUseCase,
} from '@/types'
import symbols from '@/symbols'

@injectable()
export default class FetchMagazineArchivePageContentInteractor implements IFetchMagazineArchivePageContentUseCase {
  @inject(symbols.IArticlesService) private articlesService: IArticlesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(
    input: FetchMagazineArchivePageContentUseCaseInput
  ): Promise<FetchMagazineArchivePageContentUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const [{ articles }] = await Promise.all([
        this.articlesService.fetchArticles({ categories: input.categories, limit: input.limit }),
      ])
      output.articles = articles
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchMagazineArchivePageContentUseCaseOutput {
    return {
      articles: [],
      error: null,
    }
  }
}
