import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchMyOffersUseCaseInput,
  FetchMyOffersUseCaseOutput,
  IAppErrorFactory,
  IFetchMyOffersUseCase,
  IUserOffer,
  IUserOfferFactory,
  IOffersService,
  IUseCaseOutputFactory,
} from '../../types'

@injectable()
export default class FetchMyOffersInteractor implements IFetchMyOffersUseCase {
  @inject(symbols.IOffersService) private offersService: IOffersService

  @inject(symbols.IUserOfferFactory) private offerFactory: IUserOfferFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    offers: null | IUserOffer[]
    hasNextPage: null | boolean
  }>

  async handle(input: FetchMyOffersUseCaseInput): Promise<FetchMyOffersUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        offers: null,
        hasNextPage: null,
      },
    })
    try {
      const { offers, hasNextPage } = await this.offersService.fetchMyOffers(input)
      output.data.offers = offers.map((j) => this.offerFactory.create({ base: j }))
      output.data.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
