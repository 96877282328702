import { inject, injectable } from 'inversify'
import {
  CreateChatMessageThreadSubscriptionUseCaseInput,
  CreateChatMessageThreadSubscriptionUseCaseOutput,
  IAppErrorFactory,
  IChatService,
  ICreateChatMessageThreadSubscriptionUseCase,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class CreateChatMessageThreadSubscriptionInteractor
  implements ICreateChatMessageThreadSubscriptionUseCase {
  @inject(symbols.IChatService) private chatService: IChatService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  handle(input: CreateChatMessageThreadSubscriptionUseCaseInput): CreateChatMessageThreadSubscriptionUseCaseOutput {
    const output = this.getDefaultOutputData()
    try {
      this.chatService.createChatMessageThreadSubscription(input)
      output.isSuccessful = true
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): CreateChatMessageThreadSubscriptionUseCaseOutput {
    return {
      isSuccessful: false,
      error: null,
    }
  }
}
