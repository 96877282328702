import { inject, injectable } from 'inversify'
import {
  DeleteArticleUseCaseInput,
  DeleteArticleUseCaseOutput,
  IAppErrorFactory,
  IArticlesService,
  IDeleteArticleUseCase,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class DeleteArticleInteractor implements IDeleteArticleUseCase {
  @inject(symbols.IArticlesService) private articlesService: IArticlesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: DeleteArticleUseCaseInput): Promise<DeleteArticleUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.article = await this.articlesService.deleteArticle({
        id: input.id,
      })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: '記事の削除に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): DeleteArticleUseCaseOutput {
    return {
      article: null,
      error: null,
    }
  }
}
