import { inject, injectable } from 'inversify'
import {
  DeleteMyAnswerUseCaseInput,
  DeleteMyAnswerUseCaseOutput,
  IAppErrorFactory,
  IAnswersService,
  IDeleteMyAnswerUseCase,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class DeleteMyAnswerInteractor implements IDeleteMyAnswerUseCase {
  @inject(symbols.IAnswersService) private answersService: IAnswersService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: DeleteMyAnswerUseCaseInput): Promise<DeleteMyAnswerUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.answer = await this.answersService.deleteMyAnswer({
        id: input.id,
      })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'Failed to delete reply.',
      })
    }

    return output
  }

  private getDefaultOutputData(): DeleteMyAnswerUseCaseOutput {
    return {
      answer: null,
      error: null,
    }
  }
}
