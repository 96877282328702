import {
  AddTokenTransactionUseCaseInput,
  AddTokenTransactionUseCaseOutput,
  IAddTokenTransactionUseCase,
  IAppErrorFactory,
  ITokenTransactionBase,
  IUseCaseOutputFactory,
  IViewerService,
} from '@/types'
import { inject, injectable } from 'inversify'
import symbols from '../../symbols'

@injectable()
export default class AddTokenTransactionInteractor implements IAddTokenTransactionUseCase {
  @inject(symbols.IViewerService) private viewerService: IViewerService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    tokenTransaction: null | ITokenTransactionBase
  }>

  async handle(input: AddTokenTransactionUseCaseInput): Promise<AddTokenTransactionUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        tokenTransaction: null,
      },
    })

    try {
      const base = await this.viewerService.addTokenTransaction(input)
      output.data.tokenTransaction = base
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
