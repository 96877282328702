import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchUserForViewerUseCaseInput,
  FetchUserForViewerUseCaseOutput,
  IAppErrorFactory,
  IFetchUserForViewerUseCase,
  IUseCaseOutputFactory,
  IUserBase,
  IUsersService,
} from '../../types'

@injectable()
export default class FetchUserForViewerInteractor implements IFetchUserForViewerUseCase {
  @inject(symbols.IUsersService) private usersService: IUsersService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{ user: null | IUserBase }>

  async handle(input: FetchUserForViewerUseCaseInput): Promise<FetchUserForViewerUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        user: null,
      },
    })
    try {
      output.data.user = await this.usersService.fetchUserForViewer(input.username)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
