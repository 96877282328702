import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchLocationsUseCaseInput,
  FetchLocationsUseCaseOutput,
  IAppErrorFactory,
  IFetchLocationsUseCase,
  ILocationsService,
} from '../../types'

@injectable()
export default class FetchLocationsInteractor implements IFetchLocationsUseCase {
  @inject(symbols.ILocationsService) private locationService: ILocationsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchLocationsUseCaseInput): Promise<FetchLocationsUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.locations = await this.locationService.fetchLocations(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchLocationsUseCaseOutput {
    return {
      locations: [],
      error: null,
    }
  }
}
