import { action, computed, observable } from 'mobx'
import { IBlockchainBase, IErrorsStore, IProduct, IProductBase, ITokenBase, IUpdateProductUseCase } from '@/types'

export default class Product implements IProduct {
  @observable id = ''

  @observable image = ''

  @observable name = ''

  @observable description = ''

  @observable url = ''

  @observable blockchains: IBlockchainBase[]

  @observable tokens: ITokenBase[]

  errorsStore: IErrorsStore

  updateProductUseCase: IUpdateProductUseCase

  @computed
  get base(): IProductBase {
    return {
      id: this.id,
      description: this.description,
      image: this.image,
      name: this.name,
      url: this.url,
      blockchains: this.blockchains,
      tokens: this.tokens,
    }
  }

  @action
  _mapFromBase(base: IProductBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // TODO: いい実装考える
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  constructor(base: IProductBase) {
    this._mapFromBase(base)
  }

  async save(product: IProductBase): Promise<boolean> {
    const output = await this.updateProductUseCase.handle({
      product,
      id: this.id,
    })

    if (output.product) {
      this._mapFromBase(output.product)
      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }
}
