import { inject, injectable } from 'inversify'
import {
  CompanyFactoryInput,
  IAddAngelInvitationUseCase,
  IAddCompanyInvestmentUseCase,
  IAddCompanyMemberUseCase,
  IAddInvestorUseCase,
  IAddMemberInvitationUseCase,
  IAddProductUseCase,
  IAddVcInvestmentInvitationUseCase,
  IAddVcInvitationUseCase,
  ICompanyArticleFactory,
  ICompanyFactory,
  ICompanyMemberFactory,
  ICreateArticleAttachmentUseCase,
  ICreateCompanyArticleUseCase,
  ICreateJobAttachmentUseCase,
  ICreateJobUseCase,
  IDeleteArticleUseCase,
  IDeleteCompanyArticleUseCase,
  IDeleteJobUseCase,
  IDuplicateJobUseCase,
  IErrorsStore,
  IFetchJobBookmarksUseCase,
  IFetchJobFootprintsUseCase,
  IFetchMyCompanyArticlesUseCase,
  IFetchMyCompanyReferencesUseCase,
  IFetchMyJobsUseCase,
  IInvestmentFactory,
  IMyCompany,
  IProductFactory,
  IRemoveCompanyMemberUseCase,
  IRemoveInvestmentUseCase,
  IRemoveInvestorUseCase,
  IRemoveProductUseCase,
  IUpdateCompanyUseCase,
} from '@/types'
import Company from '@/entities/Company'
import symbols from '@/symbols'

@injectable()
export default class CompanyFactory implements ICompanyFactory {
  @inject(symbols.IUpdateCompanyUseCase) private updateCompanyUseCase: IUpdateCompanyUseCase

  @inject(symbols.IAddProductUseCase) private addProductUseCase: IAddProductUseCase

  @inject(symbols.IRemoveProductUseCase) private removeProductUseCase: IRemoveProductUseCase

  @inject(symbols.IAddCompanyInvestmentUseCase) private addCompanyInvestmentUseCase: IAddCompanyInvestmentUseCase

  @inject(symbols.IAddInvestorUseCase) private addInvestorUseCase: IAddInvestorUseCase

  @inject(symbols.IRemoveInvestmentUseCase) private removeInvestmentUseCase: IRemoveInvestmentUseCase

  @inject(symbols.IRemoveInvestorUseCase) private removeInvestorUseCase: IRemoveInvestorUseCase

  @inject(symbols.IAddCompanyMemberUseCase) private addCompanyMemberUseCase: IAddCompanyMemberUseCase

  @inject(symbols.IRemoveCompanyMemberUseCase) private removeCompanyMemberUseCase: IRemoveCompanyMemberUseCase

  @inject(symbols.ICreateCompanyArticleUseCase) private createCompanyArticleUseCase: ICreateCompanyArticleUseCase

  @inject(symbols.IDeleteArticleUseCase) private deleteArticleUseCase: IDeleteArticleUseCase

  @inject(symbols.IDeleteCompanyArticleUseCase) private deleteCompanyArticleUseCase: IDeleteCompanyArticleUseCase

  @inject(symbols.ICreateArticleAttachmentUseCase)
  private createArticleAttachmentUseCase: ICreateArticleAttachmentUseCase

  @inject(symbols.IFetchMyJobsUseCase) private fetchMyJobsUseCase: IFetchMyJobsUseCase

  @inject(symbols.IFetchJobFootprintsUseCase) private fetchJobFootprintsUseCase: IFetchJobFootprintsUseCase

  @inject(symbols.ICreateJobUseCase) private createJobUseCase: ICreateJobUseCase

  @inject(symbols.IDuplicateJobUseCase) private duplicateJobUseCase: IDuplicateJobUseCase

  @inject(symbols.IDeleteJobUseCase) private deleteJobUseCase: IDeleteJobUseCase

  @inject(symbols.ICreateJobAttachmentUseCase) private createJobAttachmentUseCase: ICreateJobAttachmentUseCase

  @inject(symbols.IFetchJobBookmarksUseCase) private fetchJobBookmarksUseCase: IFetchJobBookmarksUseCase

  @inject(symbols.IAddAngelInvitationUseCase) private addAngelInvitationUseCase: IAddAngelInvitationUseCase

  @inject(symbols.IAddVcInvitationUseCase) private addVcInvitationUseCase: IAddVcInvitationUseCase

  @inject(symbols.IAddVcInvestmentInvitationUseCase)
  private addVcInvestmentInvitationUseCase: IAddVcInvestmentInvitationUseCase

  @inject(symbols.IAddMemberInvitationUseCase) private addMemberInvitationUseCase: IAddMemberInvitationUseCase

  @inject(symbols.IFetchMyCompanyReferencesUseCase)
  private fetchMyCompanyReferencesUseCase: IFetchMyCompanyReferencesUseCase

  @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore

  @inject(symbols.IInvestmentFactory) private investmentFactory: IInvestmentFactory

  @inject(symbols.IProductFactory) private productFactory: IProductFactory

  @inject(symbols.ICompanyMemberFactory) private companyMemberFactory: ICompanyMemberFactory

  @inject(symbols.ICompanyArticleFactory) private companyArticleFactory: ICompanyArticleFactory

  @inject(symbols.IFetchMyCompanyArticlesUseCase)
  private fetchMyCompanyArticlesUseCase: IFetchMyCompanyArticlesUseCase

  public create(input: CompanyFactoryInput): IMyCompany {
    // 先に割り当てれるやつを割り当て
    input.base.products = input.base.products
      ? input.base.products.map((p) => this.productFactory.create({ base: p }))
      : []
    input.base.companyMembers = input.base.companyMembers
      ? input.base.companyMembers.map((c) => this.companyMemberFactory.create({ base: c }))
      : []
    const investments = input.base.investments
      ? input.base.investments.map((investment) => this.investmentFactory.create({ base: investment }))
      : []
    const articles = input.base.articles
      ? input.base.articles.nodes.map((a) => this.companyArticleFactory.create({ base: a }))
      : []
    const company = new Company(input.base, investments)
    company.allArticles = articles
    company.errorsStore = this.errorsStore
    company.updateCompanyUseCase = this.updateCompanyUseCase
    company.addProductUseCase = this.addProductUseCase
    company.removeProductUseCase = this.removeProductUseCase
    company.addCompanyInvestmentUseCase = this.addCompanyInvestmentUseCase
    company.addInvestorUseCase = this.addInvestorUseCase
    company.removeInvestmentUseCase = this.removeInvestmentUseCase
    company.removeInvestorUseCase = this.removeInvestorUseCase
    company.addCompanyMemberUseCase = this.addCompanyMemberUseCase
    company.removeCompanyMemberUseCase = this.removeCompanyMemberUseCase
    company.createCompanyArticleUseCase = this.createCompanyArticleUseCase
    company.deleteArticleUseCase = this.deleteArticleUseCase
    company.deleteCompanyArticleUseCase = this.deleteCompanyArticleUseCase
    company.createArticleAttachmentUseCase = this.createArticleAttachmentUseCase
    company.fetchMyJobsUseCase = this.fetchMyJobsUseCase
    company.createJobUseCase = this.createJobUseCase
    company.duplicateJobUseCase = this.duplicateJobUseCase
    company.deleteJobUseCase = this.deleteJobUseCase
    company.createJobAttachmentUseCase = this.createJobAttachmentUseCase
    company.fetchJobFootprintsUseCase = this.fetchJobFootprintsUseCase
    company.fetchJobBookmarksUseCase = this.fetchJobBookmarksUseCase
    company.addAngelInvitationUseCase = this.addAngelInvitationUseCase
    company.addVcInvitationUseCase = this.addVcInvitationUseCase
    company.addVcInvestmentInvitationUseCase = this.addVcInvestmentInvitationUseCase
    company.addMemberInvitationUseCase = this.addMemberInvitationUseCase
    company.fetchMyCompanyReferencesUseCase = this.fetchMyCompanyReferencesUseCase
    company.fetchMyCompanyArticlesUseCase = this.fetchMyCompanyArticlesUseCase
    return company
  }
}
