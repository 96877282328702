import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  AddUserInvitationUseCaseInput,
  AddUserInvitationUseCaseOutput,
  IAddUserInvitationUseCase,
  IAppErrorFactory,
  IInvitationsService,
} from '../../types'

@injectable()
export default class AddUserInvitationInteractor implements IAddUserInvitationUseCase {
  @inject(symbols.IInvitationsService) private invitationsService: IInvitationsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: AddUserInvitationUseCaseInput): Promise<AddUserInvitationUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.invitationLink = await this.invitationsService.addUserInvitation(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): AddUserInvitationUseCaseOutput {
    return {
      invitationLink: null,
      error: null,
    }
  }
}
