import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  IInvestmentsService,
  IUpdateInvestmentUseCase,
  UpdateInvestmentUseCaseInput,
  UpdateInvestmentUseCaseOutput,
} from '../../types'
import symbols from '../../symbols'
import { getValidUrl } from '../../utils'

@injectable()
export default class UpdateInvestmentInteractor implements IUpdateInvestmentUseCase {
  @inject(symbols.IInvestmentsService) private investmentsService: IInvestmentsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: UpdateInvestmentUseCaseInput): Promise<UpdateInvestmentUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      // URL のプロトコル部分を調整
      input.investment.referenceUrl = getValidUrl(input.investment.referenceUrl)

      output.investment = await this.investmentsService.updateInvestment(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: '投資先情報の保存に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): UpdateInvestmentUseCaseOutput {
    return {
      investment: null,
      error: null,
    }
  }
}
