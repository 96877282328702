import { inject, injectable } from 'inversify'
import {
  AddCompanyMemberUseCaseInput,
  AddCompanyMemberUseCaseOutput,
  IAddCompanyMemberUseCase,
  IAppErrorFactory,
  ICompaniesService,
  ICompanyMemberFactory,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class AddCompanyMemberInteractor implements IAddCompanyMemberUseCase {
  @inject(symbols.ICompaniesService) private companiesService: ICompaniesService

  @inject(symbols.ICompanyMemberFactory) private companyMemberFactory: ICompanyMemberFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: AddCompanyMemberUseCaseInput): Promise<AddCompanyMemberUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const member = await this.companiesService.addCompanyMember(input)
      output.companyMember = this.companyMemberFactory.create({
        base: member,
      })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'メンバーの登録に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): AddCompanyMemberUseCaseOutput {
    return {
      companyMember: null,
      error: null,
    }
  }
}
