import { inject, injectable } from 'inversify'
import UserQuestion from '@/entities/UserQuestion'
import symbols from '@/symbols'
import {
  IErrorsStore,
  IFetchQuestionUseCase,
  IUpdateMyQuestionUseCase,
  IUserQuestion,
  IUserQuestionFactory,
  IVoteForChoiceUseCase,
  UserQuestionFactoryInput,
} from '@/types'

@injectable()
export default class UserQuestionFactory implements IUserQuestionFactory {
  @inject(symbols.IUpdateMyQuestionUseCase) private updateMyQuestionUseCase: IUpdateMyQuestionUseCase

  @inject(symbols.IVoteForChoiceUseCase) private voteForChoiceUseCase: IVoteForChoiceUseCase

  @inject(symbols.IFetchQuestionUseCase) private fetchQuestionUseCase: IFetchQuestionUseCase

  @inject(symbols.IErrorsStore) private errorStore: IErrorsStore

  public create(input: UserQuestionFactoryInput): IUserQuestion {
    const userQuestion = new UserQuestion(input.base, {
      voteForChoiceUseCase: this.voteForChoiceUseCase,
      fetchQuestionUseCase: this.fetchQuestionUseCase,
    })
    userQuestion.errorsStore = this.errorStore
    userQuestion.updateMyQuestionUseCase = this.updateMyQuestionUseCase
    userQuestion.voteForChoiceUseCase = this.voteForChoiceUseCase
    return userQuestion
  }
}
