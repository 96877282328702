import { action, observable } from 'mobx'
import {
  IErrorsStore,
  IUserAttachmentInputBase,
  IUpdateUserAttachmentUseCase,
  IUserAttachment,
  UserAttachmentCategoryNameBase,
  IUserAttachmentBase,
  UpdateUserAttachmentUseCaseOutput,
} from '@/types'

export default class UserAttachment implements IUserAttachment {
  @observable id = ''

  @observable categoryName: UserAttachmentCategoryNameBase

  @observable file = ''

  @observable filename = ''

  @observable url = ''

  @observable name = ''

  @observable description = ''

  errorsStore: IErrorsStore

  updateUserAttachmentUseCase: IUpdateUserAttachmentUseCase

  @action
  _mapBase(base: IUserAttachmentBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  constructor(base: IUserAttachmentBase) {
    this._mapBase(base)
  }

  async save(userAttachment: IUserAttachmentInputBase): Promise<UpdateUserAttachmentUseCaseOutput> {
    const output = await this.updateUserAttachmentUseCase.handle({
      userAttachment,
      id: this.id,
    })

    if (output.data.userAttachment) {
      this._mapBase(output.data.userAttachment)
    }

    return output
  }
}
