import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  IAppErrorFactory,
  IToggleFollowUserUseCase,
  IUseCaseOutputFactory,
  IUserBase,
  IViewerService,
  ToggleFollowUserUseCaseInput,
  ToggleFollowUserUseCaseOutput,
} from '@/types'

@injectable()
export default class ToggleFollowUserInteractor implements IToggleFollowUserUseCase {
  @inject(symbols.IViewerService) private viewerService: IViewerService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    user: null | IUserBase
  }>

  async handle(input: ToggleFollowUserUseCaseInput): Promise<ToggleFollowUserUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        user: null,
      },
    })
    try {
      output.data.user = await this.viewerService.toggleFollowUser(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
