import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchUserArticlesUseCaseInput,
  FetchUserArticlesUseCaseOutput,
  IAppErrorFactory,
  IArticlesService,
  IFetchUserArticlesUseCase,
} from '../../types'

@injectable()
export default class FetchUserArticlesInteractor implements IFetchUserArticlesUseCase {
  @inject(symbols.IArticlesService) private articlesService: IArticlesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchUserArticlesUseCaseInput): Promise<FetchUserArticlesUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const { articles, hasNextPage } = await this.articlesService.fetchUserArticles(input)

      output.articles = articles
      output.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchUserArticlesUseCaseOutput {
    return {
      articles: [],
      hasNextPage: true,
      error: null,
    }
  }
}
