import { action, computed, observable } from 'mobx'
import {
  IOfferInputBase,
  IErrorsStore,
  IOfferBase,
  IUpdateMyOfferUseCase,
  IUserOffer,
  IJobCategory,
  ILocationBase,
  OfferWorkingFormBase,
  CurrencyUnit,
  OfferJobTypeBase,
  OfferStatusBase,
  UpdateMyOfferUseCaseOutput,
} from '@/types'

export default class UserOffer implements IUserOffer {
  @observable id = ''

  @observable title = ''

  @observable slug = ''

  @observable body = ''

  @observable status: OfferStatusBase

  @observable thumbnail = ''

  @observable currencyUnit: CurrencyUnit

  @observable salary: string

  @observable createdAt = ''

  @observable updatedAt = ''

  @observable jobType: OfferJobTypeBase

  @observable workingForm: OfferWorkingFormBase

  @observable jobCategory: IJobCategory

  @observable locations: ILocationBase[]

  errorsStore: IErrorsStore

  updateMyOfferUseCase: IUpdateMyOfferUseCase

  @computed
  get statusName(): string {
    if (this.status === OfferStatusBase.DRAFT) {
      return 'draft'
    }
    if (this.status === OfferStatusBase.PUBLISHED) {
      return 'published'
    }
    if (this.status === OfferStatusBase.CLOSED) {
      return 'closed'
    }

    return ''
  }

  @computed
  get isPublished(): boolean {
    if (this.status === OfferStatusBase.PUBLISHED) {
      return true
    }

    return false
  }

  @action
  _mapFromBase(base: IOfferBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  constructor(base: IOfferBase) {
    this._mapFromBase(base)
  }

  async save(offer: IOfferInputBase): Promise<UpdateMyOfferUseCaseOutput> {
    const output = await this.updateMyOfferUseCase.handle({ id: this.id, offer })

    if (output.data.offer) {
      this._mapFromBase(output.data.offer)
    }

    return output
  }
}
