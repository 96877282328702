import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  FetchQuestionCategoriesUseCaseOutput,
  IFetchQuestionCategoriesUseCase,
  IQuestionCategoriesService,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FetchQuestionCategoriesInteractor implements IFetchQuestionCategoriesUseCase {
  @inject(symbols.IQuestionCategoriesService) private questionCategoriesService: IQuestionCategoriesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(): Promise<FetchQuestionCategoriesUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const questionCategories = await this.questionCategoriesService.fetchQuestionCategories()
      output.questionCategories = questionCategories
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchQuestionCategoriesUseCaseOutput {
    return {
      questionCategories: [],
      error: null,
    }
  }
}
