import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  ICompaniesService,
  ISearchCompanyUseCase,
  SearchCompanyUseCaseInput,
  SearchCompanyUseCaseOutput,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class SearchCompanyInteractor implements ISearchCompanyUseCase {
  @inject(symbols.ICompaniesService) private companiesService: ICompaniesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: SearchCompanyUseCaseInput): Promise<SearchCompanyUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.companies = await this.companiesService.searchCompany(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): SearchCompanyUseCaseOutput {
    return {
      companies: [],
      error: null,
    }
  }
}
