import { IAppErrorFactory, INftsCountBase, INftsService, IUseCaseOutputFactory } from '@/types'
import { FetchNftsCountUseCaseOutput, IFetchNftsCountUseCase } from '@/types/useCases/nfts'
import { inject, injectable } from 'inversify'
import symbols from '../../symbols'

@injectable()
export default class FetchNftsCountInteractor implements IFetchNftsCountUseCase {
  @inject(symbols.INftsService) private nftsService: INftsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    nftsCount: null | INftsCountBase
  }>

  async handle(): Promise<FetchNftsCountUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        nftsCount: null,
      },
    })

    try {
      const nftsCount = await this.nftsService.fethNftsCount()

      output.data.nftsCount = nftsCount
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
