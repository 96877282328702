import { NextRouter, Router } from 'next/router'
import { MobXProviderContext } from 'mobx-react'
import DOMPurify from 'isomorphic-dompurify'
import moment from 'moment'
import { useContext } from 'react'
import { flatten } from 'lodash'
import qs from 'qs'
import imageCompression from 'browser-image-compression'
import {
  CurrencyUnit,
  FollowableEntityEntityType,
  IArticleBase,
  ICompanyBase,
  IFollowableEntityBase,
  IInvestmentBase,
  IJobBase,
  IMyCompanyBase,
  IOfferBase,
  IProductBase,
  IStores,
  ITokenBase,
  IUserAttachmentBase,
  IUserBase,
  IUserProfileBase,
  IViewerBase,
  UserAttachmentCategoryNameBase,
  UserProfileBirthdayAccessScopeBase,
} from '@/types'
import stores from '@/stores'
import {
  DEFAULT_ARTICLE_THUMBNAIL_PATH,
  DEFAULT_COMPANY_LOGO_PATH,
  DEFAULT_PRODUCT_IMAGE_PATH,
  DEFAULT_USER_AVATAR_PATH,
  DEFAULT_HEADER_IMAGE_PATH,
  DEFAULT_PUBLIC_HEADER_IMAGE_PATH,
  DEFAULT_TOKEN_IMAGE_PATH,
} from '@/constants'

/**
 * isActive 出し分け用のヘルパー
 *
 * @param paths マッチしたときに isActive とするパスの配列
 * @param router next.js の router
 * @param useCompletelyMatching 完全一致で検証するか
 * @param shouldUsePathname 比較に asPath を使うか pathname を使うか
 */
export function getIsActive(
  paths: string[],
  router: Router | NextRouter,
  useCompletelyMatching = false,
  shouldUsePathname = false
): boolean {
  if (!paths?.length) {
    return false
  }

  const filtered = paths.filter((pathname) => {
    // ホームを判定する場合は完全一致で確認
    if (pathname === '/') {
      return router.asPath === '/'
    }

    const pathToCompare = shouldUsePathname ? router.pathname : router.asPath

    if (!pathToCompare) {
      return false
    }

    // 完全一致で検証
    if (useCompletelyMatching) {
      return pathToCompare === pathname
    }

    // ホーム以外のルート
    // 頭からマッチしてるかで判断
    return pathToCompare.indexOf(pathname) === 0
  })

  return filtered.length > 0
}

/**
 * Functional Component から store を利用するヘルパー
 */
export function useStores(): IStores {
  const providerContext = useContext(MobXProviderContext)
  return providerContext.stores as IStores
}

export function getStaticStores(): IStores {
  return stores
}

/**
 * Next.js の router.query が初回 render 時に取れないことがあるので確実に取得できるようにする
 *
 * cf., https://github.com/vercel/next.js/discussions/11484#discussioncomment-60563
 * @param key
 * @param router
 */
export function getQueryValue(key: string, router: NextRouter): string {
  const asQuery = router.query[key]
  if (asQuery) {
    return asQuery as string
  }

  const asPath = new RegExp(`[&?]${key}=([^&#]*)(&|#|$)`).exec(router.asPath)
  if (asPath && Array.isArray(asPath)) {
    return asPath[1]
  }

  return ''
}

/**
 * フォーマット変換した日付を返す
 *
 * @param utcDate UTC
 * @param format 日付フォーマット
 */
export function getDateTimeFromUtc(utcDate: string, format = 'YYYY/M/D'): string {
  const now = new Date()
  moment().utcOffset(now.getTimezoneOffset() / 60)

  if (utcDate) {
    if (moment(utcDate).isValid()) {
      return moment(utcDate).format(format)
    }
  }

  return utcDate
}

/**
 * 時刻をUTCで返す
 *
 * @param dateTime 時刻
 */
export function getUtcFromDateTime(dateTime: string): string {
  if (dateTime) {
    if (moment(dateTime).isValid()) {
      return moment.utc(dateTime).format()
    }
  }

  return dateTime
}

/**
 * 現在時刻から指定分進めた時刻をUTCで返す
 *
 * @param days
 * @param hours
 * @param minutes
 */
export function getUTCPollDeadline(days: number, hours: number, minutes: number): string {
  const now = new Date()
  now.setDate(now.getDate() + Number(days))
  now.setHours(now.getHours() + Number(hours), now.getMinutes() + Number(minutes))

  if (moment(now).isValid()) {
    return moment.utc(now).format()
  }

  return ''
}

/**
 * ユーザーのプロフィール画像を返す
 * プロフィール画像がない場合にデフォルト画像を返す
 *
 * @param user
 */
export function getUserAvatar(user: IUserBase | IViewerBase, useAbsolutePath = false): string {
  if (useAbsolutePath) {
    return user?.profile?.avatar || process.env.NEXT_PUBLIC_APP_BASE_URL + DEFAULT_USER_AVATAR_PATH
  }

  return user?.profile?.avatar || DEFAULT_USER_AVATAR_PATH
}

/**
 * ユーザーのヘッダー画像を返す
 * ヘッダー画像がない場合にデフォルト画像を返す
 *
 * @param user
 */
export function getUserHeaderImage(user: IUserBase | IViewerBase, useAbsolutePath = false, isPublic = false): string {
  if (useAbsolutePath) {
    return user?.profile?.headerImage || process.env.NEXT_PUBLIC_APP_BASE_URL + DEFAULT_HEADER_IMAGE_PATH
  }

  if (isPublic) {
    return user?.profile?.headerImage || DEFAULT_PUBLIC_HEADER_IMAGE_PATH
  }

  return user?.profile?.headerImage || DEFAULT_HEADER_IMAGE_PATH
}

/**
 * 会社のロゴ画像を返す
 *
 * @param company
 */
export function getCompanyLogo(company: ICompanyBase | IMyCompanyBase, useAbsolutePath = false): string {
  if (useAbsolutePath) {
    return company?.logo || process.env.NEXT_PUBLIC_APP_BASE_URL + DEFAULT_COMPANY_LOGO_PATH
  }

  return company?.logo || DEFAULT_COMPANY_LOGO_PATH
}

/**
 * 会社のヘッダー画像を返す
 *
 * @param company
 */
export function getCompanyHeaderImage(
  company: ICompanyBase | IMyCompanyBase,
  useAbsolutePath = false,
  isPublic = false
): string {
  if (useAbsolutePath) {
    return company?.headerImage || process.env.NEXT_PUBLIC_APP_BASE_URL + DEFAULT_HEADER_IMAGE_PATH
  }

  if (isPublic) {
    return company?.headerImage || DEFAULT_PUBLIC_HEADER_IMAGE_PATH
  }

  return company?.headerImage || DEFAULT_HEADER_IMAGE_PATH
}

/**
 * トークンのロゴ画像を返す
 *
 * @param token
 */
export function getTokenLogo(token: ITokenBase, useAbsolutePath = false): string {
  if (useAbsolutePath) {
    return token?.logo || process.env.NEXT_PUBLIC_APP_BASE_URL + DEFAULT_TOKEN_IMAGE_PATH
  }

  return token?.logo || DEFAULT_TOKEN_IMAGE_PATH
}

/**
 * followableEntity の画像を返す
 *
 * @param company
 */
export function getFollowableEntityImage(followableEntity: IFollowableEntityBase, useAbsolutePath = false): string {
  let imagePath = ''
  if (followableEntity.entityType === FollowableEntityEntityType.COMPANY) {
    imagePath = DEFAULT_COMPANY_LOGO_PATH
  } else {
    imagePath = DEFAULT_USER_AVATAR_PATH
  }

  if (useAbsolutePath) {
    return followableEntity?.image || process.env.NEXT_PUBLIC_APP_BASE_URL + imagePath
  }

  return followableEntity?.image || imagePath
}

/**
 * 会社のサムネイルを返す
 *
 * @param article
 */
export function getArticleThumbnail(article: IArticleBase): string {
  return article?.thumbnail || DEFAULT_ARTICLE_THUMBNAIL_PATH
}

// 処理内容は一緒だが、今後別のデフォルト画像を出す想定で別メソッドを定義
/**
 * 募集のサムネイルを返す
 *
 * @param job
 */
export function getJobThumbnail(job: IJobBase): string {
  return job?.thumbnail || DEFAULT_ARTICLE_THUMBNAIL_PATH
}

/**
 * オファーのサムネイルを返す
 *
 * @param offer
 */
export function getOfferThumbnail(offer: IOfferBase): string {
  return offer?.thumbnail || DEFAULT_ARTICLE_THUMBNAIL_PATH
}

/**
 * プロダクト画像を返す
 *
 * @param product
 */
export function getProductImage(product: IProductBase): string {
  return product?.image || DEFAULT_PRODUCT_IMAGE_PATH
}

/**
 * ユーザー添付資料画像を返す
 *
 * @param userAttachment
 */
export function getUserAttachmentImage(userAttachment: IUserAttachmentBase): string {
  if (userAttachment.categoryName === UserAttachmentCategoryNameBase.PORTFOLIO) {
    return userAttachment?.file || DEFAULT_PRODUCT_IMAGE_PATH
  }

  return userAttachment?.file || DEFAULT_USER_AVATAR_PATH
}

/**
 * フォーマット済みの金額を返す
 * @param amount
 * @param currency
 */
export function getFormattedAmount(amount: number, currency: CurrencyUnit = CurrencyUnit.USD): string {
  let localeFormat = ''
  let currencyUnit = CurrencyUnit.USD

  if (currency === CurrencyUnit.JPY) {
    localeFormat = 'ja-JP'
    currencyUnit = CurrencyUnit.JPY
  } else {
    localeFormat = 'en-US'
  }

  const formatter = new Intl.NumberFormat(localeFormat, {
    currency: currencyUnit, // currency 引数に null とかを渡さないために currencyUnit に明示的に代入
    style: 'currency',
    minimumFractionDigits: 0,
  })

  if (!amount) {
    return formatter.format(0)
  }

  return formatter.format(amount)
}

/**
 * ファイルを Base64 に変換して Promise で返す
 * @param file
 */
export function readFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result as string)
    }

    reader.onerror = reject

    reader.readAsDataURL(file)
  })
}

export async function compressFile(file: File, maxSizeMB: number, maxWidthOrHeight?: number): Promise<File> {
  const options = {
    maxSizeMB,
    maxWidthOrHeight,
  }
  return imageCompression(file, options)
}

/**
 * 会社を name, slug でフィルタする
 * @param keyword
 * @param companies
 */
export function filterCompanies(keyword: string, companies: ICompanyBase[]): ICompanyBase[] {
  return companies.filter((company) => {
    const trimmedKeyword = keyword.toLowerCase().trim()
    if (!trimmedKeyword) {
      return company
    }

    const name = company.name.toLowerCase()
    const slug = company.slug.toLowerCase()

    return name.includes(trimmedKeyword) || slug.includes(trimmedKeyword)
  })
}

/**
 * UTC 現在時刻との差異と、その単位の翻訳キーを返す
 * @param time
 */

type TimeAgoTranslationKeys =
  | 'year ago'
  | 'years ago'
  | 'month ago'
  | 'months ago'
  | 'day ago'
  | 'days ago'
  | 'hour ago'
  | 'hours ago'
  | 'minute ago'
  | 'minutes ago'
  | 'second ago'
  | 'seconds ago'

type GetTimeAgoAndTranslationKeyOutput = {
  timeAgo: number
  key: TimeAgoTranslationKeys
}

export function getTimeAgoAndTranslationKey(time: string): GetTimeAgoAndTranslationKeyOutput {
  const d = getTimeDiff(time)

  if (!d) {
    return { timeAgo: 0, key: 'second ago' }
  }

  const dYear = d.getUTCFullYear() - 1970
  if (dYear > 0) {
    return { timeAgo: dYear, key: dYear === 1 ? 'year ago' : 'years ago' }
  }

  const dMonth = d.getUTCMonth()
  if (dMonth > 0) {
    return { timeAgo: dMonth, key: dMonth === 1 ? 'month ago' : 'months ago' }
  }

  const dDate = d.getUTCDate() - 1
  if (dDate > 0) {
    return { timeAgo: dDate, key: dDate === 1 ? 'day ago' : 'days ago' }
  }

  const dHours = d.getUTCHours()
  if (dHours > 0) {
    return { timeAgo: dHours, key: dHours === 1 ? 'hour ago' : 'hours ago' }
  }

  const dMinutes = d.getUTCMinutes()
  if (dMinutes > 0) {
    return { timeAgo: dMinutes, key: dMinutes === 1 ? 'minute ago' : 'minutes ago' }
  }

  const dSeconds = d.getUTCSeconds()
  // 0秒前のときには単数形にする
  return { timeAgo: dSeconds, key: dSeconds <= 1 ? 'second ago' : 'seconds ago' }
}

export function getTimeDiff(time: string): Date {
  if (!time) {
    return null
  }

  const date = new Date(time)
  const now = new Date(Date.now())

  return new Date(now.getTime() - date.getTime())
}

/**
 * 投票締切時刻との差異と、その単位を返す
 * @param time
 */

type TimeLeftTranslationKeys =
  | 'year left'
  | 'years left'
  | 'month left'
  | 'months left'
  | 'day left'
  | 'days left'
  | 'hour left'
  | 'hours left'
  | 'minute left'
  | 'minutes left'
  | 'second left'
  | 'seconds left'

type GetTimeLeftAndTranslationKeyOutput = {
  timeLeft: number
  key: TimeLeftTranslationKeys
}

export function getTimeLeftAndTranslationKey(time: string): GetTimeLeftAndTranslationKeyOutput {
  const d = getTimeDiffLeft(time)

  if (!d) {
    return { timeLeft: 0, key: 'second left' }
  }

  const dDate = d.getUTCDate() - 1
  if (dDate > 0) {
    return { timeLeft: dDate, key: dDate === 1 ? 'day left' : 'days left' }
  }

  const dHours = d.getUTCHours()
  if (dHours > 0) {
    return { timeLeft: dHours, key: dHours === 1 ? 'hour left' : 'hours left' }
  }

  const dMinutes = d.getUTCMinutes()
  if (dMinutes > 0) {
    return { timeLeft: dMinutes, key: dMinutes === 1 ? 'minute left' : 'minutes left' }
  }

  const dSeconds = d.getUTCSeconds()
  // 0秒前のときには単数形にする
  return { timeLeft: dSeconds, key: dSeconds <= 1 ? 'second left' : 'seconds left' }
}

export function getTimeDiffLeft(time: string): Date {
  if (!time) {
    return null
  }

  const date = new Date(time)
  const now = new Date(Date.now())

  return new Date(date.getTime() - now.getTime())
}

/**
 * URL バリデーションの正規表現
 * 下記参照リンクを元に、プロトコル部分を任意に調整する
 * cf., https://github.com/jquense/yup/blob/master/src/string.ts#L25
 */
// eslint-disable-next-line no-useless-escape
export const URL_REGEX = /^((https?|ftp):\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i

// eslint-disable-next-line no-useless-escape
export const SCHEME_REQUIRED_URL_REGEX = /^((https?|ftp):\/\/)(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i

/**
 * URL のプロトコル部分を補完する
 * @param url
 */
export function getValidUrl(url: string): string {
  // Falsy な場合は空文字を返す
  if (!url) {
    return ''
  }

  if (!/^(https?|ftp):\/\//.test(url)) {
    return `http://${url}`
  }

  return url
}

/**
 * UI 上の表示のために URL のプロトコル部分と末尾の "/" を削除する
 * @param url
 */
export function getShortURL(url: string): string {
  // Falsy な場合は空文字を返す
  if (!url) {
    return ''
  }

  return url.replace(/^https?:\/\//, '').replace(/\/$/, '')
}

/**
 * 会社プロフィール詳細ページの投資元表示で、エンジェル投資家、VCの重複を取り除く
 * @param items
 */
export function getUniqueReceivedInvestments(items: IInvestmentBase[]): IInvestmentBase[] {
  const uniqueAngels = items
    .filter((i: IInvestmentBase) => i.angel)
    .reduce((a: IInvestmentBase[], v) => {
      if (!a.some((e: IInvestmentBase) => e.angel.username === v.angel.username)) {
        a.push(v)
      }
      return a
    }, [])

  const uniqueCompaniesInvestedFrom = items
    .filter((i: IInvestmentBase) => i.companyInvestedFrom)
    .reduce((a: IInvestmentBase[], v) => {
      if (!a.some((e: IInvestmentBase) => e.companyInvestedFrom.slug === v.companyInvestedFrom.slug)) {
        a.push(v)
      }
      return a
    }, [])

  return uniqueAngels.concat(uniqueCompaniesInvestedFrom)
}

/**
 * 3桁ずつにカンマ(,)をつけた値を返す
 * @param str
 */
export function encodeToStringWithCommas(str: string): string {
  const value = parseFloat(str)
  // NaN の場合は空文字を返す
  if (Number.isNaN(value)) {
    return ''
  }

  return str
    ?.trim()
    .replace(/,/g, '')
    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
}

/**
 * テキストをサニタイジング
 *
 * @param dirtyText
 */
export const sanitizeHtml = (dirtyText: string): string => {
  // DOMPurify と jsdom を利用しての実装がうまくビルドできなかったため、 isomorphic-dompurify を利用して対応。
  // 利用者は多くないが、頻繁にアップデートもあり、今のところソースコード的にも問題なさそうなため、一旦こちらを採用。
  // 必要が生じたら自前で用意する。
  // https://github.com/kkomelin/isomorphic-dompurify
  return DOMPurify.sanitize(dirtyText)
}

const SAFE_URL_PATTERN = /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi

/** A pattern that matches safe data URLs. It only matches image, video, and audio types. */
// eslint-disable-next-line no-useless-escape
const DATA_URL_PATTERN = /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+\/]+=*$/i

/**
 * URL をサニタイジング
 *
 * @param dirtyUrl
 */
export function sanitizeUrl(dirtyUrl = 'about:blank'): string {
  // Google Developer Expert の Web セキュリティ屋さんのコードから拝借。
  // 大元は Angular の URL サニタイズ処理。
  // cf., https://pragmaticwebsecurity.com/articles/spasecurity/react-xss-part1.html
  const url = String(dirtyUrl).trim()
  if (url === 'null' || url.length === 0 || url === 'about:blank') {
    return 'about:blank'
  }
  // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
  if (url.match(SAFE_URL_PATTERN) || url.match(DATA_URL_PATTERN)) {
    return url
  }

  // 危険な URL の場合も 'about:blank' を返す
  return 'about:blank'
}

/**
 * カンマ(,)を除外した値を返す
 * @param str
 */
export function decodeToStringWithoutCommas(str: string): string {
  const value = parseFloat(str)
  // NaN の場合は空文字を返す
  if (Number.isNaN(value)) {
    return ''
  }

  return str.replace(/,/g, '')
}

/**
 * 通貨記号の判別
 * @param currencyUnit
 */
export function getCurrencySymbol(currencyUnit: CurrencyUnit): string {
  if (currencyUnit === CurrencyUnit.USD) {
    return '$'
  }
  return '¥'
}

/**
 * 文字列を置換する
 * cf., https://github.com/iansinnott/react-string-replace
 *
 * 正規表現でマッチしたすべての部分文字列を、関数を呼び出した結果で置き換える。
 * 結果は、置換された奇数番目の要素を持つ配列になる。
 * 主な使用例は、React以外ではString.prototype.replaceを使用する場合と同様。
 *
 * 例:
 * matchReplace(
 *   'Emphasize all phone numbers like 884-555-4443.',
 *   /([\d|-]+)/g,
 *   (number, i) => <strong key={i}>{number}</strong>
 * );
 * // => ['Emphasize all phone numbers like ', <strong>884-555-4443</strong>, '.'
 *
 * @param source 文字列 or 文字列の配列
 * @param match 正規表現
 * @param fn 関数
 */
export function reactStringReplace(
  source: string | string[],
  match: RegExp,
  fn: (str: string, index?: number, num?: number) => string | JSX.Element
): string[] {
  if (!Array.isArray(source)) {
    return getFlatten([source], match, fn)
  }
  return getFlatten(source, match, fn)
}

/**
 * @param arr 文字列の配列
 * @param match 正規表現
 * @param fn 関数
 */
export function getFlatten(
  arr: string[],
  match: RegExp,
  fn: (str: string, index?: number, num?: number) => string | JSX.Element
): string[] {
  if (!arr?.length) {
    return []
  }
  return flatten(
    arr.map((x) => {
      return replaceString(x, match, fn)
    })
  )
}

/**
 * @param str 文字列
 * @param match 正規表現
 * @param fn 関数
 */
export function replaceString(
  str: string,
  match: RegExp,
  fn: (str: string, index?: number, num?: number) => string | JSX.Element
): string | string[] {
  let curCharStart = 0
  let curCharLen = 0

  if (str === '') {
    return str
  }
  if (!str) {
    throw new TypeError('First argument to react-string-replace#replaceString must be a string')
  }

  const result = str.split(match)

  // 全ての奇数の要素に関数を適用する
  for (let i = 1; i < result.length; i += 2) {
    curCharLen = result[i].length
    curCharStart += result[i - 1].length
    result[i] = fn(result[i], i, curCharStart) as string
    curCharStart += curCharLen
  }

  return result
}

/**
 * Youtube 動画の Video Id を返す
 *
 * @param youtubeUrl Youtube 動画の URL
 */
export function getVideoId(youtubeUrl: string): string {
  if (!youtubeUrl) {
    return ''
  }

  // 通常 URL の場合
  if (/^(https?):\/\/(www\.)?youtube\.com\/watch/.test(youtubeUrl)) {
    return qs.parse(youtubeUrl)['https://www.youtube.com/watch?v'] as string
  }

  // 共有用 URL の場合
  if (/^(https?):\/\/youtu\.be/.test(youtubeUrl)) {
    return /(?:https?):\/\/(?:youtu\.be)\/(.+)/g.exec(youtubeUrl)[1]
  }

  // 埋め込み URL の場合
  if (/^(https?):\/\/(www\.)?youtube\.com\/embed\//.test(youtubeUrl)) {
    return /(?:https?):\/\/(?:www\.)?(?:youtube\.com\/embed)\/(.+)/g.exec(youtubeUrl)[1]
  }

  return ''
}

/**
 * HTML タグを取り除いた string を返す
 * @param str
 */
export function removeHTMLTag(str: string): string {
  // Falsy な場合は空文字を返す
  if (!str) {
    return ''
  }

  return str.replace(/(<([^>]+)>)/gi, '')
}

/**
 * 文字列に含まれる改行と前後の空白を削除
 * @param str
 */
export function trimLineBreaks(str: string): string {
  return str.trim().replace(/\n+/g, '')
}

/**
 * meta description に使いやすい形式で文字列を返す
 * @param str
 */
export function getMetaDescription(str: string): string {
  let desc = removeHTMLTag(str)
  desc = trimLineBreaks(desc)
  desc = desc.substring(0, 119)
  const suffix = desc.length > 119 ? '...' : ''

  return `${desc}${suffix}`
}

/**
 * 生年月日を受け取り満年齢を返す
 * @param birthday
 */
export function getAge(birthday: string): number {
  const dateOfBirth = new Date(birthday)
  const today = new Date()
  const thisYearBirthday = new Date(today.getFullYear(), dateOfBirth.getUTCMonth(), dateOfBirth.getUTCDate())
  let age = today.getFullYear() - dateOfBirth.getUTCFullYear()
  if (today < thisYearBirthday) {
    age--
  }
  return age
}

/**
 * 詳細ページの基本情報を表示するかどうか
 * @param profile
 */
export function hasOverView(profile: IUserProfileBase): boolean {
  return (
    profile?.websiteUrl.length > 0 ||
    profile?.locations.length > 0 ||
    (profile?.birthday && profile?.birthdayAccessScope === UserProfileBirthdayAccessScopeBase.ANYONE)
  )
}

/**
 * 詳細ページの投資方針を表示するかどうか
 * @param profile
 */
export function hasInvestmentPolicy(profile: IUserProfileBase): boolean {
  return (
    profile?.investmentTargetMarkets.length > 0 ||
    profile?.investmentTargetRounds.length > 0 ||
    Number(profile?.minInvestmentAmount) > 0 ||
    Number(profile?.maxInvestmentAmount) > 0
  )
}

/**
 * 追加読み込み用の IntersectionObserver を生成
 * @param hasNextContentsPage
 * @param fetchAdditionalContent
 */
export function getIOForFetchingAdditionalContents(
  hasNextContentsPage: boolean,
  fetchAdditionalContent: (shouldRefresh: boolean) => Promise<void>
): IntersectionObserver {
  return new IntersectionObserver((entries) => {
    const entry = entries[0]
    // 0 のときはコンポーネントがアンマウントされたときなので実行しない
    if (entry.boundingClientRect.height === 0) {
      return
    }

    if (entry.isIntersecting && hasNextContentsPage) {
      fetchAdditionalContent(false)
    }
  })
}

export function shortSolanaAddress(address: string): string {
  return `${address.slice(0, 4)}...${address.slice(-4)}`
}
