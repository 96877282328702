import { inject, injectable } from 'inversify'
import {
  CreateMyCompanyUseCaseInput,
  CreateMyCompanyUseCaseOutput,
  IAppErrorFactory,
  ICompaniesService,
  ICompanyFactory,
  ICompanyMemberFactory,
  ICreateMyCompanyUseCase,
} from '@/types'
import symbols from '@/symbols'
import { getValidUrl } from '@/utils'

@injectable()
export default class CreateMyCompanyInteractor implements ICreateMyCompanyUseCase {
  @inject(symbols.ICompaniesService) private companiesService: ICompaniesService

  @inject(symbols.ICompanyFactory) private companyFactory: ICompanyFactory

  @inject(symbols.ICompanyMemberFactory) private companyMemberFactory: ICompanyMemberFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: CreateMyCompanyUseCaseInput): Promise<CreateMyCompanyUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      // URL のプロトコル部分を調整
      input.company.url = getValidUrl(input.company.url)

      const base = await this.companiesService.createMyCompany(input.company)
      output.company = this.companyFactory.create({
        base,
      })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: '会社ページの作成に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): CreateMyCompanyUseCaseOutput {
    return {
      company: null,
      error: null,
    }
  }
}
