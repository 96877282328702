import { action, computed, observable } from 'mobx'
import {
  IExperienceBase,
  IFetchUserForViewerUseCase,
  IInvestmentBase,
  IPublicUser,
  IUserBase,
  IUserNotificationBase,
  IUserProfileBase,
  UserStatus,
} from '@/types'

type Dependencies = {
  fetchUserForViewerUseCase: IFetchUserForViewerUseCase
}

export default class PublicUser implements IPublicUser {
  @observable experiences: IExperienceBase[] = []

  @observable followersCount = 0

  @observable followingCount = 0

  @observable id = ''

  @observable investments: IInvestmentBase[] = []

  @observable isFollower = false

  @observable isFollowing = false

  @observable name = ''

  @observable notificationSettings: IUserNotificationBase[] = []

  @observable profile: IUserProfileBase

  @observable status: UserStatus

  @observable username = ''

  fetchUserForViewerUseCase!: IFetchUserForViewerUseCase

  @computed
  get hasSNSAccount(): boolean {
    if (
      this.profile?.facebookUrl ||
      this.profile?.githubUrl ||
      this.profile?.instagramUrl ||
      this.profile?.linkedinUrl ||
      this.profile?.twitterUrl ||
      this.profile?.websiteUrl ||
      this.profile?.telegramUrl ||
      this.profile?.openseaUrl ||
      this.profile?.discordUrl
    ) {
      return true
    }
    return false
  }

  @action
  _mapFromBase(base: IUserBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  @action
  update(base: IUserBase): void {
    this._mapFromBase(base)
  }

  constructor(base: IUserBase, deps: Dependencies) {
    this._mapFromBase(base)
    this.fetchUserForViewerUseCase = deps.fetchUserForViewerUseCase
  }

  async fetch(): Promise<boolean> {
    const output = await this.fetchUserForViewerUseCase.handle({ username: this.username })

    if (output.data.user) {
      this.update(output.data.user)
      return true
    }

    return false
  }
}
