import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  IChatMessageThreadFactory,
  IChatService,
  IInitializeChatUseCase,
  InitializeChatUseCaseOutput,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class InitializeChatInteractor implements IInitializeChatUseCase {
  @inject(symbols.IChatService) private chatService: IChatService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IChatMessageThreadFactory) private chatMessageThreadFactory: IChatMessageThreadFactory

  async handle(): Promise<InitializeChatUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      // WebSocket を初期化
      this.chatService.initChatWebSocket()

      // スレッド一覧を取得
      const threads = await this.chatService.fetchChatMessageThreads({
        limitOfMessages: 1,
      })
      output.threads = threads.map((t) => this.chatMessageThreadFactory.create({ base: t }))
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): InitializeChatUseCaseOutput {
    return {
      threads: [],
      error: null,
    }
  }
}
