const symbols = {
  // ============================================================
  // Stores
  // ============================================================
  IUIStore: Symbol.for('IUIStore'),
  IErrorsStore: Symbol.for('IErrorsStore'),
  IMessagesStore: Symbol.for('IMessagesStore'),
  IViewerStore: Symbol.for('IViewerStore'),
  IUsersStore: Symbol.for('IUsersStore'),
  ICompaniesStore: Symbol.for('ICompaniesStore'),
  IArticlesStore: Symbol.for('IArticlesStore'),
  IChatStore: Symbol.for('IChatStore'),
  IJobApplicationStore: Symbol.for('IJobApplicationStore'),
  IJobsStore: Symbol.for('IJobsStore'),
  IOffersStore: Symbol.for('IOffersStore'),
  IQuestionsStore: Symbol.for('IQuestionsStore'),
  INftsStore: Symbol.for('INftsStore'),

  // ============================================================
  // Entities
  // ============================================================
  IAppCredentials: Symbol.for('IAppCredentials'),
  IPreferences: Symbol.for('IPreferences'),
  IViewer: Symbol.for('IViewer'),

  // ============================================================
  // Factories
  // ============================================================
  IAppErrorFactory: Symbol.for('IAppErrorFactory'),
  IUseCaseOutputFactory: Symbol.for('IUseCaseOutputFactory'),
  IMessageFactory: Symbol.for('IMessageFactory'),
  IUserProfileFactory: Symbol.for('IUserProfileFactory'),
  ICompanyFactory: Symbol.for('ICompanyFactory'),
  IProductFactory: Symbol.for('IProductFactory'),
  IExperienceFactory: Symbol.for('IExperienceFactory'),
  IInvestmentFactory: Symbol.for('IInvestmentFactory'),
  ICompanyMemberFactory: Symbol.for('ICompanyMemberFactory'),
  IChatMessageThreadFactory: Symbol.for('IChatMessageThreadFactory'),
  ICompanyArticleFactory: Symbol.for('ICompanyArticleFactory'),
  IJobFactory: Symbol.for('IJobFactory'),
  IJobApplicationFactory: Symbol.for('IJobApplicationFactory'),
  IUserArticleFactory: Symbol.for('IUserArticleFactory'),
  IJobFootprintFactory: Symbol.for('IJobFootprintFactory'),
  IPublicArticleFactory: Symbol.for('IPublicArticleFactory'),
  IUserReferenceFactory: Symbol.for('IUserReferenceFactory'),
  ICompanyReferenceFactory: Symbol.for('ICompanyReferenceFactory'),
  IPublicUserFactory: Symbol.for('IPublicUserFactory'),
  IPublicCompanyFactory: Symbol.for('IPublicCompanyFactory'),
  IPublicFollowableEntityFactory: Symbol.for('IPublicFollowableEntityFactory'),
  IUserOfferFactory: Symbol.for('IUserOfferFactory'),
  IUserAttachmentFactory: Symbol.for('IUserAttachmentFactory'),
  IUserQuestionFactory: Symbol.for('IUserQuestionFactory'),
  IUserAnswerFactory: Symbol.for('IUserAnswerFactory'),

  // ============================================================
  // Services
  // ============================================================
  IStorageService: Symbol.for('IStorageService'),
  IAuthService: Symbol.for('IAuthService'),
  IViewerService: Symbol.for('IViewerService'),
  IUsersService: Symbol.for('IUsersService'),
  ICompaniesService: Symbol.for('ICompaniesService'),
  IInvestmentsService: Symbol.for('IInvestmentsService'),
  IArticlesService: Symbol.for('IArticlesService'),
  IChatService: Symbol.for('IChatService'),
  IMarketsService: Symbol.for('IMarketsService'),
  IArticleAttachmentService: Symbol.for('IArticleAttachmentService'),
  INotificationsService: Symbol.for('INotificationsService'),
  ILocationsService: Symbol.for('ILocationsService'),
  IErrorTrackingService: Symbol.for('IErrorTrackingService'),
  IJobCategoriesService: Symbol.for('IJobCategoriesService'),
  IJobTagsService: Symbol.for('IJobTagsService'),
  ISkillsService: Symbol.for('ISkillsService'),
  IJobsService: Symbol.for('IJobsService'),
  IJobApplicationsService: Symbol.for('IJobApplicationsService'),
  ISubscriptionsService: Symbol.for('ISubscriptionsService'),
  IJobBookmarksService: Symbol.for('IJobBookmarksService'),
  IUserReferencesService: Symbol.for('IUserReferencesService'),
  ICompanyReferencesService: Symbol.for('ICompanyReferencesService'),
  IInvitationsService: Symbol.for('IInvitationsService'),
  IBlockchainsService: Symbol.for('IBlockchainsService'),
  ITokensService: Symbol.for('ITokensService'),
  IOffersService: Symbol.for('IOffersService'),
  IQuestionsService: Symbol.for('IQuestionsService'),
  IAnswersService: Symbol.for('IAnswersService'),
  IQuestionCategoriesService: Symbol.for('IQuestionCategoriesService'),
  INftsService: Symbol.for('INftsService'),

  // ============================================================
  // UseCases
  // ============================================================
  // Viewer
  ISignInUseCase: Symbol.for('ISignInUseCase'),
  ISignUpUseCase: Symbol.for('ISignUpUseCase'),
  ISignOutUseCase: Symbol.for('ISignOutUseCase'),
  ISendResetPasswordRequestUseCase: Symbol.for('ISendResetPasswordRequestUseCase'),
  IUpdatePasswordUseCase: Symbol.for('IUpdatePasswordUseCase'),
  IUpdatePasswordWithTokenUseCase: Symbol.for('IUpdatePasswordWithTokenUseCase'),
  IUpdateEmailUseCase: Symbol.for('IUpdateEmailUseCase'),
  IUpdateMeUseCase: Symbol.for('IUpdateMeUseCase'),
  IInitializeUseCase: Symbol.for('IInitializeUseCase'),
  IFetchMeUseCase: Symbol.for('IFetchMeUseCase'),
  IChangeLanguageUseCase: Symbol.for('IChangeLanguageUseCase'),
  IUpdateMyProfileUseCase: Symbol.for('IUpdateMyProfileUseCase'),
  IAddExperienceUseCase: Symbol.for('IAddExperienceUseCase'),
  IUpdateExperienceUseCase: Symbol.for('IUpdateExperienceUseCase'),
  IRemoveExperienceUseCase: Symbol.for('IRemoveExperienceUseCase'),
  IFetchNotificationsUseCase: Symbol.for('IFetchNotificationsUseCase'),
  IAddUserAttachmentUseCase: Symbol.for('IAddUserAttachmentUseCase'),
  IUpdateUserAttachmentUseCase: Symbol.for('IUpdateUserAttachmentUseCase'),
  IDeleteUserAttachmentUseCase: Symbol.for('IDeleteUserAttachmentUseCase'),
  IAddTokenTransactionUseCase: Symbol.for('IAddTokenTransactionUseCase'),
  // Users
  IFetchAngelsUseCase: Symbol.for('IFetchAngelsUseCase'),
  IFetchFeaturedAngelsUseCase: Symbol.for('IFetchFeaturedAngelsUseCase'),
  IFetchUserUseCase: Symbol.for('IFetchUserUseCase'),
  ISearchUserUseCase: Symbol.for('ISearchUserUseCase'),
  IFetchUserForViewerUseCase: Symbol.for('IFetchUserForViewerUseCase'),
  IFetchJobSeekersUseCase: Symbol.for('IFetchJobSeekersUseCase'),
  // Companies
  IFetchCompaniesUseCase: Symbol.for('IFetchCompaniesUseCase'),
  IFetchStartupsUseCase: Symbol.for('IFetchStartupsUseCase'),
  IFetchVentureCapitalsUseCase: Symbol.for('IFetchVentureCapitalsUseCase'),
  IFetchFeaturedVentureCapitalsUseCase: Symbol.for('IFetchFeaturedVentureCapitalsUseCase'),
  IFetchFeaturedStartupsUseCase: Symbol.for('IFetchFeaturedStartupsUseCase'),
  IFetchFeaturedCompaniesWithJobsUseCase: Symbol.for('IFetchFeaturedCompaniesWithJobsUseCase'),
  IFetchCompanyUseCase: Symbol.for('IFetchCompanyUseCase'),
  IUpdateCompanyUseCase: Symbol.for('IUpdateCompanyUseCase'),
  ICreateMyCompanyUseCase: Symbol.for('ICreateMyCompanyUseCase'),
  ICreateCompanyUseCase: Symbol.for('ICreateCompanyUseCase'),
  IAddProductUseCase: Symbol.for('IAddProductUseCase'),
  IUpdateProductUseCase: Symbol.for('IUpdateProductUseCase'),
  IRemoveProductUseCase: Symbol.for('IRemoveProductUseCase'),
  ISearchCompanyUseCase: Symbol.for('ISearchCompanyUseCase'),
  IAddCompanyMemberUseCase: Symbol.for('IAddCompanyMemberUseCase'),
  IUpdateCompanyMemberUseCase: Symbol.for('IUpdateCompanyMemberUseCase'),
  IRemoveCompanyMemberUseCase: Symbol.for('IRemoveCompanyMemberUseCase'),
  IFetchCompaniesWithJobsUseCase: Symbol.for('IFetchCompaniesWithJobsUseCase'),
  IFetchCompanyForViewerUseCase: Symbol.for('IFetchCompanyForViewerUseCase'),
  // Investments
  IAddMyInvestmentUseCase: Symbol.for('IAddMyInvestmentUseCase'),
  IAddInvestorUseCase: Symbol.for('IAddMyInvestorUseCase'),
  IAddCompanyInvestmentUseCase: Symbol.for('IAddCompanyInvestmentUseCase'),
  IUpdateInvestmentUseCase: Symbol.for('IUpdateInvestmentUseCase'),
  IUpdateInvestorUseCase: Symbol.for('IUpdateInvestorUseCase'),
  IRemoveInvestmentUseCase: Symbol.for('IRemoveInvestmentUseCase'),
  IRemoveInvestorUseCase: Symbol.for('IRemoveInvestorUseCase'),
  // Articles
  IFetchMagazineTopPageContentUseCase: Symbol.for('IFetchMagazineTopPageContentUseCase'),
  IFetchMagazineArchivePageContentUseCase: Symbol.for('IFetchMagazineArchivePageContentUseCase'),
  IFetchMagazineSinglePageContentUseCase: Symbol.for('IFetchMagazineSinglePageContentUseCase'),
  IFetchArticlesUseCase: Symbol.for('IFetchArticlesUseCase'),
  IFetchCompanyArticlesUseCase: Symbol.for('IFetchCompanyArticlesUseCase'),
  IFetchMyCompanyArticlesUseCase: Symbol.for('IFetchMyCompanyArticlesUseCase'),
  IFetchCompanyArticleSinglePageContentUseCase: Symbol.for('IFetchCompanyArticleSinglePageContentUseCase'),
  ICreateCompanyArticleUseCase: Symbol.for('ICreateCompanyArticleUseCase'),
  IUpdateCompanyArticleUseCase: Symbol.for('IUpdateCompanyArticleUseCase'),
  IDeleteArticleUseCase: Symbol.for('IDeleteArticleUseCase'),
  IFetchUserArticlesUseCase: Symbol.for('IFetchUserArticlesUseCase'),
  IFetchMyArticlesUseCase: Symbol.for('IFetchMyArticlesUseCase'),
  ICreateMyArticleUseCase: Symbol.for('ICreateMyArticleUseCase'),
  IUpdateMyArticleUseCase: Symbol.for('IUpdateMyArticleUseCase'),
  IDeleteMyArticleUseCase: Symbol.for('IDeleteMyArticleUseCase'),
  IDeleteCompanyArticleUseCase: Symbol.for('IDeleteCompanyArticleUseCase'),
  IFetchFeaturedStoriesUseCase: Symbol.for('IFetchFeaturedStoriesUseCase'),
  IToggleArticleLikedStateUseCase: Symbol.for('IToggleArticleLikedStateUseCase'),
  IFetchArticleUseCase: Symbol.for('IFetchArticleUseCase'),
  IFetchPickedUpFeaturesUseCase: Symbol.for('IFetchPickedUpFeaturesUseCase'),
  // Chat
  IInitializeChatUseCase: Symbol.for('IInitializeChatUseCase'),
  ICreateChatMessageThreadSubscriptionUseCase: Symbol.for('ICreateChatMessageThreadSubscriptionUseCase'),
  ISendChatMessageUseCase: Symbol.for('ISendChatMessageUseCase'),
  IFindOrCreateChatMessageThreadUseCase: Symbol.for('IFindOrCreateChatMessageThreadUseCase'),
  IFetchChatMessagesUseCase: Symbol.for('IFetchChatMessagesUseCase'),
  IMarkAllMessagesAsReadUseCase: Symbol.for('IMarkAllMessagesAsReadUseCase'),
  // markets
  IFetchMarketsUseCase: Symbol.for('IFetchMarketsUseCase'),
  // ArticleAttachment
  ICreateArticleAttachmentUseCase: Symbol.for('ICreateArticleAttachmentUseCase'),
  // notifications
  IMarkAllNotificationsAsReadUseCase: Symbol.for('IMarkAllNotificationsAsReadUseCase'),
  // Location
  IFetchLocationsUseCase: Symbol.for('IFetchLocationsUseCase'),
  IFetchCountriesUseCase: Symbol.for('IFetchCountriesUseCase'),
  // JobCategories
  IFetchJobCategoriesUseCase: Symbol.for('IFetchJobCategoriesUseCase'),
  // JobTags
  IFetchJobTagsUseCase: Symbol.for('IFetchJobTagsUseCase'),
  // Skills
  IFetchSkillsUseCase: Symbol.for('IFetchSkillsUseCase'),
  ICreateSkillUseCase: Symbol.for('ICreateSkillUseCase'),
  // Jobs
  IFetchJobsUseCase: Symbol.for('IFetchJobsUseCase'),
  IFetchCompanyJobsUseCase: Symbol.for('IFetchCompanyJobsUseCase'),
  IFetchMyJobsUseCase: Symbol.for('IFetchMyJobsUseCase'),
  IFetchJobUseCase: Symbol.for('IFetchJobUseCase'),
  ICreateJobUseCase: Symbol.for('ICreateJobUseCase'),
  IDuplicateJobUseCase: Symbol.for('IDuplicateJobUseCase'),
  IUpdateJobUseCase: Symbol.for('IUpdateJobUseCase'),
  IDeleteJobUseCase: Symbol.for('IDeleteJobUseCase'),
  IAddFootprintToJobUseCase: Symbol.for('IAddFootprintToJobUseCase'),
  IFetchJobFootprintsUseCase: Symbol.for('IFetchJobFootprintsUseCase'),
  ICreateJobAttachmentUseCase: Symbol.for('ICreateJobAttachmentUseCase'),
  IFetchFeaturedJobsUseCase: Symbol.for('IFetchFeaturedJobsUseCase'),
  // JobApplications
  IFetchJobApplicationsUseCase: Symbol.for('IFetchJobApplicationsUseCase'),
  IApplyForJobUseCase: Symbol.for('IApplyForJobUseCase'),
  // JobBookmarks
  IFetchMyJobBookmarksUseCase: Symbol.for('IFetchMyJobBookmarksUseCase'),
  IAddMyJobBookmarkUseCase: Symbol.for('IAddMyJobBookmarkUseCase'),
  IRemoveMyJobBookmarkUseCase: Symbol.for('IRemoveMyJobBookmarkUseCase'),
  IFetchJobBookmarksUseCase: Symbol.for('IFetchJobBookmarksUseCase'),
  // UserReferences
  IAddUserReferenceUseCase: Symbol.for('IAddUserReferenceUseCase'),
  IUpdateUserReferenceUseCase: Symbol.for('IUpdateUserReferenceUseCase'),
  IRemoveUserReferenceUseCase: Symbol.for('IRemoveUserReferenceUseCase'),
  IFetchMyReferencesUseCase: Symbol.for('IFetchMyReferencesUseCase'),
  IUpdateMyReferenceUseCase: Symbol.for('IUpdateMyReferenceUseCase'),
  // CompanyReferences
  IAddCompanyReferenceUseCase: Symbol.for('IAddCompanyReferenceUseCase'),
  IUpdateCompanyReferenceUseCase: Symbol.for('IUpdateCompanyReferenceUseCase'),
  IRemoveCompanyReferenceUseCase: Symbol.for('IRemoveCompanyReferenceUseCase'),
  IFetchMyCompanyReferencesUseCase: Symbol.for('IFetchMyCompanyReferencesUseCase'),
  IUpdateMyCompanyReferenceUseCase: Symbol.for('IUpdateMyCompanyReferenceUseCase'),
  // Invitations
  IValidInvitationTokenUseCase: Symbol.for('IValidInvitationTokenUseCase'),
  IAddAngelInvitationUseCase: Symbol.for('IAddAngelInvitationUseCase'),
  IAddVcInvitationUseCase: Symbol.for('IAddVcInvitationUseCase'),
  IAddAngelInvestmentInvitationUseCase: Symbol.for('IAddAngelInvestmentInvitationUseCase'),
  IAddVcInvestmentInvitationUseCase: Symbol.for('IAddVcInvestmentInvitationUseCase'),
  IAddMemberInvitationUseCase: Symbol.for('IAddMemberInvitationUseCase'),
  IAddUserInvitationUseCase: Symbol.for('IAddUserInvitationUseCase'),
  // Follow, Follower
  IToggleFollowCompanyUseCase: Symbol.for('IToggleFollowCompanyUseCase'),
  IToggleFollowUserUseCase: Symbol.for('IToggleFollowUserUseCase'),
  IFetchFollowingUseCase: Symbol.for('IFetchFollowingUseCase'),
  IFetchFollowersUseCase: Symbol.for('IFetchFollowersUseCase'),
  // NotificationSettings
  IUpdateCompanyNotificationSettingsUseCase: Symbol.for('IUpdateCompanyNotificationSettingsUseCase'),
  IUpdateUserNotificationSettingsUseCase: Symbol.for('IUpdateUserNotificationSettingsUseCase'),
  // Blockchains
  IFetchBlockchainsUseCase: Symbol.for('IFetchBlockchainsUseCase'),
  // Token
  IFetchTokensUseCase: Symbol.for('IFetchTokensUseCase'),
  IAddTokenUseCase: Symbol.for('IAddTokenUseCase'),
  // Offer
  IFetchOffersUseCase: Symbol.for('IFetchOffersUseCase'),
  IFetchUserOffersUseCase: Symbol.for('IFetchUserOffersUseCase'),
  IFetchMyOffersUseCase: Symbol.for('IFetchMyOffersUseCase'),
  IFetchOfferUseCase: Symbol.for('IFetchOfferUseCase'),
  ICreateMyOfferUseCase: Symbol.for('ICreateMyOfferUseCase'),
  IUpdateMyOfferUseCase: Symbol.for('IUpdateMyOfferUseCase'),
  IDeleteMyOfferUseCase: Symbol.for('IDeleteMyOfferUseCase'),
  // QuestionCategories
  IFetchQuestionCategoriesUseCase: Symbol.for('IFetchQuestionCategoriesUseCase'),
  // Question
  IFetchQuestionsUseCase: Symbol.for('IFetchQuestionsUseCase'),
  IFetchQuestionUseCase: Symbol.for('IFetchQuestionUseCase'),
  IFetchUserQuestionsUseCase: Symbol.for('IFetchUserQuestionsUseCase'),
  ICreateMyQuestionUseCase: Symbol.for('ICreateMyQuestionUseCase'),
  IUpdateMyQuestionUseCase: Symbol.for('IUpdateMyQuestionUseCase'),
  IDeleteMyQuestionUseCase: Symbol.for('IDeleteMyQuestionUseCase'),
  IVoteForChoiceUseCase: Symbol.for('IVoteForChoiceUseCase'),
  // Answer
  ICreateMyAnswerUseCase: Symbol.for('ICreateMyAnswerUseCase'),
  ICreateMyReplyUseCase: Symbol.for('ICreateMyReplyUseCase'),
  IUpdateMyAnswerUseCase: Symbol.for('IUpdateMyAnswerUseCase'),
  IDeleteMyAnswerUseCase: Symbol.for('IDeleteMyAnswerUseCase'),
  IFetchReplyUseCase: Symbol.for('IFetchReplyUseCase'),
  // Nft
  IFetchNftListingsUseCase: Symbol.for('IFetchNftListingsUseCase'),
  IFindAuctionHouseUseCase: Symbol.for('IFindAuctionHouseUseCase'),
  IFetchNftsUseCase: Symbol.for('IFetchNftsUseCase'),
  IFetchNftUseCase: Symbol.for('IFetchNftUseCase'),
  IFetchNftsCountUseCase: Symbol.for('IFetchNftsCountUseCase'),
  IChangeSoldOutUseCase: Symbol.for('IChangeSoldOutUseCase'),
}

export default symbols
