import { action, computed, observable } from 'mobx'
import { AnswerBase, AnswerStatusBase, IAnswerBase, IErrorsStore, IUserAnswer } from '@/types'

export default class UserAnswer implements IUserAnswer {
  @observable id = ''

  @observable body = ''

  @observable slug = ''

  @observable status: AnswerStatusBase

  @observable parentReply: AnswerBase

  @observable childReplies: AnswerBase[]

  @observable createdAt = ''

  @observable updatedAt = ''

  errorsStore: IErrorsStore

  @computed
  get statusName(): string {
    if (this.status === AnswerStatusBase.BEST) {
      return 'best'
    }
    if (this.status === AnswerStatusBase.HIDDEN) {
      return 'hidden'
    }
    if (this.status === AnswerStatusBase.SHOWN) {
      return 'shown'
    }

    return ''
  }

  @action
  _mapBase(base: IAnswerBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // TODO: いい実装考える
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  constructor(base: IAnswerBase) {
    this._mapBase(base)
  }
}
