import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  FetchJobSeekersUseCaseInput,
  FetchJobSeekersUseCaseOutput,
  IAppErrorFactory,
  IFetchJobSeekersUseCase,
  IUseCaseOutputFactory,
  IUserBase,
  IUsersService,
} from '@/types'

@injectable()
export default class FetchJobSeekersInteractor implements IFetchJobSeekersUseCase {
  @inject(symbols.IUsersService) private usersService: IUsersService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    jobSeekers: null | IUserBase[]
    hasNextPage: boolean
  }>

  async handle(input: FetchJobSeekersUseCaseInput): Promise<FetchJobSeekersUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        jobSeekers: null,
        hasNextPage: false,
      },
    })
    try {
      const { jobSeekers, hasNextPage } = await this.usersService.fetchJobSeekers(input)
      output.data.jobSeekers = jobSeekers
      output.data.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
