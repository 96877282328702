import { inject, injectable } from 'inversify'
import {
  FetchCompanyArticleSinglePageContentUseCaseInput,
  FetchCompanyArticleSinglePageContentUseCaseOutput,
  IAppErrorFactory,
  IArticlesService,
  IFetchCompanyArticleSinglePageContentUseCase,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FetchCompanyArticleSinglePageContentInteractor
  implements IFetchCompanyArticleSinglePageContentUseCase {
  @inject(symbols.IArticlesService) private articleService: IArticlesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(
    input: FetchCompanyArticleSinglePageContentUseCaseInput
  ): Promise<FetchCompanyArticleSinglePageContentUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const [article, companyArticles] = await Promise.all([
        this.articleService.fetchArticle(input.articleSlug),
        this.articleService.fetchCompanyArticles({ slug: input.companySlug, limit: 4, shouldRefresh: true }),
      ])

      // 記事のcompany.slugとcompanySlugの値が違ったらURLが間違っているのでエラーを返す。
      if (article?.company.slug !== input.companySlug) {
        throw new Error()
      }

      output.article = article
      // articleと以外のcompanyArticleを3つ取得
      output.recommendedArticles = companyArticles.articles.filter((a) => a?.slug !== article.slug).slice(0, 3)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchCompanyArticleSinglePageContentUseCaseOutput {
    return {
      article: null,
      recommendedArticles: [],
      error: null,
    }
  }
}
