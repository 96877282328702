import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  AddTokenUseCaseInput,
  AddTokenUseCaseOutput,
  IAppErrorFactory,
  IAddTokenUseCase,
  ITokensService,
} from '../../types'

@injectable()
export default class AddTokenInteractor implements IAddTokenUseCase {
  @inject(symbols.ITokensService) private tokensService: ITokensService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: AddTokenUseCaseInput): Promise<AddTokenUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.token = await this.tokensService.addToken(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): AddTokenUseCaseOutput {
    return {
      token: null,
      error: null,
    }
  }
}
