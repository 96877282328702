import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  CreateMyAnswerUseCaseInput,
  CreateMyAnswerUseCaseOutput,
  IAppErrorFactory,
  IAnswersService,
  ICreateMyAnswerUseCase,
  IUserAnswerFactory,
  IUseCaseOutputFactory,
  IUserAnswer,
} from '../../types'

@injectable()
export default class CreateMyAnswerInteractor implements ICreateMyAnswerUseCase {
  @inject(symbols.IAnswersService) private answersService: IAnswersService

  @inject(symbols.IUserAnswerFactory) private userAnswerFactory: IUserAnswerFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    answer: null | IUserAnswer
  }>

  async handle(input: CreateMyAnswerUseCaseInput): Promise<CreateMyAnswerUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        answer: null,
      },
    })
    try {
      const base = await this.answersService.createMyAnswer(input)
      output.data.answer = this.userAnswerFactory.create({ base })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'Failed to add your reply.',
      })
    }

    return output
  }
}
