import { action, observable } from 'mobx'
import remotedev, { RemoteDevConfig } from 'mobx-remotedev'
import { injectable } from 'inversify'
import { InvestorType, IUIStore } from '@/types'

const remoteDevConfig: RemoteDevConfig = {
  name: 'UIStore',
  global: true,
  remote: false,
}

@remotedev(remoteDevConfig)
@injectable()
export default class UIStore implements IUIStore {
  _mobileBreakPoint = 992

  // タブレット対応するときのブレークポイント設定用
  _largeMobileBreakPoint = 992

  @observable isRightDrawerOpen = false

  @observable isNotificationMenuOpen = false

  @observable isJobApplicationModalOpen = false

  @observable isRecruitPaymentModalOpen = false

  @observable isOnboardingModalOpen = false

  @observable isInvitationModalOpen = false

  @observable isUserInvitationModalOpen = false

  @observable investorType: InvestorType

  @observable isProfileInputModalOpen = false

  @observable isRecruitPaymentDescriptionModalOpen = false

  @observable isSignUpModalOpen = false

  @observable isSetUpProfileToSendDMsModalOpen = false

  @observable isForumModalOpen = false

  @observable isSolanaAddressModalOpen = false

  @observable isAdContactFormModalOpen = false

  get isMobile(): boolean {
    if (!process.browser) {
      return false
    }

    const viewportWidth = window.innerWidth
    if (viewportWidth >= this._mobileBreakPoint) {
      return false
    }

    return true
  }

  @action
  toggleRightDrawer = (): void => {
    this.isRightDrawerOpen = !this.isRightDrawerOpen
  }

  @action
  toggleNotificationMenu = (): void => {
    this.isNotificationMenuOpen = !this.isNotificationMenuOpen
  }

  @action
  toggleJobApplicationModal = (): void => {
    this.isJobApplicationModalOpen = !this.isJobApplicationModalOpen
  }

  @action
  toggleRecruitPaymentModal(): void {
    this.isRecruitPaymentModalOpen = !this.isRecruitPaymentModalOpen
  }

  @action
  toggleOnboardingModal(): void {
    this.isOnboardingModalOpen = !this.isOnboardingModalOpen
  }

  @action
  toggleInvitationModal(): void {
    this.isInvitationModalOpen = !this.isInvitationModalOpen
  }

  @action
  toggleUserInvitationModal(): void {
    this.isUserInvitationModalOpen = !this.isUserInvitationModalOpen
  }

  @action
  updateInvestorType(investorType: InvestorType): void {
    this.investorType = investorType
  }

  @action
  toggleProfileInputModal(): void {
    this.isProfileInputModalOpen = !this.isProfileInputModalOpen
  }

  @action
  toggleRecruitPaymentDescriptionModal(): void {
    this.isRecruitPaymentDescriptionModalOpen = !this.isRecruitPaymentDescriptionModalOpen
  }

  @action
  toggleSignUpModal(): void {
    this.isSignUpModalOpen = !this.isSignUpModalOpen
  }

  @action
  toggleSetUpProfileToSendDMsModal(): void {
    this.isSetUpProfileToSendDMsModalOpen = !this.isSetUpProfileToSendDMsModalOpen
  }

  @action
  toggleForumModal(): void {
    this.isForumModalOpen = !this.isForumModalOpen
  }

  @action
  toggleSolanaAddressModal(): void {
    this.isSolanaAddressModalOpen = !this.isSolanaAddressModalOpen
  }

  @action
  toggleAdContactFormModalOpen(): void {
    this.isAdContactFormModalOpen = !this.isAdContactFormModalOpen
  }
}
