export const DEFAULT_COMPANY_LOGO_PATH = '/images/common/default/company.png'
export const DEFAULT_USER_AVATAR_PATH = '/images/common/default/user.png'
export const DEFAULT_HEADER_IMAGE_PATH = '/images/common/default/header.png'
export const DEFAULT_PUBLIC_HEADER_IMAGE_PATH = '/images/common/default/public_header.png'
export const DEFAULT_PRODUCT_IMAGE_PATH = '/images/common/default/service.png'
export const DEFAULT_TOKEN_IMAGE_PATH = '/images/common/default/token.png'
export const DEFAULT_ARTICLE_THUMBNAIL_PATH = '/images/common/default/article.png'
export const URL_PATTERN = /(https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+(?=\s|$))/
export const LINK_FOR_NOTES_ON_CREATING_JOB = 'https://protocoljp.notion.site/91b88185d7644e1ca39fafac4cc4f27a'
export const DEFAULT_NFTS_PAGE_HEADER_IMAGE_PATH = '/images/common/default/nfts_page_header.png'
export const TOKEN_PER_SOL = 169492
