import { action, observable } from 'mobx'
import remotedev, { RemoteDevConfig } from 'mobx-remotedev'
import { inject, injectable } from 'inversify'
import {
  ChangeSoldOutStoreInput,
  ChangeSoldOutStoreOutput,
  FetchNftsCountStoreOutput,
  FetchNftsStoreInput,
  FetchNftsStoreOutput,
  IListingBase,
  INftBase,
  INftsStore,
} from '@/types'
import symbols from '@/symbols'
import {
  FetchNftUseCaseInput,
  FetchNftUseCaseOutput,
  IChangeSoldOutUseCase,
  IFetchNftsCountUseCase,
  IFetchNftsUseCase,
  IFetchNftUseCase,
} from '@/types/useCases/nfts'

const remoteDevConfig: RemoteDevConfig = {
  name: 'NftsStore',
  global: true,
  remote: false,
}

@remotedev(remoteDevConfig)
@injectable()
export default class NftsStore implements INftsStore {
  // @observable auctionHouse: AuctionHouse = null

  // @observable seller: PublicKey = new PublicKey(process.env.NEXT_PUBLIC_NFT_SELLER_PUBLICKEY)

  // @observable listings: FindListingsOutput = []

  @observable nfts: INftBase[] = []

  @observable nftsCount: number

  @observable hasNextPage = true

  constructor(
    // @inject(symbols.IFindAuctionHouseUseCase) private findAuctionHouseUseCase: IFindAuctionHouseUseCase,
    // @inject(symbols.IFetchNftListingsUseCase) private fetchNftListingsUseCase: IFetchNftListingsUseCase,
    // @inject(symbols.IUseCaseOutputFactory)
    // private fetchNftListingsOutputFactory: IUseCaseOutputFactory<{
    //   listings: null | FindListingsOutput
    // }>
    @inject(symbols.IFetchNftsUseCase) private fetchNftsUseCase: IFetchNftsUseCase,
    @inject(symbols.IFetchNftUseCase) private fetchNftUseCase: IFetchNftUseCase,
    @inject(symbols.IFetchNftsCountUseCase) private fetchNftsCountUseCase: IFetchNftsCountUseCase,
    @inject(symbols.IChangeSoldOutUseCase) private changeSoldOutUseCase: IChangeSoldOutUseCase
  ) {
    //
  }

  // @action
  // _updateIsInitialized(): void {
  //   this.isInitialFetched = true
  // }

  // @action
  // _toggleIsFetching(): void {
  //   this.isFetching = !this.isFetching
  // }

  // @action
  // _updatelistings(listings: FindListingsOutput): void {
  //   this.listings = listings
  // }

  @action
  updateNfts(nfts: INftBase[]): void {
    this.nfts = nfts
  }

  @action
  _addNfts(nfts: INftBase[]): void {
    nfts.forEach((newNft) => {
      if (this.nfts.some((n) => n.mintAddress === newNft.mintAddress)) {
        return
      }

      this.nfts = this.nfts.concat(newNft)
    })
  }

  @action
  updateHasNextPage(hasNextPage: boolean): void {
    this.hasNextPage = hasNextPage
  }

  @action
  updateNftsCount(nftsCount: number): void {
    this.nftsCount = nftsCount
  }

  @action
  updateListing(mintAddress: string, listing: IListingBase): void {
    this.nfts.forEach((nft, i) => {
      if (nft.mintAddress === mintAddress) {
        this.nfts[i].listing = listing
      }
    })
  }

  // async findAuctionHouse(input: FindAuctionHouseStoreInput): Promise<FindAuctionHouseStoreOutput> {
  //   const output = await this.findAuctionHouseUseCase.handle(input)

  //   if (output.isSuccessful) {
  //     this.auctionHouse = output.data.auctionHouse

  //     return output
  //   }
  //   return null
  // }

  // async fetchNftListings(input: FetchNftListingsStoreIntput): Promise<FetchNftListingsStoreOutput> {
  //   this._toggleIsFetching()

  //   if (!this.auctionHouse) {
  //     const findAuctionHouseOutput = await this.findAuctionHouse({ metaplex: input.metaplex })
  //     if (!findAuctionHouseOutput.isSuccessful) {
  //       const output = this.fetchNftListingsOutputFactory.create({
  //         defaultValue: {
  //           listings: null,
  //         },
  //       })

  //       output.error = findAuctionHouseOutput.error
  //       return output
  //     }
  //   }

  //   const output = await this.fetchNftListingsUseCase.handle({
  //     metaplex: input.metaplex,
  //     auctionHouse: this.auctionHouse,
  //     seller: this.seller,
  //   })

  //   if (output.isSuccessful) {
  //     if (!this.isInitialFetched) {
  //       this._updateIsInitialized()
  //     }
  //     this._updatelistings(output.data.listings)
  //   }

  //   this._toggleIsFetching()

  //   return output
  // }

  // async fetchListingByAddress(metaplex: Metaplex): Promise<boolean> {
  //   const receiptAddress = this.listings[0].receiptAddress
  //   const list = await metaplex.auctionHouse().findListings({
  //     auctionHouse: this.auctionHouse,
  //     seller: this.seller,
  //     mint: new PublicKey('BEtp6GtvKup2W3oDhkdK9v8QJkxDT3mhu9LcnZEZbVvZ')
  //   })

  //   return true
  // }

  async fetchNfts(input: FetchNftsStoreInput): Promise<FetchNftsStoreOutput> {
    const output = await this.fetchNftsUseCase.handle(input)

    if (output.isSuccessful) {
      if (input.shouldRefresh) {
        this.updateNfts(output.data.nfts)
      } else {
        this._addNfts(output.data.nfts)
      }
      this.updateHasNextPage(output.data.hasNextPage)
    }

    return output
  }

  async fetchNft(input: FetchNftUseCaseInput): Promise<FetchNftUseCaseOutput> {
    const output = await this.fetchNftUseCase.handle(input)

    return output
  }

  async fetchNftsCount(): Promise<FetchNftsCountStoreOutput> {
    const output = await this.fetchNftsCountUseCase.handle()

    if (output.isSuccessful) {
      this.updateNftsCount(output.data.nftsCount.count)
    }

    return output
  }

  async changeSoldOut(input: ChangeSoldOutStoreInput): Promise<ChangeSoldOutStoreOutput> {
    const output = await this.changeSoldOutUseCase.handle({ listingAddress: input.listingAddress })

    if (output.isSuccessful) {
      this.updateListing(input.mintAddress, output.data.listing)
    }

    return output
  }
}
