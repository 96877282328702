import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchMyJobsUseCaseInput,
  FetchMyJobsUseCaseOutput,
  IAppErrorFactory,
  IFetchMyJobsUseCase,
  IJob,
  IJobFactory,
  IJobsService,
  IUseCaseOutputFactory,
} from '../../types'

@injectable()
export default class FetchMyJobsInteractor implements IFetchMyJobsUseCase {
  @inject(symbols.IJobsService) private jobsService: IJobsService

  @inject(symbols.IJobFactory) private jobFactory: IJobFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    jobs: null | IJob[]
    hasNextPage: null | boolean
  }>

  async handle(input: FetchMyJobsUseCaseInput): Promise<FetchMyJobsUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        jobs: null,
        hasNextPage: null,
      },
    })
    try {
      const { jobs, hasNextPage } = await this.jobsService.fetchMyJobs(input)
      output.data.jobs = jobs.map((j) => this.jobFactory.create({ base: j }))
      output.data.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
