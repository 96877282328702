import { inject, injectable } from 'inversify'
import {
  FetchArticlesUseCaseInput,
  FetchArticlesUseCaseOutput,
  IAppErrorFactory,
  IArticlesService,
  IFetchArticlesUseCase,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FetchArticlesInteractor implements IFetchArticlesUseCase {
  @inject(symbols.IArticlesService) private articlesService: IArticlesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchArticlesUseCaseInput): Promise<FetchArticlesUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      // TODO: Gateway を改良して1リクエストでランキング取得処理まで対応できるように変更
      const { articles, hasNextPage } = await this.articlesService.fetchArticles({
        categories: input.categories,
        cursorKey: input.categories.join(),
        limit: input.limit,
      })
      output.articles = articles
      output.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchArticlesUseCaseOutput {
    return {
      articles: [],
      hasNextPage: true,
      error: null,
    }
  }
}
