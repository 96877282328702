import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  CreateSkillUseCaseInput,
  CreateSkillUseCaseOutput,
  IAppErrorFactory,
  ICreateSkillUseCase,
  ISkillsService,
} from '../../types'

@injectable()
export default class CreateSkillInteractor implements ICreateSkillUseCase {
  @inject(symbols.ISkillsService) private skillService: ISkillsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: CreateSkillUseCaseInput): Promise<CreateSkillUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.skill = await this.skillService.createSkill(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): CreateSkillUseCaseOutput {
    return {
      skill: null,
      error: null,
    }
  }
}
