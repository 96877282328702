import { inject, injectable } from 'inversify'
import UserAttachment from '@/entities/UserAttachment'
import symbols from '@/symbols'
import {
  IErrorsStore,
  IUpdateUserAttachmentUseCase,
  IUserAttachment,
  IUserAttachmentFactory,
  UserAttachmentFactoryInput,
} from '@/types'

@injectable()
export default class UserAttachmentFactory implements IUserAttachmentFactory {
  @inject(symbols.IUpdateUserAttachmentUseCase) private updateUserAttachmentUseCase: IUpdateUserAttachmentUseCase

  @inject(symbols.IErrorsStore) private errorStore: IErrorsStore

  public create(input: UserAttachmentFactoryInput): IUserAttachment {
    const userAttachment = new UserAttachment(input.base)
    userAttachment.errorsStore = this.errorStore
    userAttachment.updateUserAttachmentUseCase = this.updateUserAttachmentUseCase
    return userAttachment
  }
}
