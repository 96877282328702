import { IAppErrorFactory, IUseCaseOutputFactory } from '@/types'
import {
  FetchNftListingsUseCaseIntput,
  FetchNftListingsUseCaseOutput,
  IFetchNftListingsUseCase,
} from '@/types/useCases/nfts'
import { FindListingsOutput } from '@metaplex-foundation/js'
import { inject, injectable } from 'inversify'
import symbols from '../../symbols'

@injectable()
export default class FetchNftListingsInteractor implements IFetchNftListingsUseCase {
  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    listings: null | FindListingsOutput
  }>

  async handle(input: FetchNftListingsUseCaseIntput): Promise<FetchNftListingsUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        listings: null,
      },
    })

    try {
      const listings = await input.metaplex.auctionHouse().findListings({
        auctionHouse: input.auctionHouse,
        seller: input.seller,
      })
      output.data.listings = listings
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
