import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  CreateMyQuestionUseCaseInput,
  CreateMyQuestionUseCaseOutput,
  IAppErrorFactory,
  IQuestionsService,
  ICreateMyQuestionUseCase,
  IUserQuestionFactory,
  IUseCaseOutputFactory,
  IUserQuestion,
} from '../../types'

@injectable()
export default class CreateMyQuestionInteractor implements ICreateMyQuestionUseCase {
  @inject(symbols.IQuestionsService) private questionsService: IQuestionsService

  @inject(symbols.IUserQuestionFactory) private userQuestionFactory: IUserQuestionFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    question: null | IUserQuestion
  }>

  async handle(input: CreateMyQuestionUseCaseInput): Promise<CreateMyQuestionUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        question: null,
      },
    })
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      input.question.pollDeadline = input.question.pollDeadline || null
      const base = await this.questionsService.createMyQuestion(input)
      output.data.question = this.userQuestionFactory.create({ base })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
