import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import { FetchCountriesUseCaseOutput, IAppErrorFactory, IFetchCountriesUseCase, ILocationsService } from '../../types'

@injectable()
export default class FetchCountriesInteractor implements IFetchCountriesUseCase {
  @inject(symbols.ILocationsService) private locationService: ILocationsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(): Promise<FetchCountriesUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const countries = await this.locationService.fetchCountries()
      output.countries = countries
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchCountriesUseCaseOutput {
    return {
      countries: [],
      error: null,
    }
  }
}
