import {
  ChangeSoldOutUseCaseInput,
  ChangeSoldOutUseCaseOutput,
  IAppErrorFactory,
  IChangeSoldOutUseCase,
  IListingBase,
  INftsService,
  IUseCaseOutputFactory,
} from '@/types'
import { inject, injectable } from 'inversify'
import symbols from '../../symbols'

@injectable()
export default class ChangeSoldOutInteractor implements IChangeSoldOutUseCase {
  @inject(symbols.INftsService) private nftsService: INftsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    listing: null | IListingBase
  }>

  async handle(input: ChangeSoldOutUseCaseInput): Promise<ChangeSoldOutUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        listing: null,
      },
    })

    try {
      const listing = await this.nftsService.changeSoldOut(input)

      output.data.listing = listing
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
