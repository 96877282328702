import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  ICompaniesService,
  IUpdateCompanyUseCase,
  UpdateCompanyUseCaseInput,
  UpdateCompanyUseCaseOutput,
} from '@/types'
import symbols from '@/symbols'

@injectable()
export default class UpdateCompanyInteractor implements IUpdateCompanyUseCase {
  @inject(symbols.ICompaniesService) private companiesService: ICompaniesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: UpdateCompanyUseCaseInput): Promise<UpdateCompanyUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.company = await this.companiesService.updateCompany({
        id: input.id,
        company: input.company,
      })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: '会社情報の更新に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): UpdateCompanyUseCaseOutput {
    return {
      company: null,
      error: null,
    }
  }
}
