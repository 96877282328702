import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  ICompaniesService,
  IRemoveProductUseCase,
  RemoveProductUseCaseInput,
  RemoveProductUseCaseOutput,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class RemoveProductInteractor implements IRemoveProductUseCase {
  @inject(symbols.ICompaniesService) private companiesService: ICompaniesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: RemoveProductUseCaseInput): Promise<RemoveProductUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.product = await this.companiesService.removeProduct({
        id: input.id,
      })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'プロダクトの削除に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): RemoveProductUseCaseOutput {
    return {
      product: null,
      error: null,
    }
  }
}
