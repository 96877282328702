import { FC } from 'react'
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui'
import { clusterApiUrl, Cluster } from '@solana/web3.js'
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets'

require('@solana/wallet-adapter-react-ui/styles.css')

export const WalletContextProvider: FC = ({ children }) => {
  const wallets = [new PhantomWalletAdapter()]

  let endpoint: string
  // 環境変数に endpoit が設定されている場合はそれを使用する
  if (process.env.NEXT_PUBLIC_SOLANA_ENDPOINT) {
    endpoint = process.env.NEXT_PUBLIC_SOLANA_ENDPOINT
  } else {
    endpoint = clusterApiUrl(process.env.NEXT_PUBLIC_SOLANA_CLUSTER_TYPE as Cluster)
  }

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  )
}
