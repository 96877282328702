import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchReplyUseCaseInput,
  FetchReplyUseCaseOutput,
  IAppErrorFactory,
  IFetchReplyUseCase,
  IQuestionsService,
} from '../../types'

@injectable()
export default class FetchReplyInteractor implements IFetchReplyUseCase {
  @inject(symbols.IQuestionsService) private questionsService: IQuestionsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchReplyUseCaseInput): Promise<FetchReplyUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const reply = await this.questionsService.fetchReply(input)

      output.reply = reply
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchReplyUseCaseOutput {
    return {
      reply: null,
      error: null,
    }
  }
}
