import { inject, injectable } from 'inversify'
import { action, computed, observable } from 'mobx'
import symbols from '@/symbols'
import {
  AddTokenTransactionUseCaseInput,
  AddTokenTransactionUseCaseOutput,
  AddUserAttachmentUseCaseOutput,
  ArticleAttachmentBase,
  ArticleAttachmentInputBase,
  ArticleInputBase,
  ATAInfoType,
  CreateMyAnswerUseCaseOutput,
  CreateMyOfferUseCaseOutput,
  CreateMyQuestionUseCaseOutput,
  CreateMyReplyUseCaseOutput,
  FetchMyJobBookmarksInput,
  FetchMyOffersUseCaseInput,
  FetchMyOffersUseCaseOutput,
  FetchNotificationsInput,
  IAddAngelInvestmentInvitationUseCase,
  IAddCompanyReferenceUseCase,
  IAddExperienceUseCase,
  IAddMyInvestmentUseCase,
  IAddMyJobBookmarkUseCase,
  IAddTokenTransactionUseCase,
  IAddUserAttachmentUseCase,
  IAddUserInvitationUseCase,
  IAddUserReferenceUseCase,
  IAngelInvestmentInvitationInputBase,
  IAnswerBase,
  IArticleBase,
  IChoiceInputBase,
  ICompanyBase,
  ICompanyFactory,
  ICompanyMemberFactory,
  ICompanyReferenceBase,
  ICreateArticleAttachmentUseCase,
  ICreateMyAnswerUseCase,
  ICreateMyArticleUseCase,
  ICreateMyOfferUseCase,
  ICreateMyQuestionUseCase,
  ICreateMyReplyUseCase,
  IDeleteMyAnswerUseCase,
  IDeleteMyArticleUseCase,
  IDeleteMyOfferUseCase,
  IDeleteMyQuestionUseCase,
  IDeleteUserAttachmentUseCase,
  IDL,
  IErrorsStore,
  IExperience,
  IExperienceFactory,
  IExperienceInputBase,
  IFetchMyArticlesUseCase,
  IFetchMyJobBookmarksUseCase,
  IFetchMyOffersUseCase,
  IFetchMyReferencesUseCase,
  IFetchNotificationsUseCase,
  IInvestment,
  IInvestmentFactory,
  IInvestmentInputBase,
  IJobBookmarkBase,
  IMarkAllNotificationsAsReadUseCase,
  IMyCompany,
  IMyCompanyMember,
  IMyReference,
  INotificationBase,
  InvitationOfBase,
  IOfferBase,
  IOfferInputBase,
  IQuestionInputBase,
  IRemoveCompanyReferenceUseCase,
  IRemoveExperienceUseCase,
  IRemoveInvestmentUseCase,
  IRemoveMyJobBookmarkUseCase,
  IRemoveUserReferenceUseCase,
  IToggleFollowCompanyUseCase,
  IToggleFollowUserUseCase,
  ITokenBase,
  IUpdateCompanyNotificationSettingsUseCase,
  IUpdateCompanyReferenceUseCase,
  IUpdateMyAnswerUseCase,
  IUpdateMyQuestionUseCase,
  IUpdateUserNotificationSettingsUseCase,
  IUpdateUserReferenceUseCase,
  IUserArticle,
  IUserAttachment,
  IUserAttachmentBase,
  IUserAttachmentFactory,
  IUserAttachmentInputBase,
  IUserBase,
  IUserInvitationInputBase,
  IUserOffer,
  IUserProfile,
  IUserProfileFactory,
  IUserQuestion,
  IUserReferenceBase,
  IViewer,
  IViewerBase,
  NotificationConnection,
  SaleToken,
  TokenInfoType,
  UpdateMyAnswerUseCaseOutput,
  UpdateMyQuestionUseCaseOutput,
  UserAttachmentCategoryNameBase,
  UserProfileJobHuntingStatus,
  UserReferenceStatus,
} from '@/types'
import {
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  RpcResponseAndContext,
  SystemProgram,
  TokenAmount,
  Transaction,
  TransactionInstruction,
} from '@solana/web3.js'
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  createAssociatedTokenAccountInstruction,
  createTransferInstruction,
  getAccount,
  getAssociatedTokenAddress,
  TOKEN_PROGRAM_ID,
} from '@solana/spl-token'
import { Metaplex } from '@metaplex-foundation/js'
import { AnchorProvider, BN, Program, Wallet } from '@coral-xyz/anchor'
import { changeAmountToBasicPoints } from '@/utils'
import { TOKEN_PER_SOL } from '@/constants'

@injectable()
export default class Viewer implements IViewer {
  @observable id = ''

  @observable email: string = null

  @observable username: string = null

  @observable name: string = null

  @observable profile: IUserProfile = null

  @observable investments: IInvestment[] = []

  @observable experiences: IExperience[] = []

  @observable companyMembers: IMyCompanyMember[] = []

  @observable hasUnreadNotifications = false

  @observable allNotifications: INotificationBase[] = []

  @observable hasAllNotificationsNextPage = false

  @observable allNotificationsCursor = ''

  @observable myJobBookmarks: IJobBookmarkBase[] = []

  @observable myReferences: IMyReference[] = []

  @observable hasMyJobBookmarksNextPage = true

  @observable invitationOf: InvitationOfBase

  notifications: NotificationConnection & {
    nodes: INotificationBase[]
  }

  @observable isFirstSignIn = false

  @observable articles: IUserArticle[] = []

  @observable offers: IUserOffer[] = []

  @observable hasNextMyOffersPage = true

  @observable userAttachments: IUserAttachment[] = []

  @observable questions: IUserQuestion[] = []

  @observable hasNextMyQuestionsPage = true

  @observable solBalance = ''

  // ウォレットのアドレスの切り替えに対応するため Record で作成
  @observable associatedTokenAddresses: Record<string, ATAInfoType> = {}

  @observable tokenBalances: Record<string, string> = {}

  @observable tokenInfo: TokenInfoType = {
    image: 'https://arweave.net/gWsQ6F0FKh2N68FbZqbkeM9mygbHZOj8Zi-NBqTFUhs',
    symbol: 'AQA',
    decimals: 9,
  }

  @observable tokens: ITokenBase[] = []

  @observable exchangeRate: number = TOKEN_PER_SOL

  constructor(
    @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore,
    @inject(symbols.IAddExperienceUseCase) private addExperienceUseCase: IAddExperienceUseCase,
    @inject(symbols.IRemoveExperienceUseCase) private removeExperienceUseCase: IRemoveExperienceUseCase,
    @inject(symbols.IAddMyInvestmentUseCase) private addMyInvestmentUseCase: IAddMyInvestmentUseCase,
    @inject(symbols.IRemoveInvestmentUseCase) private removeInvestmentUseCase: IRemoveInvestmentUseCase,
    @inject(symbols.IMarkAllNotificationsAsReadUseCase)
    private markAllNotificationsAsReadUseCase: IMarkAllNotificationsAsReadUseCase,
    @inject(symbols.IFetchMyArticlesUseCase) private fetchMyArticlesUseCase: IFetchMyArticlesUseCase,
    @inject(symbols.ICreateMyArticleUseCase) private createMyArticleUseCase: ICreateMyArticleUseCase,
    @inject(symbols.IDeleteMyArticleUseCase) private deleteMyArticleUseCase: IDeleteMyArticleUseCase,
    @inject(symbols.ICreateArticleAttachmentUseCase)
    private createArticleAttachmentUseCase: ICreateArticleAttachmentUseCase,
    @inject(symbols.IFetchMyJobBookmarksUseCase)
    private fetchMyJobBookmarksUseCase: IFetchMyJobBookmarksUseCase,
    @inject(symbols.IAddMyJobBookmarkUseCase) private addMyJobBookmarkUseCase: IAddMyJobBookmarkUseCase,
    @inject(symbols.IRemoveMyJobBookmarkUseCase) private removeMyJobBookmarkUseCase: IRemoveMyJobBookmarkUseCase,
    @inject(symbols.IAddUserReferenceUseCase) private addUserReferenceUseCase: IAddUserReferenceUseCase,
    @inject(symbols.IUpdateUserReferenceUseCase) private updateUserReferenceUseCase: IUpdateUserReferenceUseCase,
    @inject(symbols.IRemoveUserReferenceUseCase) private removeUserReferenceUseCase: IRemoveUserReferenceUseCase,
    @inject(symbols.IAddCompanyReferenceUseCase) private addCompanyReferenceUseCase: IAddCompanyReferenceUseCase,
    @inject(symbols.IUpdateCompanyReferenceUseCase)
    private updateCompanyReferenceUseCase: IUpdateCompanyReferenceUseCase,
    @inject(symbols.IRemoveCompanyReferenceUseCase)
    private removeCompanyReferenceUseCase: IRemoveCompanyReferenceUseCase,
    @inject(symbols.IFetchMyReferencesUseCase) private fetchMyReferencesUseCase: IFetchMyReferencesUseCase,
    @inject(symbols.IToggleFollowCompanyUseCase) private toggleFollowCompanyUseCase: IToggleFollowCompanyUseCase,
    @inject(symbols.IToggleFollowUserUseCase) private toggleFollowUserUseCase: IToggleFollowUserUseCase,
    @inject(symbols.IUpdateCompanyNotificationSettingsUseCase)
    private updateCompanyNotificationSettingsUseCase: IUpdateCompanyNotificationSettingsUseCase,
    @inject(symbols.IUpdateUserNotificationSettingsUseCase)
    private updateUserNotificationSettingsUseCase: IUpdateUserNotificationSettingsUseCase,
    @inject(symbols.IFetchNotificationsUseCase) private fetchNotificationsUserUseCase: IFetchNotificationsUseCase,
    @inject(symbols.ICompanyFactory) private companyFactory: ICompanyFactory,
    @inject(symbols.IExperienceFactory) private experienceFactory: IExperienceFactory,
    @inject(symbols.IInvestmentFactory) private investmentFactory: IInvestmentFactory,
    @inject(symbols.IUserProfileFactory) private userProfileFactory: IUserProfileFactory,
    @inject(symbols.ICompanyMemberFactory) private companyMemberFactory: ICompanyMemberFactory,
    @inject(symbols.IAddAngelInvestmentInvitationUseCase)
    private addAngelInvestmentInvitationUseCase: IAddAngelInvestmentInvitationUseCase,
    @inject(symbols.IAddUserInvitationUseCase) private addUserInvitationUseCase: IAddUserInvitationUseCase,
    @inject(symbols.IFetchMyOffersUseCase) private fetchMyOffersUseCase: IFetchMyOffersUseCase,
    @inject(symbols.ICreateMyOfferUseCase) private createMyOfferUseCase: ICreateMyOfferUseCase,
    @inject(symbols.IDeleteMyOfferUseCase) private deleteMyOfferUseCase: IDeleteMyOfferUseCase,
    @inject(symbols.IAddUserAttachmentUseCase) private addUserAttachmentUseCase: IAddUserAttachmentUseCase,
    @inject(symbols.IDeleteUserAttachmentUseCase) private deleteUserAttachmentUseCase: IDeleteUserAttachmentUseCase,
    @inject(symbols.IUserAttachmentFactory) private userAttachmentFactory: IUserAttachmentFactory,
    @inject(symbols.ICreateMyQuestionUseCase) private createMyQuestionUseCase: ICreateMyQuestionUseCase,
    @inject(symbols.IUpdateMyQuestionUseCase) private updateMyQuestionUseCase: IUpdateMyQuestionUseCase,
    @inject(symbols.IDeleteMyQuestionUseCase) private deleteMyQuestionUseCase: IDeleteMyQuestionUseCase,
    @inject(symbols.ICreateMyAnswerUseCase) private createMyAnswerUseCase: ICreateMyAnswerUseCase,
    @inject(symbols.ICreateMyReplyUseCase) private createMyReplyUseCase: ICreateMyReplyUseCase,
    @inject(symbols.IUpdateMyAnswerUseCase) private updateMyAnswerUseCase: IUpdateMyAnswerUseCase,
    @inject(symbols.IDeleteMyAnswerUseCase) private deleteMyAnswerUseCase: IDeleteMyAnswerUseCase,
    @inject(symbols.IAddTokenTransactionUseCase) private addTokenTransactionUseCase: IAddTokenTransactionUseCase
  ) {}

  @action
  update(base: IViewerBase): void {
    this.id = base.id
    this.email = base.email
    this.username = base.username
    this.name = base.name
    this.hasUnreadNotifications = base.hasUnreadNotifications
    this.allNotifications = base.notifications.nodes
    this.hasAllNotificationsNextPage = base.notifications.pageInfo.hasNextPage
    this.allNotificationsCursor = base.notifications.pageInfo.endCursor
    this.isFirstSignIn = base.isFirstSignIn
    this.invitationOf = base.invitationOf

    // UserProfile に Factory を作成して割り当て
    this.profile = this.userProfileFactory.create({ base: base.profile })
    // CompanyMember の各Company に Factory を作成して割り当て
    this.companyMembers = base.companyMembers.map((companyMember) => {
      const company = this.companyFactory.create({
        base: companyMember.company,
      })
      company.receivedInvestments = company.receivedInvestments.map((i) => this.investmentFactory.create({ base: i }))
      return this.companyMemberFactory.create({
        company,
        base: companyMember,
      })
    })
    // Experience
    this.experiences = base.experiences.map((experience) => {
      return this.experienceFactory.create({ base: experience })
    })
    // Investment
    this.investments = base.investments.map((investment) => {
      return this.investmentFactory.create({
        base: investment,
      })
    })
    // UserAttachment
    this.userAttachments = base.userAttachments.map((userAttachment) => {
      return this.userAttachmentFactory.create({
        base: userAttachment,
      })
    })
  }

  @action
  updateProfile(profile: IUserProfile): void {
    this.profile = profile
  }

  @action
  updateName(name: string): void {
    this.name = name
  }

  @action
  updateUsername(username: string): void {
    this.username = username
  }

  @action
  addMyCompany(companyMember: IMyCompanyMember): void {
    // CompanyMember の Company に Factory を作成して割り当て
    companyMember.company = this.companyFactory.create({
      base: companyMember.company,
    })

    this.companyMembers.push(companyMember)
  }

  @action
  _addExperience(experience: IExperience): void {
    this.experiences.push(experience)
  }

  @action
  _removeExperience(experience: IExperience): void {
    this.experiences = this.experiences.filter((e) => e.id !== experience.id)
  }

  @action
  _addInvestment(investment: IInvestment): void {
    this.investments.push(investment)
  }

  @action
  _removeInvestment(investment: IInvestment): void {
    this.investments = this.investments.filter((i) => i.id !== investment.id)
  }

  // =========== notifications ===========
  @action
  _updateAllNotifications(notifications: INotificationBase[]): void {
    this.allNotifications = notifications
  }

  @action
  _addAllNotifications(notifications: INotificationBase[]): void {
    notifications.forEach((newNotification) => {
      if (this.allNotifications.some((n) => n.id === newNotification.id)) {
        return
      }

      this.allNotifications = this.allNotifications.concat(newNotification)
    })
  }

  @action
  _updateHasUnreadNotifications(hasUnreadNotifications: boolean): void {
    this.hasUnreadNotifications = hasUnreadNotifications
  }

  // 引数に渡した readNotifications を既読に更新
  @action
  _markNotificationsAsRead(readNotifications: INotificationBase[]): void {
    this.allNotifications.forEach((n) => {
      if (readNotifications.some((readNotification) => readNotification.id === n.id)) {
        n.isRead = true
      }
    })
  }

  @computed
  get latestNotifications(): INotificationBase[] {
    return this.allNotifications.slice(0, 5)
  }

  // =========== articles ===========
  @action
  _updateArticles(articles: IUserArticle[]): void {
    this.articles = articles
  }

  @action
  _addArticle(article: IUserArticle): void {
    this.articles.push(article)
  }

  @action
  _deleteArticle(article: IArticleBase): void {
    this.articles = this.articles.filter((a) => a.id !== article.id)
  }

  // =========== myJobBookmarks ===========
  @action
  _updateMyJobBookmarks(jobBookmarks: IJobBookmarkBase[]): void {
    this.myJobBookmarks = jobBookmarks
  }

  @action
  _addMyJobBookmarks(jobBookmarks: IJobBookmarkBase[]): void {
    jobBookmarks.forEach((newJobBookmark) => {
      if (this.myJobBookmarks.some((j) => j.id === newJobBookmark.id)) {
        return
      }

      this.myJobBookmarks = this.myJobBookmarks.concat(newJobBookmark)
    })
  }

  @action
  _updateHasNextMyJobBookmarks(hasNextPage: boolean): void {
    this.hasMyJobBookmarksNextPage = hasNextPage
  }

  @action
  _addMyJobBookmark(jobBookmark: IJobBookmarkBase): void {
    this.myJobBookmarks.unshift(jobBookmark)
  }

  @action
  _removeMyJobBookmark(jobBookmark: IJobBookmarkBase): void {
    this.myJobBookmarks = this.myJobBookmarks.filter((j) => j.id !== jobBookmark.id)
  }

  // =========== myReferences ===========
  @action
  _updateMyReferences(myReferences: IMyReference[]): void {
    this.myReferences = myReferences
  }

  @computed
  get shownStatusReferences(): IMyReference[] {
    return this.myReferences.filter((r) => r.status === UserReferenceStatus.SHOWN)
  }

  @computed
  get hiddenStatusReferences(): IMyReference[] {
    return this.myReferences.filter((r) => r.status === UserReferenceStatus.HIDDEN)
  }

  // =========== offers ===========
  @action
  updateHasNextMyOffersPage(hasNextPage: boolean): void {
    this.hasNextMyOffersPage = hasNextPage
  }

  @action
  _updateOffers(offers: IUserOffer[]): void {
    this.offers = offers
  }

  @action
  _addOffers(offers: IUserOffer[]): void {
    offers.forEach((newOffer) => {
      // 重複していたら処理をスキップ
      if (this.offers.some((o) => o.slug === newOffer.slug)) {
        return
      }

      // 末尾に追加
      this.offers = this.offers.concat(newOffer)
    })
  }

  @action
  _addOffer(offer: IUserOffer): void {
    this.offers.push(offer)
  }

  @action
  _deleteOffer(offer: IOfferBase): void {
    this.offers = this.offers.filter((o) => o.id !== offer.id)
  }

  // =========== userAttachment ===========
  @action
  _addUserAttachment(userAttachment: IUserAttachment): void {
    this.userAttachments.push(userAttachment)
  }

  @action
  _deleteUserAttachment(userAttachment: IUserAttachmentBase): void {
    this.userAttachments = this.userAttachments.filter((ua) => ua.id !== userAttachment.id)
  }

  @computed
  get userBase(): IUserBase {
    return {
      id: this.id,
      username: this.username,
      name: this.name,
      profile: this.profile,
      investments: this.investments,
      experiences: this.experiences,
    }
  }

  @computed
  get unreadNotifications(): INotificationBase[] {
    return this.allNotifications.filter((n) => !n.isRead)
  }

  @computed
  get hasSNSAccount(): boolean {
    if (
      this.profile?.facebookUrl ||
      this.profile?.githubUrl ||
      this.profile?.instagramUrl ||
      this.profile?.linkedinUrl ||
      this.profile?.twitterUrl ||
      this.profile?.websiteUrl ||
      this.profile?.telegramUrl ||
      this.profile?.openseaUrl ||
      this.profile?.discordUrl
    ) {
      return true
    }
    return false
  }

  @computed
  get hasDesiredEmploymentStatus(): boolean {
    if (
      this.profile?.isFulltimeEmployeeDesired ||
      this.profile?.isContractorDesired ||
      this.profile?.isCofounderDesired ||
      this.profile?.isInternDesired
    ) {
      return true
    }
    return false
  }

  @computed
  get basicProfileCompletionRate(): number {
    let count = 0
    if (this.profile?.avatar) {
      count++
    }
    if (this.profile?.jobTitle) {
      count++
    }
    if (this.profile?.bio) {
      count++
    }
    if (this.hasSNSAccount) {
      count++
    }
    if (this.experiences.length > 0) {
      count++
    }
    return Math.floor((count * 100) / 5)
  }

  @computed
  get investorProfileCompletionRate(): number {
    let count = 0
    if (this.profile?.minInvestmentAmount && this.profile?.maxInvestmentAmount) {
      count++
    }
    if (this.profile?.investmentTargetRounds.length > 0) {
      count++
    }
    if (this.profile?.investmentTargetMarkets.length > 0) {
      count++
    }
    return Math.floor((count * 100) / 3)
  }

  @computed
  get tokensCompletionRate(): number {
    let count = 0
    if (this.profile?.tokens.length > 0) {
      count++
    }
    return Math.floor(count * 100)
  }

  @computed
  get jobSeekerProfileCompletionRate(): number {
    let count = 0
    if (!(this.profile?.jobHuntingStatus === UserProfileJobHuntingStatus.UNKNOWN)) {
      count++
    }
    if (!(this.profile?.primaryJobCategory?.slug === 'unknown')) {
      count++
    }
    if (this.profile?.desiredThingsInNextJob) {
      count++
    }
    return Math.floor((count * 100) / 3)
  }

  @computed
  get myProfileCompletionRate(): number {
    const isInvestor = this.profile?.isInvestor || this.profile?.isAngel
    if (isInvestor && this.profile?.isJobSeeker) {
      return Math.floor(
        (this.basicProfileCompletionRate +
          this.investorProfileCompletionRate +
          this.tokensCompletionRate +
          this.jobSeekerProfileCompletionRate) /
          4
      )
    }
    if (isInvestor) {
      return Math.floor(
        (this.basicProfileCompletionRate + this.investorProfileCompletionRate + this.tokensCompletionRate) / 3
      )
    }
    if (this.profile?.isJobSeeker) {
      return Math.floor((this.basicProfileCompletionRate + this.jobSeekerProfileCompletionRate) / 2)
    }
    return this.basicProfileCompletionRate
  }

  @computed
  get resumes(): IUserAttachment[] {
    return this.userAttachments.filter((ua) => ua.categoryName === UserAttachmentCategoryNameBase.RESUME)
  }

  @computed
  get portfolios(): IUserAttachment[] {
    return this.userAttachments.filter((ua) => ua.categoryName === UserAttachmentCategoryNameBase.PORTFOLIO)
  }

  getMyCompanyBySlug(slug: string): IMyCompany {
    const member = this.companyMembers.find((companyMember) => companyMember.company.slug === slug)
    return member?.company
  }

  isCompanyMember(slug: string): boolean {
    return this.companyMembers.some((companyMember) => companyMember.company.slug === slug)
  }

  async addExperience(experience: IExperienceInputBase): Promise<boolean> {
    const output = await this.addExperienceUseCase.handle({
      experience,
    })

    if (output.experience) {
      this._addExperience(output.experience)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async removeExperience(experience: IExperience): Promise<boolean> {
    const output = await this.removeExperienceUseCase.handle({
      id: experience.id,
    })

    if (output.experience) {
      this._removeExperience(experience)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async addMyInvestment(investment: IInvestmentInputBase): Promise<boolean> {
    const output = await this.addMyInvestmentUseCase.handle({
      investment,
    })
    if (output.investment) {
      this._addInvestment(output.investment)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async removeInvestment(investment: IInvestment): Promise<boolean> {
    const output = await this.removeInvestmentUseCase.handle({
      id: investment.id,
    })
    if (output.investment) {
      this._removeInvestment(investment)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async markAllNotificationsAsRead(): Promise<boolean> {
    if (this.unreadNotifications.length === 0) {
      return true
    }

    const output = await this.markAllNotificationsAsReadUseCase.handle({
      unreadNotifications: this.unreadNotifications,
    })

    this._updateHasUnreadNotifications(output.hasUnreadNotifications)

    if (output.notifications) {
      this._markNotificationsAsRead(output.notifications)
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return true
  }

  async fetchMyArticles(): Promise<boolean> {
    const output = await this.fetchMyArticlesUseCase.handle({ limit: 100 })

    if (output.error) {
      this.errorsStore.handle(output.error)
      return false
    }

    this._updateArticles(output.articles)

    return true
  }

  async addMyArticle(article: ArticleInputBase): Promise<boolean> {
    const output = await this.createMyArticleUseCase.handle({ article })

    if (output.article) {
      this._addArticle(output.article)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async removeMyArticle(id: string): Promise<boolean> {
    const output = await this.deleteMyArticleUseCase.handle({ id })

    if (output.article) {
      this._deleteArticle(output.article)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async addArticleAttachment(input: ArticleAttachmentInputBase): Promise<ArticleAttachmentBase> {
    const output = await this.createArticleAttachmentUseCase.handle({ articleAttachment: input })

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return output.articleAttachment
  }

  async fetchMyJobBookmarks(input: FetchMyJobBookmarksInput): Promise<boolean> {
    const output = await this.fetchMyJobBookmarksUseCase.handle(input)
    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    if (input.shouldRefresh) {
      this._updateMyJobBookmarks(output.jobBookmarks)
    } else {
      this._addMyJobBookmarks(output.jobBookmarks)
    }

    this._updateHasNextMyJobBookmarks(output.hasNextPage)
    return true
  }

  async addMyJobBookmark(jobId: string): Promise<boolean> {
    const output = await this.addMyJobBookmarkUseCase.handle({ jobId })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    this._addMyJobBookmark(output.jobBookmark)
    return true
  }

  async removeMyJobBookmark(jobId: string): Promise<boolean> {
    const output = await this.removeMyJobBookmarkUseCase.handle({ jobId })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    this._removeMyJobBookmark(output.jobBookmark)
    return true
  }

  isInMyJobBookmarks(jobId: string): boolean {
    const found = this.myJobBookmarks.find((bookmark) => bookmark.job.id === jobId)
    if (found) {
      return true
    }

    return false
  }

  async addAngelInvestmentInvitation(input: IAngelInvestmentInvitationInputBase): Promise<string> {
    const output = await this.addAngelInvestmentInvitationUseCase.handle({
      days: input.days || 7,
      emails: input.emails,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)
      return ''
    }

    return output.invitationLink.url
  }

  async addUserInvitation(input: IUserInvitationInputBase): Promise<string> {
    const output = await this.addUserInvitationUseCase.handle({
      days: input.days || 7,
      emails: input.emails,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)
      return ''
    }

    return output.invitationLink.url
  }

  async toggleFollowCompany(slug: string): Promise<ICompanyBase> {
    const output = await this.toggleFollowCompanyUseCase.handle({ slug })

    if (output.data.company) {
      return output.data.company
    }

    return null
  }

  async toggleFollowUser(username: string): Promise<IUserBase> {
    const output = await this.toggleFollowUserUseCase.handle({ username })

    if (output.data.user) {
      return output.data.user
    }

    return null
  }

  async updateCompanyNotificationSettings(companySlug: string, notificationIds: string[]): Promise<ICompanyBase> {
    const output = await this.updateCompanyNotificationSettingsUseCase.handle({ companySlug, notificationIds })

    if (output.data.company) {
      return output.data.company
    }

    return null
  }

  async updateUserNotificationSettings(username: string, notificationIds: string[]): Promise<IUserBase> {
    const output = await this.updateUserNotificationSettingsUseCase.handle({ username, notificationIds })

    if (output.data.user) {
      return output.data.user
    }

    return null
  }

  async fetchNotifications(input: FetchNotificationsInput): Promise<boolean> {
    const output = await this.fetchNotificationsUserUseCase.handle({
      shouldRefresh: input.shouldRefresh,
      cursor: this.allNotificationsCursor,
      limit: input.limit,
    })

    if (output.error) {
      return false
    }

    if (input.shouldRefresh) {
      this._updateAllNotifications(output.data.me.notifications.nodes)
    } else {
      this._addAllNotifications(output.data.me.notifications.nodes)
    }

    this.hasAllNotificationsNextPage = output.data.me.notifications.pageInfo.hasNextPage
    this.allNotificationsCursor = output.data.me.notifications.pageInfo.endCursor

    return true
  }

  async addUserReference(username: string, comment: string): Promise<IUserReferenceBase> {
    const output = await this.addUserReferenceUseCase.handle({ username, comment })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output.userReference
  }

  async updateUserReference(id: string, comment: string): Promise<IUserReferenceBase> {
    const output = await this.updateUserReferenceUseCase.handle({ id, comment })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output.userReference
  }

  async removeUserReference(id: string): Promise<IUserReferenceBase> {
    const output = await this.removeUserReferenceUseCase.handle({ id })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output.userReference
  }

  async addCompanyReference(slug: string, comment: string): Promise<ICompanyReferenceBase> {
    const output = await this.addCompanyReferenceUseCase.handle({ slug, comment })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output.companyReference
  }

  async updateCompanyReference(id: string, comment: string): Promise<ICompanyReferenceBase> {
    const output = await this.updateCompanyReferenceUseCase.handle({ id, comment })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output.companyReference
  }

  async removeCompanyReference(id: string): Promise<ICompanyReferenceBase> {
    const output = await this.removeCompanyReferenceUseCase.handle({ id })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output.companyReference
  }

  async fetchMyReferences(limit: number): Promise<boolean> {
    const output = await this.fetchMyReferencesUseCase.handle({ limit })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    this._updateMyReferences(output.userReferences)
    return true
  }

  async fetchMyOffers(input: FetchMyOffersUseCaseInput): Promise<FetchMyOffersUseCaseOutput> {
    const output = await this.fetchMyOffersUseCase.handle({
      shouldRefresh: input.shouldRefresh,
      limit: input.limit,
    })

    if (input.shouldRefresh) {
      this._updateOffers(output.data.offers)
    } else {
      this._addOffers(output.data.offers)
    }

    this.updateHasNextMyOffersPage(output.data.hasNextPage)

    return output
  }

  async addMyOffer(offer: IOfferInputBase): Promise<CreateMyOfferUseCaseOutput> {
    const output = await this.createMyOfferUseCase.handle({ offer })

    if (output.data.offer) {
      this._addOffer(output.data.offer)
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return output
  }

  async removeMyOffer(id: string): Promise<boolean> {
    const output = await this.deleteMyOfferUseCase.handle({ id })

    if (output.offer) {
      this._deleteOffer(output.offer)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async addUserAttachment(userAttachment: IUserAttachmentInputBase): Promise<AddUserAttachmentUseCaseOutput> {
    const output = await this.addUserAttachmentUseCase.handle({ userAttachment })

    if (output.data.userAttachment) {
      this._addUserAttachment(output.data.userAttachment)
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return output
  }

  async deleteUserAttachment(id: string): Promise<boolean> {
    const output = await this.deleteUserAttachmentUseCase.handle({ id })

    if (output.userAttachment) {
      this._deleteUserAttachment(output.userAttachment)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async addMyQuestion(
    question: IQuestionInputBase,
    choices: IChoiceInputBase[]
  ): Promise<CreateMyQuestionUseCaseOutput> {
    const output = await this.createMyQuestionUseCase.handle({ question, choices })

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return output
  }

  async updateMyQuestion(id: string, question: IQuestionInputBase): Promise<UpdateMyQuestionUseCaseOutput> {
    const output = await this.updateMyQuestionUseCase.handle({ id, question })

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return output
  }

  async removeMyQuestion(id: string): Promise<boolean> {
    const output = await this.deleteMyQuestionUseCase.handle({ id })

    if (output.question) {
      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async addMyAnswer(slug: string, body: string): Promise<CreateMyAnswerUseCaseOutput> {
    const output = await this.createMyAnswerUseCase.handle({ slug, body })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output
  }

  async addMyReply(slug: string, body: string): Promise<CreateMyReplyUseCaseOutput> {
    const output = await this.createMyReplyUseCase.handle({ slug, body })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output
  }

  async updateMyAnswer(id: string, body: string): Promise<UpdateMyAnswerUseCaseOutput> {
    const output = await this.updateMyAnswerUseCase.handle({ id, body })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output
  }

  async removeMyAnswer(id: string): Promise<IAnswerBase> {
    const output = await this.deleteMyAnswerUseCase.handle({ id })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output.answer
  }

  async getSolBalance(userSolanaAddress: PublicKey, connection: Connection): Promise<boolean> {
    try {
      const balance = await connection.getBalance(userSolanaAddress)
      this.solBalance = (balance / LAMPORTS_PER_SOL).toString()

      return true
    } catch {
      return false
    }
  }

  @action
  updateSolBalance(amount: string): void {
    this.solBalance = amount
  }

  // TODO: connection を使わない方法あるかリサーチ
  // TODO: Interactor に移動
  async findAssociatedTokenAddress(userSolanaAddress: PublicKey, connection: Connection): Promise<boolean> {
    const mintPublicKey = process.env.NEXT_PUBLIC_AQA_TOKEN_MINT_PUBLICKEY
    if (!mintPublicKey) {
      return false
    }
    try {
      const tokenPubKey = new PublicKey(mintPublicKey)
      // wallet アドレスのアソシエーショントークンアドレスを取得
      const associatedTokenAddress = await getAssociatedTokenAddress(
        tokenPubKey,
        userSolanaAddress,
        false,
        TOKEN_PROGRAM_ID,
        ASSOCIATED_TOKEN_PROGRAM_ID
      )

      const tokenBalance = await this.getTokenAcccountBalance(associatedTokenAddress, connection)
      this.associatedTokenAddresses[userSolanaAddress.toBase58()] = {
        ata: associatedTokenAddress,
        isExist: true,
      }
      this.tokenBalances[userSolanaAddress.toBase58()] = tokenBalance.value.uiAmountString

      return true
    } catch {
      // ATAがない場合 notExist を入れる
      this.associatedTokenAddresses[userSolanaAddress.toBase58()] = { isExist: false }
      return false
    }
  }

  async getTokenAcccountBalance(
    associatedTokenAddress: PublicKey,
    connection: Connection
  ): Promise<RpcResponseAndContext<TokenAmount>> {
    const tokenBalance = await connection.getTokenAccountBalance(associatedTokenAddress)
    return tokenBalance
  }

  async updateTokenAcccountBalance(
    publicKey: PublicKey,
    associatedTokenAddress: PublicKey,
    connection: Connection
  ): Promise<boolean> {
    const tokenBalance = await this.getTokenAcccountBalance(associatedTokenAddress, connection)
    this.tokenBalances[publicKey.toBase58()] = tokenBalance.value.uiAmountString

    return true
  }

  // 一度のみ findAssociatedTokenAddress し、ATA が存在している場合は Balance のみ取得
  async findATAOrUpdateBalance(userSolanaAddress: PublicKey, connection: Connection): Promise<boolean> {
    if (!this.associatedTokenAddresses[userSolanaAddress.toBase58()]) {
      const output = await this.findAssociatedTokenAddress(userSolanaAddress, connection)
      return output
    }
    if (this.associatedTokenAddresses[userSolanaAddress.toBase58()].isExist) {
      const output = await this.updateTokenAcccountBalance(
        userSolanaAddress,
        this.associatedTokenAddresses[userSolanaAddress.toBase58()].ata,
        connection
      )
      return output
    }
    // ATA が存在しないアカウントは一旦 true を返す
    return true
  }

  async getTokenInfo(connection: Connection): Promise<boolean> {
    const mintPublicKey = process.env.NEXT_PUBLIC_AQA_TOKEN_MINT_PUBLICKEY
    if (!mintPublicKey || this.tokenInfo) {
      return false
    }

    try {
      const mintKey = new PublicKey(mintPublicKey)
      const metaplex = new Metaplex(connection)
      const token = await metaplex.nfts().findByMint({ mintAddress: mintKey })
      const tokenJson = token.json
      // TODO: decimals は一旦ベタ打ち
      this.tokenInfo = { image: tokenJson.image, symbol: tokenJson.symbol, decimals: 9 }

      return true
    } catch {
      return false
    }
  }

  async existATA(connection: Connection, ata: PublicKey): Promise<boolean> {
    // ATA がない場合のフラグ
    let hasATA = true
    try {
      // ATA の存在確認
      await getAccount(connection, ata)
    } catch (e) {
      const error = e as Error
      if (error.name === 'TokenAccountNotFoundError') {
        hasATA = false
      } else {
        throw e
      }
    }
    return hasATA
  }

  // TODO: Interactor に移動
  async transferToken(
    connection: Connection,
    toAddress: string,
    fromPublicKey: PublicKey,
    amount: string
  ): Promise<Transaction> {
    const mintPublicKey = process.env.NEXT_PUBLIC_AQA_TOKEN_MINT_PUBLICKEY
    if (!mintPublicKey || !this.associatedTokenAddresses[fromPublicKey.toBase58()].ata) {
      return null
    }

    const mintKey = new PublicKey(mintPublicKey)
    const toPublicKey = new PublicKey(toAddress)
    const toATAPublicKey = await getAssociatedTokenAddress(
      mintKey,
      toPublicKey, // sender の publicKey
      false,
      TOKEN_PROGRAM_ID,
      ASSOCIATED_TOKEN_PROGRAM_ID
    )
    // ATA がない場合のフラグ
    const hasATA = await this.existATA(connection, toATAPublicKey)

    const transaction = new Transaction()
    const instructions: TransactionInstruction[] = []

    if (!hasATA) {
      instructions.push(
        createAssociatedTokenAccountInstruction(
          fromPublicKey,
          toATAPublicKey,
          toPublicKey,
          mintKey,
          TOKEN_PROGRAM_ID,
          ASSOCIATED_TOKEN_PROGRAM_ID
        )
      )
    }
    instructions.push(
      createTransferInstruction(
        this.associatedTokenAddresses[fromPublicKey.toBase58()].ata,
        toATAPublicKey,
        fromPublicKey,
        Math.floor(Number(amount) * 10 ** this.tokenInfo.decimals) // 丸め誤差が発生する可能性があるので小数点以下切り捨て
      )
    )
    transaction.add(...instructions)

    return transaction
  }

  async addTokenTransaction(
    tokenTransaction: AddTokenTransactionUseCaseInput
  ): Promise<AddTokenTransactionUseCaseOutput> {
    const output = await this.addTokenTransactionUseCase.handle(tokenTransaction)
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output
  }

  async fetchSalesTokenState(connection: Connection, wallet: Wallet): Promise<boolean> {
    const programId = process.env.NEXT_PUBLIC_SALE_TOKEN_PROGRAM_ID
    const salesAccount = process.env.NEXT_PUBLIC_SALES_ACCOUNT_PUBLICKEY
    if (!programId) {
      return null
    }

    const provider = new AnchorProvider(connection, wallet, {})
    const programPublicKey = new PublicKey(programId)
    const program = new Program<SaleToken>(IDL, programPublicKey, provider)
    const fetchedSalesAccount = await program.account.salesAccount.fetch(salesAccount)

    this.exchangeRate = fetchedSalesAccount.rate.toNumber()

    return true
  }

  async swapSolToToken(
    connection: Connection,
    wallet: Wallet,
    publicKey: PublicKey,
    lamports: string
  ): Promise<Transaction> {
    const mint = new PublicKey(process.env.NEXT_PUBLIC_AQA_TOKEN_MINT_PUBLICKEY)
    const programId = process.env.NEXT_PUBLIC_SALE_TOKEN_PROGRAM_ID
    const solVault = new PublicKey(process.env.NEXT_PUBLIC_SOL_VAULT_PUBLICKEY)
    const vaultAuthority = new PublicKey(process.env.NEXT_PUBLIC_VAULT_AUTHORITY_PUBLICKEY)
    const tokenVault = new PublicKey(process.env.NEXT_PUBLIC_TOKEN_VAULT_PUBLICKEY)
    const salesAccount = new PublicKey(process.env.NEXT_PUBLIC_SALES_ACCOUNT_PUBLICKEY)
    const solAccount = new PublicKey(publicKey)

    const ata = await getAssociatedTokenAddress(mint, solAccount, false, TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID)
    const hasATA = await this.existATA(connection, ata)

    const instructions: TransactionInstruction[] = []

    if (!hasATA) {
      instructions.push(
        createAssociatedTokenAccountInstruction(
          solAccount,
          ata,
          solAccount,
          mint,
          TOKEN_PROGRAM_ID,
          ASSOCIATED_TOKEN_PROGRAM_ID
        )
      )
    }

    const provider = new AnchorProvider(connection, wallet, {})
    const programPublicKey = new PublicKey(programId)
    const program = new Program<SaleToken>(IDL, programPublicKey, provider)
    const amount = changeAmountToBasicPoints(lamports)
    const swapInstruction = await program.methods
      .swapSolToToken(new BN(amount))
      .accounts({
        purchaser: solAccount,
        purchaserTokenAccount: ata,
        mint,
        solVault,
        vaultAuthority,
        tokenVault,
        salesState: salesAccount,
        systemProgram: SystemProgram.programId,
        tokenProgram: TOKEN_PROGRAM_ID,
      })
      .instruction()
    instructions.push(swapInstruction)

    const transaction = new Transaction()
    transaction.add(...instructions)

    return transaction
  }

  // async swapTokenToSol(
  //   connection: Connection,
  //   wallet: Wallet,
  //   publicKey: PublicKey,
  //   amount: string
  // ): Promise<Transaction> {
  //   const mint = new PublicKey(process.env.NEXT_PUBLIC_AQA_TOKEN_MINT_PUBLICKEY)
  //   const programId = process.env.NEXT_PUBLIC_SALE_TOKEN_PROGRAM_ID
  //   const solVault = new PublicKey(process.env.NEXT_PUBLIC_SOL_VAULT_PUBLICKEY)
  //   const vaultAuthority = new PublicKey(process.env.NEXT_PUBLIC_VAULT_AUTHORITY_PUBLICKEY)
  //   const tokenVault = new PublicKey(process.env.NEXT_PUBLIC_TOKEN_VAULT_PUBLICKEY)
  //   const salesAccount = new PublicKey(process.env.NEXT_PUBLIC_SALES_ACCOUNT_PUBLICKEY)
  //   const solAccount = new PublicKey(publicKey)

  //   const ata = await getAssociatedTokenAddress(mint, solAccount, false, TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID)
  //   const hasATA = await this.existATA(connection, ata)

  //   const instructions: TransactionInstruction[] = []

  //   if (!hasATA) {
  //     instructions.push(
  //       createAssociatedTokenAccountInstruction(
  //         solAccount,
  //         ata,
  //         solAccount,
  //         mint,
  //         TOKEN_PROGRAM_ID,
  //         ASSOCIATED_TOKEN_PROGRAM_ID
  //       )
  //     )
  //   }

  //   const provider = new AnchorProvider(connection, wallet, {})
  //   const programPublicKey = new PublicKey(programId)
  //   const program = new Program<SaleToken>(IDL, programPublicKey, provider)
  //   const basicPointsAmount = changeAmountToBasicPoints(amount)
  //   const swapInstruction = await program.methods
  //     .swapTokenToSol(new BN(basicPointsAmount))
  //     .accounts({
  //       purchaser: solAccount,
  //       purchaserTokenAccount: ata,
  //       mint,
  //       solVault,
  //       vaultAuthority,
  //       tokenVault,
  //       salesState: salesAccount,
  //       systemProgram: SystemProgram.programId,
  //       tokenProgram: TOKEN_PROGRAM_ID,
  //     })
  //     .instruction()
  //   instructions.push(swapInstruction)

  //   const transaction = new Transaction()
  //   transaction.add(...instructions)

  //   return transaction
  // }
}
