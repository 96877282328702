import { inject, injectable } from 'inversify'
import {
  FetchAngelsUseCaseInput,
  FetchAngelsUseCaseOutput,
  IAppErrorFactory,
  IFetchAngelsUseCase,
  IUsersService,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FetchAngelsInteractor implements IFetchAngelsUseCase {
  @inject(symbols.IUsersService) private usersService: IUsersService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchAngelsUseCaseInput): Promise<FetchAngelsUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const { angels, hasNextPage } = await this.usersService.fetchAngels(input)

      output.angels = angels
      output.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchAngelsUseCaseOutput {
    return {
      angels: [],
      hasNextPage: true,
      error: null,
    }
  }
}
