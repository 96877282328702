import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchMyArticlesUseCaseInput,
  FetchMyArticlesUseCaseOutput,
  IAppErrorFactory,
  IArticlesService,
  IFetchMyArticlesUseCase,
  IUserArticleFactory,
} from '../../types'

@injectable()
export default class FetchMyArticlesInteractor implements IFetchMyArticlesUseCase {
  @inject(symbols.IArticlesService) private articlesService: IArticlesService

  @inject(symbols.IUserArticleFactory) private userArticleFactory: IUserArticleFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchMyArticlesUseCaseInput): Promise<FetchMyArticlesUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const articles = await this.articlesService.fetchMyArticles(input)

      output.articles = articles.map((a) => this.userArticleFactory.create({ base: a }))
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchMyArticlesUseCaseOutput {
    return {
      articles: [],
      error: null,
    }
  }
}
