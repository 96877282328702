import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchJobApplicationsUseCaseInput,
  FetchJobApplicationsUseCaseOnput,
  IAppErrorFactory,
  IChatService,
  IFetchJobApplicationsUseCase,
  IJobApplicationFactory,
  IJobApplicationsService,
} from '../../types'

@injectable()
export default class FetchJobApplicationsInteractor implements IFetchJobApplicationsUseCase {
  @inject(symbols.IJobApplicationsService) private jobApplicationsService: IJobApplicationsService

  @inject(symbols.IChatService) private chatService: IChatService

  @inject(symbols.IJobApplicationFactory) private jobApplicationFactory: IJobApplicationFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchJobApplicationsUseCaseInput): Promise<FetchJobApplicationsUseCaseOnput> {
    const output = this.getDefaultOutputData()
    try {
      // WebSocket を初期化
      this.chatService.initChatWebSocket()

      const { jobApplications, hasNextPage } = await this.jobApplicationsService.fetchJobApplications({
        companySlug: input.companySlug,
        limitOfMessage: input.limitOfMessage,
        limitOfJopApplication: input.limitOfJopApplication,
      })
      output.jobApplications = jobApplications.map((j) => this.jobApplicationFactory.create({ base: j }))
      output.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: '応募の取得に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchJobApplicationsUseCaseOnput {
    return {
      jobApplications: [],
      hasNextPage: true,
      error: null,
    }
  }
}
