import { inject, injectable } from 'inversify'
import {
  FindOrCreateChatMessageThreadUseCaseInput,
  FindOrCreateChatMessageThreadUseCaseOutput,
  IAppErrorFactory,
  IChatMessageThreadFactory,
  IChatService,
  IFindOrCreateChatMessageThreadUseCase,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FindOrCreateChatMessageThreadInteractor implements IFindOrCreateChatMessageThreadUseCase {
  @inject(symbols.IChatService) private chatService: IChatService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IChatMessageThreadFactory) private chatMessageThreadFactory: IChatMessageThreadFactory

  async handle(input: FindOrCreateChatMessageThreadUseCaseInput): Promise<FindOrCreateChatMessageThreadUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const thread = await this.chatService.createChatMessageThread(input)
      output.thread = this.chatMessageThreadFactory.create({
        base: thread,
      })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FindOrCreateChatMessageThreadUseCaseOutput {
    return {
      thread: null,
      error: null,
    }
  }
}
