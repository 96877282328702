import { inject, injectable } from 'inversify'
import {
  DeleteUserAttachmentUseCaseInput,
  DeleteUserAttachmentUseCaseOutput,
  IAppErrorFactory,
  IUsersService,
  IDeleteUserAttachmentUseCase,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class DeleteUserAttachmentInteractor implements IDeleteUserAttachmentUseCase {
  @inject(symbols.IUsersService) private usersService: IUsersService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: DeleteUserAttachmentUseCaseInput): Promise<DeleteUserAttachmentUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.userAttachment = await this.usersService.deleteUserAttachment({
        id: input.id,
      })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): DeleteUserAttachmentUseCaseOutput {
    return {
      userAttachment: null,
      error: null,
    }
  }
}
