import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchJobFootprintsUseCaseInput,
  FetchJobFootprintsUseCaseOutput,
  IAppErrorFactory,
  IFetchJobFootprintsUseCase,
  IJobFootprint,
  IJobFootprintFactory,
  IJobsService,
  IUseCaseOutputFactory,
} from '../../types'

@injectable()
export default class FetchJobFootprintsInteractor implements IFetchJobFootprintsUseCase {
  @inject(symbols.IJobsService) private jobsService: IJobsService

  @inject(symbols.IJobFootprintFactory) private jobFootprintFactory: IJobFootprintFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    jobFootprints: null | IJobFootprint[]
    hasNextPage: null | boolean
  }>

  async handle(input: FetchJobFootprintsUseCaseInput): Promise<FetchJobFootprintsUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        jobFootprints: null,
        hasNextPage: null,
      },
    })
    try {
      const { jobFootprints, hasNextPage } = await this.jobsService.fetchJobFootprints(input)

      const uniqueFootprints: IJobFootprint[] = []
      jobFootprints.forEach((base, index, array) => {
        const userName = base.user.name
        const jobTitle = base.job.title
        const isFound = uniqueFootprints.find((j) => j.user.name === userName && j.job.title === jobTitle)
        if (isFound) {
          // すでに同じ足跡が登録されていれば以降は処理しない
          return
        }

        // まだなければ最初のユニークな足跡なのでインスタンス化
        const rest = array.slice(index + 1)
        const children = rest.filter((j) => j.user.name === userName && j.job.title === jobTitle)
        const footprint = this.jobFootprintFactory.create({ base, children })
        uniqueFootprints.push(footprint)
      })

      output.data.jobFootprints = uniqueFootprints
      output.data.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
