import { inject, injectable } from 'inversify'
import {
  FetchUserUseCaseInput,
  FetchUserUseCaseOutput,
  IAppErrorFactory,
  IFetchUserUseCase,
  IUsersService,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FetchUserInteractor implements IFetchUserUseCase {
  @inject(symbols.IUsersService) private usersService: IUsersService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchUserUseCaseInput): Promise<FetchUserUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const user = await this.usersService.fetchUser(input)
      output.user = user
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchUserUseCaseOutput {
    return {
      user: null,
      error: null,
    }
  }
}
