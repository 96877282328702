import { action, computed, observable } from 'mobx'
import remotedev, { RemoteDevConfig } from 'mobx-remotedev'
import { inject, injectable } from 'inversify'
import {
  IAppCredentials,
  IApplyForJobUseCase,
  IChangeLanguageUseCase,
  ICompanyInputBase,
  ICreateMyCompanyUseCase,
  IErrorsStore,
  IInitializeUseCase,
  IInvitationBase,
  IMyCompany,
  IPreferences,
  ISendResetPasswordRequestUseCase,
  ISignInUseCase,
  ISignOutUseCase,
  ISignUpUseCase,
  IUpdateEmailUseCase,
  IUpdateMeUseCase,
  IUpdateMyProfileUseCase,
  IUpdatePasswordUseCase,
  IUpdatePasswordWithTokenUseCase,
  IUserProfileInputBase,
  IValidInvitationTokenUseCase,
  IViewer,
  IViewerStore,
  Language,
  OnInitializedHandler,
} from '@/types'
import symbols from '@/symbols'

const remoteDevConfig: RemoteDevConfig = {
  name: 'ViewerStore',
  global: true,
  remote: false,
}

@remotedev(remoteDevConfig)
@injectable()
export default class ViewerStore implements IViewerStore {
  @observable isInitialized = false

  constructor(
    @inject(symbols.IViewer) public viewer: IViewer,
    @inject(symbols.IAppCredentials)
    private appCredentials: IAppCredentials,
    @inject(symbols.IPreferences) private preferences: IPreferences,
    @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore,
    @inject(symbols.ISignInUseCase) private signInUseCase: ISignInUseCase,
    @inject(symbols.ISignUpUseCase) private signUpUseCase: ISignUpUseCase,
    @inject(symbols.ISignOutUseCase) private signOutUseCase: ISignOutUseCase,
    @inject(symbols.IUpdatePasswordUseCase) private updatePasswordUseCase: IUpdatePasswordUseCase,
    @inject(symbols.IUpdatePasswordWithTokenUseCase)
    private updatePasswordWithTokenUseCase: IUpdatePasswordWithTokenUseCase,
    @inject(symbols.ISendResetPasswordRequestUseCase)
    private sendResetPasswordRequestUseCase: ISendResetPasswordRequestUseCase,
    @inject(symbols.IUpdateEmailUseCase) private updateEmailUseCase: IUpdateEmailUseCase,
    @inject(symbols.IUpdateMeUseCase) private updateMeUseCase: IUpdateMeUseCase,
    @inject(symbols.IUpdateMyProfileUseCase) private updateMyProfileUseCase: IUpdateMyProfileUseCase,
    @inject(symbols.IChangeLanguageUseCase) private changeLanguageUseCase: IChangeLanguageUseCase,
    @inject(symbols.IInitializeUseCase) private initializeUseCase: IInitializeUseCase,
    @inject(symbols.ICreateMyCompanyUseCase) private createMyCompanyUseCase: ICreateMyCompanyUseCase,
    @inject(symbols.IApplyForJobUseCase) private applyForJobUseCase: IApplyForJobUseCase,
    @inject(symbols.IValidInvitationTokenUseCase) private validInvitationTokenUseCase: IValidInvitationTokenUseCase
  ) {
    //
  }

  @computed get isSignedIn(): boolean {
    return this.appCredentials.isSignedIn
  }

  @computed get language(): Language {
    return this.preferences.language
  }

  @action
  _updateIsInitialized(isInitialized: boolean): void {
    this.isInitialized = isInitialized
  }

  async updateMyProfile(profile: IUserProfileInputBase): Promise<boolean> {
    const output = await this.updateMyProfileUseCase.handle({
      profile,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    if (output.profile) {
      this.viewer.updateProfile(output.profile)
    }

    return true
  }

  async updateNames(name: string, username: string): Promise<boolean> {
    const output = await this.updateMeUseCase.handle({
      username,
      name,
      email: this.viewer.email,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    if (output.name) {
      this.viewer.updateName(output.name)
    }

    if (output.username) {
      this.viewer.updateUsername(output.username)
    }

    return true
  }

  async updateEmail(newEmail: string): Promise<boolean> {
    const output = await this.updateEmailUseCase.handle({
      email: newEmail,
      username: this.viewer.username,
      name: this.viewer.name,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    return true
  }

  async updatePassword(
    newPassword: string,
    newPasswordConfirmation: string,
    currentPassword: string
  ): Promise<boolean> {
    const output = await this.updatePasswordUseCase.handle({
      currentPassword,
      password: newPassword,
      passwordConfirmation: newPasswordConfirmation,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    return true
  }

  async updatePasswordWithToken(
    resetPasswordToken: string,
    password: string,
    passwordConfirmation: string
  ): Promise<boolean> {
    const output = await this.updatePasswordWithTokenUseCase.handle({
      resetPasswordToken,
      password,
      passwordConfirmation,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    return true
  }

  async sendResetPasswordRequest(email: string): Promise<boolean> {
    const output = await this.sendResetPasswordRequestUseCase.handle({ email })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    return true
  }

  async signIn(email: string, password: string): Promise<boolean> {
    const output = await this.signInUseCase.handle({
      email,
      password,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    return true
  }

  async signUp(
    name: string,
    username: string,
    email: string,
    password: string,
    passwordConfirmation: string,
    token?: string
  ): Promise<boolean> {
    const output = await this.signUpUseCase.handle({ name, username, email, password, passwordConfirmation, token })
    if (!output.isSuccessful) {
      this.errorsStore.handle(output.error)

      return false
    }

    return true
  }

  async signOut(): Promise<boolean> {
    const output = await this.signOutUseCase.handle()
    if (!output.isSuccessful) {
      return false
    }

    return true
  }

  async initialize(language: Language, onInitialized?: OnInitializedHandler): Promise<void> {
    // クレデンシャルのリストアと viewer の更新
    const output = await this.initializeUseCase.handle({
      language,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    // this.initialized を true にする前にリダイレクトとかをするためのコールバックを実行
    if (onInitialized) {
      await onInitialized({
        isSignedIn: this.isSignedIn,
      })
    }

    this._updateIsInitialized(true)
  }

  async changeLanguage(language: Language): Promise<boolean> {
    const output = await this.changeLanguageUseCase.handle({
      language,
    })

    if (output.isSuccessful) {
      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async createMyCompany(company: ICompanyInputBase): Promise<IMyCompany> {
    const output = await this.createMyCompanyUseCase.handle({
      company,
    })

    if (output.company) {
      this.viewer.addMyCompany(output.company.companyMembers[0]) // TODO: 取れるか確認して調整
      return output.company
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return null
  }

  async applyForJob(jobId: string, message: string): Promise<boolean> {
    const output = await this.applyForJobUseCase.handle({ jobId, message })

    if (output.jobApplication) {
      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async validInvitationToken(token: string): Promise<IInvitationBase> {
    const output = await this.validInvitationTokenUseCase.handle({ token })

    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output.invitation
  }
}
