import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  IInvestmentsService,
  IRemoveInvestorUseCase,
  RemoveInvestorUseCaseInput,
  RemoveInvestorUseCaseOutput,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class RemoveInvestorInteractor implements IRemoveInvestorUseCase {
  @inject(symbols.IInvestmentsService) private investmentsService: IInvestmentsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: RemoveInvestorUseCaseInput): Promise<RemoveInvestorUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.investment = await this.investmentsService.removeInvestor(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: '投資先情報の削除に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): RemoveInvestorUseCaseOutput {
    return {
      investment: null,
      error: null,
    }
  }
}
