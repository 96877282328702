import { inject, injectable } from 'inversify'
import {
  AddCompanyInvestmentUseCaseInput,
  AddCompanyInvestmentUseCaseOutput,
  IAddCompanyInvestmentUseCase,
  IAppErrorFactory,
  IInvestmentFactory,
  IInvestmentsService,
} from '../../types'
import symbols from '../../symbols'
import { getValidUrl } from '../../utils'

@injectable()
export default class AddCompanyInvestmentInteractor implements IAddCompanyInvestmentUseCase {
  @inject(symbols.IInvestmentsService) private investmentsService: IInvestmentsService

  @inject(symbols.IInvestmentFactory) private investmentFactory: IInvestmentFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: AddCompanyInvestmentUseCaseInput): Promise<AddCompanyInvestmentUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      // URL のプロトコル部分を調整
      input.investment.referenceUrl = getValidUrl(input.investment.referenceUrl)

      const base = await this.investmentsService.addCompanyInvestment(input)
      output.investment = this.investmentFactory.create({ base })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: '投資先の登録に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): AddCompanyInvestmentUseCaseOutput {
    return {
      investment: null,
      error: null,
    }
  }
}
