import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  IAppErrorFactory,
  IQuestionsService,
  IQuestionBase,
  IUseCaseOutputFactory,
  IUpdateMyQuestionUseCase,
  UpdateMyQuestionUseCaseInput,
  UpdateMyQuestionUseCaseOutput,
} from '../../types'

@injectable()
export default class UpdateMyQuestionInteractor implements IUpdateMyQuestionUseCase {
  @inject(symbols.IQuestionsService) private questionsService: IQuestionsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    question: null | IQuestionBase
  }>

  async handle(input: UpdateMyQuestionUseCaseInput): Promise<UpdateMyQuestionUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        question: null,
      },
    })
    try {
      output.data.question = await this.questionsService.updateMyQuestion(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
