import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchQuestionUseCaseInput,
  FetchQuestionUseCaseOutput,
  IAppErrorFactory,
  IFetchQuestionUseCase,
  IQuestionsService,
} from '../../types'

@injectable()
export default class FetchQuestionInteractor implements IFetchQuestionUseCase {
  @inject(symbols.IQuestionsService) private questionsService: IQuestionsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchQuestionUseCaseInput): Promise<FetchQuestionUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const question = await this.questionsService.fetchQuestion(input)

      output.question = question
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchQuestionUseCaseOutput {
    return {
      question: null,
      error: null,
    }
  }
}
