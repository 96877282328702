import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  IAppErrorFactory,
  IAnswersService,
  IAnswerBase,
  IUseCaseOutputFactory,
  IUpdateMyAnswerUseCase,
  UpdateMyAnswerUseCaseInput,
  UpdateMyAnswerUseCaseOutput,
} from '../../types'

@injectable()
export default class UpdateMyAnswerInteractor implements IUpdateMyAnswerUseCase {
  @inject(symbols.IAnswersService) private answersService: IAnswersService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    answer: null | IAnswerBase
  }>

  async handle(input: UpdateMyAnswerUseCaseInput): Promise<UpdateMyAnswerUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        answer: null,
      },
    })
    try {
      output.data.answer = await this.answersService.updateMyAnswer(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
