import { action, computed, observable } from 'mobx'
import { inject, injectable } from 'inversify'
import moment from 'moment'
import { IPreferences, IPreferencesBase, IStorageService, Language } from '@/types'
import symbols from '@/symbols'

@injectable()
export default class Preferences implements IPreferences {
  @inject(symbols.IStorageService) private storageService: IStorageService

  @observable language: Language = Language.EN

  static readonly STORAGE_KEY = 'preferences'

  restore(): boolean {
    const preferencesString = this._restoreCurrentCredentials()
    if (!preferencesString) {
      // storage になかった場合にアーリーリターン
      return false
    }

    // メモリにも保持
    const preferences = JSON.parse(preferencesString) as IPreferencesBase
    this.update(preferences)

    return true
  }

  changeLanguage(language: Language): void {
    this._changeLanguage(language)
    moment.locale(language)
    this._saveCurrentPreferences()
  }

  update(base: IPreferencesBase): void {
    this.changeLanguage(base.language)
  }

  @action
  _changeLanguage(language: Language): void {
    this.language = language
  }

  _restoreCurrentCredentials(): string {
    return this.storageService.getItem(Preferences.STORAGE_KEY)
  }

  _saveCurrentPreferences(): void {
    // storageService が利用可能なら storage にも保存
    // ISR でも実行されうるので利用可否のチェックが必要
    if (this.storageService.isAbleToUse) {
      this.storageService.setItem(Preferences.STORAGE_KEY, JSON.stringify(this.base))
    }
  }

  @computed
  get base(): IPreferencesBase {
    return {
      language: this.language,
    }
  }
}
