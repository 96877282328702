import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  ICompaniesService,
  IUpdateProductUseCase,
  UpdateProductUseCaseInput,
  UpdateProductUseCaseOutput,
} from '../../types'
import symbols from '../../symbols'
import { getValidUrl } from '../../utils'

@injectable()
export default class UpdateProductInteractor implements IUpdateProductUseCase {
  @inject(symbols.ICompaniesService) private companiesService: ICompaniesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: UpdateProductUseCaseInput): Promise<UpdateProductUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      // URL のプロトコル部分を調整
      input.product.url = getValidUrl(input.product.url)

      output.product = await this.companiesService.updateProduct(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'プロダクト情報の更新に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): UpdateProductUseCaseOutput {
    return {
      product: null,
      error: null,
    }
  }
}
