import { inject, injectable } from 'inversify'
import axios from 'axios'
import fetch from 'cross-fetch'
import { GraphQLClient } from 'graphql-request'
import {
  ActionCable,
  ActionCableConsumer,
  ActionCableSubscription,
  AddAngelInvestmentInvitationRequestInput,
  AddAngelInvitationRequestInput,
  AddCompanyInvestmentRequestInput,
  AddCompanyMemberRequestInput,
  AddCompanyReferenceRequestInput,
  AddExperienceRequestInput,
  AddFootprintToJobInputBase,
  AddInvestorRequestInput,
  AddMemberInvitationRequestInput,
  AddMyInvestmentRequestInput,
  AddMyJobBookmarkRequestInput,
  AddProductRequestInput,
  AddTokenRequestInput,
  AddTokenTransactionRequestInput,
  AddUserAttachmentRequestInput,
  AddUserInvitationRequestInput,
  AddUserReferenceRequestInput,
  AddVcInvestmentInvitationRequestInput,
  AddVcInvitationRequestInput,
  ApplyForJobRequestInput,
  ChangeSoldOutRequestInput,
  CreateArticleAttachmentRequestInput,
  CreateChatMessageThreadRequestInput,
  CreateChatMessageThreadsSubscriptionRequestInput,
  CreateCompanyArticleRequestInput,
  CreateJobAttachmentRequestInput,
  CreateJobRequestInput,
  CreateMyAnswerRequestInput,
  CreateMyArticleRequestInput,
  CreateMyOfferRequestInput,
  CreateMyQuestionRequestInput,
  CreateMyReplyRequestInput,
  CreateSkillRequestInput,
  DeleteArticleRequestInput,
  DeleteJobRequestInput,
  DeleteMyAnswerRequestInput,
  DeleteMyOfferRequestInput,
  DeleteMyQuestionRequestInput,
  DeleteUserAttachmentRequestInput,
  DuplicateJobRequestInput,
  FetchAngelsRequestInput,
  FetchAngelsRequestOutput,
  FetchArticleRequestInput,
  FetchArticleRequestOutput,
  FetchBlockchainsRequestInput,
  FetchChatMessagesRequestInput,
  FetchChatMessagesRequestOutput,
  FetchChatMessageThreadsRequestInput,
  FetchCompaniesRequestInput,
  FetchCompaniesRequestOutput,
  FetchCompaniesWithJobsRequestInput,
  FetchCompaniesWithJobsRequestOutput,
  FetchCompanyArticleRequestInput,
  FetchCompanyArticleRequestOutput,
  FetchCompanyJobsRequestInput,
  FetchCompanyJobsRequestOutput,
  FetchCompanyRequestInput,
  FetchFollowersRequestInput,
  FetchFollowersRequestOutput,
  FetchFollowingRequestInput,
  FetchFollowingRequestOutput,
  FetchJobApplicationsRequestInput,
  FetchJobApplicationsRequestOutput,
  FetchJobBookmarksRequestInput,
  FetchJobBookmarksRequestOutput,
  FetchJobFootprintsRequestInput,
  FetchJobFootprintsRequestOutput,
  FetchJobRequestInput,
  FetchJobSeekersRequestInput,
  FetchJobSeekersRequestOutput,
  FetchJobsRequestInput,
  FetchJobsRequestOutput,
  FetchLocationsRequestInput,
  FetchMyArticlesRequestInput,
  FetchMyCompanyArticlesRequestInput,
  FetchMyCompanyArticlesRequestOutput,
  FetchMyCompanyReferencesRequestInput,
  FetchMyCompanyReferencesRequestOutput,
  FetchMyCompanyRequestInput,
  FetchMyJobBookmarksRequestInput,
  FetchMyJobBookmarksRequestOutput,
  FetchMyJobsRequestInput,
  FetchMyJobsRequestOutput,
  FetchMyOffersRequestInput,
  FetchMyOffersRequestOutput,
  FetchMyReferencesRequestInput,
  FetchMyReferencesRequestOutput,
  FetchNftRequestInput,
  FetchNftsRequestInput,
  FetchNftsRequestOutput,
  FetchNotificationsRequestInput,
  FetchOfferRequestInput,
  FetchOffersRequestInput,
  FetchOffersRequestOutput,
  FetchQuestionRequestInput,
  FetchQuestionsRequestInput,
  FetchQuestionsRequestOutput,
  FetchReplyRequestInput,
  FetchSkillsRequestInput,
  FetchTokensRequestInput,
  FetchUserArticlesRequestInput,
  FetchUserArticlesRequestOutput,
  FetchUserOffersRequestInput,
  FetchUserOffersRequestOutput,
  FetchUserQuestionsRequestInput,
  FetchUserQuestionsRequestOutput,
  FetchUserRequestInput,
  FollowableEntityEntityType,
  GraphQLErrors,
  IAnswerBase,
  IAppCredentials,
  IArticleAttachmentBase,
  IArticleBase,
  IArticlesService,
  IBlockchainBase,
  IChatMessageThreadBase,
  IChatService,
  IChoiceBase,
  ICompaniesService,
  ICompanyBase,
  ICompanyInputBase,
  ICompanyReferenceBase,
  ICompanyReferencesService,
  IExperienceBase,
  IInvestmentBase,
  IInvestmentsService,
  IInvitationBase,
  IInvitationLinkBase,
  IInvitationsService,
  IJobApplicationBase,
  IJobApplicationsService,
  IJobAttachmentBase,
  IJobBase,
  IJobBookmarkBase,
  IJobBookmarksService,
  IJobCategoriesService,
  IJobsService,
  IJobTagBase,
  IJobTagsService,
  IListingBase,
  ILocationBase,
  ILocationsService,
  IMarketBase,
  IMarketsService,
  IMyCompanyBase,
  IMyCompanyMemberBase,
  INftBase,
  INftsCountBase,
  INftsService,
  INotificationsService,
  IOfferBase,
  IOffersService,
  IPreferences,
  IProductBase,
  IQuestionBase,
  IQuestionCategoriesService,
  IQuestionsService,
  ISkillBase,
  ITokenBase,
  ITokenTransactionBase,
  IUserAttachmentBase,
  IUserBase,
  IUserProfileBase,
  IUserProfileInputBase,
  IUserReferenceBase,
  IUserReferencesService,
  IUsersService,
  IViewerBase,
  IViewerService,
  JobCategoryBase,
  MarkAllMessagesAsReadRequestInput,
  MarkNotificationsAsReadRequestInput,
  MarkNotificationsAsReadRequestOutput,
  QuestionCategoryBase,
  RemoveCompanyMemberRequestInput,
  RemoveCompanyReferenceRequestInput,
  RemoveExperienceRequestInput,
  RemoveInvestmentRequestInput,
  RemoveInvestorRequestInput,
  RemoveMyJobBookmarkRequestInput,
  RemoveProductRequestInput,
  RemoveUserReferenceRequestInput,
  SearchCompanyRequestInput,
  SearchUserRequestInput,
  SendChatMessageRequestInput,
  ToggleArticleLikedStateRequestInput,
  ToggleFollowCompanyRequestInput,
  ToggleFollowUserRequestInput,
  UpdateCompanyArticleRequestInput,
  UpdateCompanyMemberRequestInput,
  UpdateCompanyNotificationSettingsRequestInput,
  UpdateCompanyReferenceRequestInput,
  UpdateCompanyRequestInput,
  UpdateExperienceRequestInput,
  UpdateInvestmentRequestInput,
  UpdateInvestorRequestInput,
  UpdateJobRequestInput,
  UpdateMeRequestInput,
  UpdateMyAnswerRequestInput,
  UpdateMyArticleRequestInput,
  UpdateMyCompanyReferenceRequestInput,
  UpdateMyOfferRequestInput,
  UpdateMyQuestionRequestInput,
  UpdateMyReferenceRequestInput,
  UpdateProductRequestInput,
  UpdateUserAttachmentRequestInput,
  UpdateUserNotificationSettingsRequestInput,
  UpdateUserReferenceRequestInput,
  ValidInvitationTokenRequestInput,
  VoteForChoiceRequestInput,
} from '@/types'
import { isBrowser } from '@/utils'
import ForbiddenError from '@/errors/ForbiddenError'
import UnauthenticatedError from '@/errors/UnauthenticatedError'
import { getSdk } from '@/lib/generated/sdk'
import symbols from '@/symbols'

@injectable()
export default class AppAPIGateway
  implements
    IViewerService,
    IUsersService,
    ICompaniesService,
    IArticlesService,
    IInvestmentsService,
    IChatService,
    IMarketsService,
    INotificationsService,
    ILocationsService,
    IJobCategoriesService,
    IJobTagsService,
    IJobsService,
    IJobApplicationsService,
    IJobBookmarksService,
    IInvitationsService,
    IJobBookmarksService,
    IUserReferencesService,
    ICompanyReferencesService,
    IOffersService,
    IQuestionCategoriesService,
    IQuestionsService,
    INftsService {
  @inject(symbols.IAppCredentials) private credentials: IAppCredentials

  @inject(symbols.IPreferences) private preferences: IPreferences

  private _actionCable: ActionCable = null

  private _actionCableConsumer: ActionCableConsumer = null

  private _actionCableSubscriptions: Record<string, ActionCableSubscription> = {}

  private sdk: ReturnType<typeof getSdk> = null

  /**
   * ChatMessages の Relay Connection のページ送り用
   */
  private _fetchStartupsCursors = ''

  private _fetchVentureCapitalsCursors = ''

  private _fetchAngelsCursors = ''

  private _fetchCompaniesWithJobsCursors = ''

  private _fetchInvestmentsWithJobsOfVcCursors: Record<string, string> = {}

  private _fetchInvestmentsWithJobsOfAngelCursors: Record<string, string> = {}

  private _chatMessagesCursors: Record<string, string> = {}

  private _fetchArticlesCursors: Record<string, string> = {}

  private _fetchJobsCursors = ''

  private _fetchJobFootprintsCursors: Record<string, string> = {}

  private _fetchMyJobsCursors: Record<string, string> = {}

  private _fetchMyJobBookmarksCursors = ''

  private _fetchCompanyJobsCursors: Record<string, string> = {}

  private _fetchCompanyArticlesCursors: Record<string, string> = {}

  private _fetchMyCompanyArticlesCursors: Record<string, string> = {}

  private _fetchFollowingCursors: Record<string, string> = {}

  // user と company で同じ slug が考えられるのでそれぞれ用意
  private _fetchCompanyFollowersCursors: Record<string, string> = {}

  private _fetchUserFollowersCursors: Record<string, string> = {}

  private _fetchUserArticlesCursors: Record<string, string> = {}

  private _fetchJobSeekersCursors = ''

  private _fetchOffersCursors = ''

  private _fetchUserOffersCursors: Record<string, string> = {}

  private _fetchQuestionsCursors = ''

  private _fetchNftsCursors = ''

  private _fetchUserQuestionsCursors: Record<string, string> = {}

  constructor() {
    const url = `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/graphql`
    const client = new GraphQLClient(url, {
      // fetch をラップして access-token, uid をインターセプトする処理
      fetch: async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
        const response = await fetch(input, init)

        // 送ったトークンの有効性を検証
        if (response.status !== 500) {
          // 500 エラーのケースでは uid は返って来ないのでログアウトさせられてしまうため 500 以外のケースを処理
          const oldAccessToken = init.headers['access-token'] as string
          const uid = response.headers.get('uid')
          this._handleUnauthenticated(oldAccessToken, uid)
        }

        // 送ったトークンが valid なら新しいものを保存
        const newAccessToken = response.headers.get('access-token')
        this._setNewAccessToken(newAccessToken)

        return response
      },
    })

    this.sdk = getSdk(client)
  }

  /**
   * WebSocket の初期化
   */
  initChatWebSocket(): void {
    // ActionCable がブラウザ環境以外で利用を想定しておらず
    // SSR のときに死ぬので、ちょっとごちゃるけどサーバーサイドでは処理しない
    if (!isBrowser()) {
      return
    }

    // 既に両方初期化されてる場合は処理しない
    if (this._actionCable && this._actionCableConsumer) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    this._actionCable = require('@rails/actioncable') as ActionCable
    this._actionCableConsumer = this._actionCable.createConsumer(
      `${process.env.NEXT_PUBLIC_BACKEND_WS_BASE_URL}/cable/?access-token=${this.credentials.accessToken}&client=${this.credentials.client}&uid=${this.credentials.uid}`
    )
  }

  /**
   * Subscription を利用してメッセージを送信
   * @param input
   */
  sendChatMessage(input: SendChatMessageRequestInput): void {
    this._actionCableSubscriptions[input.chatMessageTreadSlug].perform('send_message', {
      message_body: input.messageBody,
      message_thread_slug: input.chatMessageTreadSlug,
      message_files: input.messageFiles,
      deduplication_id: input.deduplicationId,
    })
  }

  private async _post<T>(query: string, variables: Record<string, unknown>): Promise<T & GraphQLErrors> {
    const url = `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/graphql`
    const result = await axios.post<T & GraphQLErrors>(
      url,
      {
        query,
        variables,
      },
      {
        headers: this._headers(),
      }
    )

    if (result.data.errors) {
      // エラーがある場合
      if (result.data.errors[0]?.message === 'Forbidden') {
        throw new ForbiddenError('Server responded with "Forbidden".')
      }
    }

    // TODO: eslint-disabled-next-line を解消する
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const newAccessToken = typeof result.headers['access-token'] === 'string' ? result.headers['access-token'] : ''
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    const uid = typeof result.headers.uid === 'string' ? result.headers.uid : ''
    this._handleUnauthenticated(this.credentials.accessToken, uid)
    this._setNewAccessToken(newAccessToken)

    return result.data
  }

  private _setNewAccessToken(newAccessToken: string): void {
    // 同時に複数 post した場合、新しい access-token はひとつのレスポンスにしか返ってこないため
    // access-token が空でないときのみ値を更新する
    if (isBrowser() && newAccessToken) {
      this.credentials.updateAccessToken(newAccessToken)
    }
  }

  // ログイン済で accessToken をつけてるのに uid が返ってこないのは
  // 保持してる accessToken が期限切れのためなので認証エラーにする
  private _handleUnauthenticated(oldAccessToken: string, uid: string | null): void {
    if (!oldAccessToken) {
      // oldAccessToken がないのはログインしてないケース
      return
    }

    if (uid) {
      // uid が返ってくるのは API に渡した oldAccessToken が有効なケース
      return
    }

    throw new UnauthenticatedError('Uid is empty on Response Header.')
  }

  // API へリクエストする際に使用する headers を返すメソッド
  private _headers(): HeadersInit {
    const credentials = this.credentials.getLatestCredentials()
    return {
      'X-PREFERRED-LANGUAGE': this.preferences.language,
      'access-token': credentials.accessToken || '',
      uid: credentials.uid || '',
      client: credentials.client || '',
    }
  }

  // ============================================================
  // Viewer
  // ============================================================
  async fetchMe(): Promise<IViewerBase> {
    const variables = {
      articlesAfter: '',
      articlesBefore: '',
      articlesFirst: 20,
      notificationsAfter: '',
      notificationsBefore: '',
      notificationsFirst: 5,
    }

    const response = await this.sdk.fetchMe(variables, this._headers())

    return response.me
  }

  async updateMe(input: UpdateMeRequestInput): Promise<IViewerBase> {
    const response = await this.sdk.updateMe(
      {
        input: {
          attributes: input,
        },
      },
      this._headers()
    )

    return response.updateMe
  }

  // ============================================================
  // Users
  // ============================================================
  async searchUser(input: SearchUserRequestInput): Promise<IUserBase[]> {
    const response = await this.sdk.searchUser(
      {
        query: input.searchWord,
      },
      this._headers()
    )

    return response.searchUser.nodes
  }

  async fetchAngels(input: FetchAngelsRequestInput): Promise<FetchAngelsRequestOutput> {
    // サーバー側から実行するときはページ送りしないので、 after は指定しない
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else {
      after = this._fetchAngelsCursors
    }

    const response = await this.sdk.fetchAngels(
      {
        after,
        before: '',
        first: input.limit,
        maxInvestmentAmount: input.maxInvestmentAmount ? input.maxInvestmentAmount : null,
        minInvestmentAmount: input.minInvestmentAmount ? input.minInvestmentAmount : null,
        investmentTargetMarketIds: input.investmentTargetMarketIds ? input.investmentTargetMarketIds : [],
        investmentTargetRoundIds: input.investmentTargetRoundIds ? input.investmentTargetRoundIds : [],
        locationIds: input.locationIds ? input.locationIds : [],
        currencyUnit: input.currencyUnit ? input.currencyUnit : null,
        orderBy: input.orderBy ? input.orderBy : null,
        query: input.searchWord ? input.searchWord : '',
      },
      this._headers()
    )

    this._fetchAngelsCursors = response.angels.pageInfo.endCursor

    return {
      angels: response.angels.nodes,
      hasNextPage: response.angels.pageInfo.hasNextPage,
    }
  }

  async fetchFeaturedAngels(): Promise<IUserBase[]> {
    const response = await this.sdk.fetchFeaturedAngels({}, this._headers())

    return response.featuredAngels
  }

  async fetchUser(input: FetchUserRequestInput): Promise<IUserBase> {
    const response = await this.sdk.fetchUser(
      {
        username: input.username,
        firstReferences: input.limitOfReference ? input.limitOfReference : null,
        firstQuestions: input.limitOfQuestion ? input.limitOfQuestion : null,
      },
      this._headers()
    )

    return response.user
  }

  async fetchUserForViewer(username: string): Promise<IUserBase> {
    const response = await this.sdk.fetchUserForViewer(
      {
        username,
      },
      this._headers()
    )

    return response.user
  }

  async fetchJobSeekers(input: FetchJobSeekersRequestInput): Promise<FetchJobSeekersRequestOutput> {
    // サーバー側から実行するときはページ送りしないので、 after は指定しない
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else {
      after = this._fetchJobSeekersCursors
    }

    const response = await this.sdk.fetchJobSeekers(
      {
        after,
        before: '',
        first: input.limit,
        jobHuntingStatus: input.jobHuntingStatus ? input.jobHuntingStatus : [],
        primaryJobCategoryIds: input.primaryJobCategoryIds ? input.primaryJobCategoryIds : [],
        yearsOfExperience: input.yearsOfExperience ? input.yearsOfExperience : [],
        query: input.searchWord ? input.searchWord : '',
      },
      this._headers()
    )

    this._fetchJobSeekersCursors = response.jobSeekers.pageInfo.endCursor

    return {
      jobSeekers: response.jobSeekers.nodes,
      hasNextPage: response.jobSeekers.pageInfo.hasNextPage,
    }
  }

  async updateMyProfile(profile: IUserProfileInputBase): Promise<IUserProfileBase> {
    const response = await this.sdk.updateMyProfile(
      {
        input: {
          attributes: profile,
        },
      },
      this._headers()
    )

    return response.updateMyProfile
  }

  async addExperience(input: AddExperienceRequestInput): Promise<IExperienceBase> {
    const response = await this.sdk.addExperience(
      {
        input: {
          attributes: input.experience,
        },
      },
      this._headers()
    )

    return response.addExperience
  }

  async updateExperience(input: UpdateExperienceRequestInput): Promise<IExperienceBase> {
    const response = await this.sdk.updateExperience(
      {
        input: {
          attributes: input.experience,
          id: input.id,
        },
      },
      this._headers()
    )

    return response.updateExperience
  }

  async removeExperience(input: RemoveExperienceRequestInput): Promise<IExperienceBase> {
    const response = await this.sdk.removeExperience(
      {
        input,
      },
      this._headers()
    )

    return response.removeExperience
  }

  async addUserAttachment(input: AddUserAttachmentRequestInput): Promise<IUserAttachmentBase> {
    const response = await this.sdk.addUserAttachment(
      {
        input: {
          attributes: input.userAttachment,
        },
      },
      this._headers()
    )

    return response.addUserAttachment
  }

  async updateUserAttachment(input: UpdateUserAttachmentRequestInput): Promise<IUserAttachmentBase> {
    const response = await this.sdk.updateUserAttachment(
      {
        input: {
          attributes: input.userAttachment,
          id: input.id,
        },
      },
      this._headers()
    )

    return response.updateUserAttachment
  }

  async deleteUserAttachment(input: DeleteUserAttachmentRequestInput): Promise<IUserAttachmentBase> {
    const response = await this.sdk.deleteUserAttachment(
      {
        input,
      },
      this._headers()
    )

    return response.deleteUserAttachment
  }

  // ============================================================
  // Companies
  // ============================================================
  async fetchCompanies(input: FetchCompaniesRequestInput): Promise<FetchCompaniesRequestOutput> {
    // サーバー側から実行するときはページ送りしないので、 after は指定しない
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else if (input.isVc) {
      after = this._fetchVentureCapitalsCursors
    } else {
      after = this._fetchStartupsCursors
    }

    const variables = {
      after,
      before: '',
      first: input.limit,
      isVc: input.isVc,
      marketIds: input.marketIds ? input.marketIds : [],
      maxInvestmentAmount: input.maxInvestmentAmount ? input.maxInvestmentAmount : null,
      minInvestmentAmount: input.minInvestmentAmount ? input.minInvestmentAmount : null,
      companySize: input.companySize ? input.companySize : null,
      roundIds: input.roundIds ? input.roundIds : [],
      investmentTargetMarketIds: input.investmentTargetMarketIds ? input.investmentTargetMarketIds : [],
      investmentTargetRoundIds: input.investmentTargetRoundIds ? input.investmentTargetRoundIds : [],
      locationIds: input.locationIds ? input.locationIds : [],
      currencyUnit: input.currencyUnit ? input.currencyUnit : null,
      orderBy: input.orderBy ? input.orderBy : null,
      query: input.searchWord ? input.searchWord : '',
    }
    const response = await this.sdk.fetchCompanies(variables, this._headers())

    if (input.isVc) {
      this._fetchVentureCapitalsCursors = response.companies.pageInfo.endCursor
    } else {
      this._fetchStartupsCursors = response.companies.pageInfo.endCursor
    }

    return {
      companies: response.companies.nodes,
      hasNextPage: response.companies.pageInfo.hasNextPage,
    }
  }

  async fetchFeaturedVentureCapitals(): Promise<ICompanyBase[]> {
    const response = await this.sdk.fetchFeaturedVentureCapitals()

    return response.featuredVentureCapitals
  }

  async fetchFeaturedStartups(): Promise<ICompanyBase[]> {
    const response = await this.sdk.fetchFeaturedStartups()

    return response.featuredStartups
  }

  async fetchCompaniesWithJobs(
    input: FetchCompaniesWithJobsRequestInput
  ): Promise<FetchCompaniesWithJobsRequestOutput> {
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else if (input.vcSlug) {
      after = this._fetchInvestmentsWithJobsOfVcCursors[input.vcSlug]
    } else if (input.angelUsername) {
      after = this._fetchInvestmentsWithJobsOfAngelCursors[input.angelUsername]
    } else {
      after = this._fetchCompaniesWithJobsCursors
    }

    const variables = {
      after,
      before: '',
      first: input.limit,
      firstJobs: 3, // Job の取得の件数
      isVc: input.isVc,
      marketIds: input.marketIds ? input.marketIds : [],
      maxInvestmentAmount: input.maxInvestmentAmount ? input.maxInvestmentAmount : null,
      minInvestmentAmount: input.minInvestmentAmount ? input.minInvestmentAmount : null,
      companySize: input.companySize ? input.companySize : null,
      roundIds: input.roundIds ? input.roundIds : [],
      investmentTargetMarketIds: input.investmentTargetMarketIds ? input.investmentTargetMarketIds : [],
      investmentTargetRoundIds: input.investmentTargetRoundIds ? input.investmentTargetRoundIds : [],
      companyLocationIds: input.companyLocationIds ? input.companyLocationIds : [],
      currencyUnit: input.currencyUnit ? input.currencyUnit : null,
      minMonthlySalary: input.minMonthlySalary ? input.minMonthlySalary : null,
      maxMonthlySalary: input.maxMonthlySalary ? input.maxMonthlySalary : null,
      tokenGrant: input.tokenGrant ? input.tokenGrant : [],
      typeOfPosition: input.typeOfPosition ? input.typeOfPosition : [],
      workExperience: input.workExperience ? input.workExperience : null,
      workingForm: input.workingForm ? input.workingForm : [],
      jobCategoryIds: input.jobCategoryIds ? input.jobCategoryIds : [],
      skillIds: input.skillIds ? input.skillIds : [],
      jobLocationIds: input.jobLocationIds ? input.jobLocationIds : [],
      query: input.searchWord ? input.searchWord : '',
      vcSlug: input.vcSlug ? input.vcSlug : null,
      angelUsername: input.angelUsername ? input.angelUsername : null,
    }
    const response = await this.sdk.fetchCompaniesWithJobs(variables, this._headers())

    if (input.vcSlug) {
      this._fetchInvestmentsWithJobsOfVcCursors[input.vcSlug] = response.companiesWithJobs.pageInfo.endCursor
    } else if (input.angelUsername) {
      this._fetchInvestmentsWithJobsOfAngelCursors[input.angelUsername] = response.companiesWithJobs.pageInfo.endCursor
    } else {
      this._fetchCompaniesWithJobsCursors = response.companiesWithJobs.pageInfo.endCursor
    }

    return {
      companies: response.companiesWithJobs.nodes,
      hasNextPage: response.companiesWithJobs.pageInfo.hasNextPage,
    }
  }

  async fetchFeaturedCompaniesWithJobs(): Promise<ICompanyBase[]> {
    const variables = {
      firstJobs: 3, // Job の取得の件数
    }

    const response = await this.sdk.fetchFeaturedCompaniesWithJobs(variables, this._headers())

    return response.featuredCompaniesWithJobs
  }

  async searchCompany(input: SearchCompanyRequestInput): Promise<ICompanyBase[]> {
    const response = await this.sdk.searchCompany(
      {
        first: input.limit,
        query: input.searchWord,
      },
      this._headers()
    )

    return response.searchCompany.nodes
  }

  async fetchCompany(input: FetchCompanyRequestInput): Promise<ICompanyBase> {
    const response = await this.sdk.fetchCompany(
      {
        slug: input.slug,
        firstJobs: input.limitOfJob,
        firstReferences: input.limitOfReference ? input.limitOfReference : null,
      },
      this._headers()
    )

    return response.company
  }

  async fetchCompanyForViewer(slug: string): Promise<ICompanyBase> {
    const response = await this.sdk.fetchCompanyForViewer(
      {
        slug,
      },
      this._headers()
    )

    return response.company
  }

  async fetchMyCompany(input: FetchMyCompanyRequestInput): Promise<IMyCompanyBase> {
    const response = await this.sdk.fetchMyCompany(
      {
        slug: input.slug,
      },
      this._headers()
    )

    return response.myCompany
  }

  async createCompany(company: ICompanyInputBase): Promise<ICompanyBase> {
    const response = await this.sdk.createCompany(
      {
        input: {
          attributes: company,
        },
      },
      this._headers()
    )

    return response.createCompany
  }

  async createMyCompany(company: ICompanyInputBase): Promise<IMyCompanyBase> {
    const response = await this.sdk.createMyCompany(
      {
        input: {
          attributes: company,
        },
      },
      this._headers()
    )

    return response.createMyCompany
  }

  async updateCompany(input: UpdateCompanyRequestInput): Promise<IMyCompanyBase> {
    const response = await this.sdk.updateCompany(
      {
        input: {
          attributes: input.company,
          id: input.id,
        },
      },
      this._headers()
    )

    return response.updateCompany
  }

  async addCompanyMember(input: AddCompanyMemberRequestInput): Promise<IMyCompanyMemberBase> {
    const response = await this.sdk.addCompanyMember(
      {
        input: {
          attributes: input,
        },
      },
      this._headers()
    )

    return response.addCompanyMember
  }

  async updateCompanyMember(input: UpdateCompanyMemberRequestInput): Promise<IMyCompanyMemberBase> {
    const response = await this.sdk.updateCompanyMember(
      {
        input,
      },
      this._headers()
    )

    return response.updateCompanyMember
  }

  async removeCompanyMember(input: RemoveCompanyMemberRequestInput): Promise<IMyCompanyMemberBase> {
    const response = await this.sdk.removeCompanyMember(
      {
        input,
      },
      this._headers()
    )

    return response.removeCompanyMember
  }

  async addProduct(input: AddProductRequestInput): Promise<IProductBase> {
    const response = await this.sdk.addProduct(
      {
        input: {
          attributes: input.product,
          companyId: input.companyId,
        },
      },
      this._headers()
    )

    return response.addProduct
  }

  async updateProduct(input: UpdateProductRequestInput): Promise<IProductBase> {
    const response = await this.sdk.updateProduct(
      {
        input: {
          attributes: input.product,
          id: input.id,
        },
      },
      this._headers()
    )

    return response.updateProduct
  }

  async removeProduct(input: RemoveProductRequestInput): Promise<IProductBase> {
    const response = await this.sdk.removeProduct(
      {
        input,
      },
      this._headers()
    )

    return response.removeProduct
  }

  // ============================================================
  // Investments
  // ============================================================
  async addMyInvestment(input: AddMyInvestmentRequestInput): Promise<IInvestmentBase> {
    const response = await this.sdk.addMyInvestment(
      {
        input: {
          attributes: input.investment,
        },
      },
      this._headers()
    )

    return response.addMyInvestment
  }

  async addInvestor(input: AddInvestorRequestInput): Promise<IInvestmentBase> {
    const response = await this.sdk.addInvestor(
      {
        input: {
          attributes: input.investment,
        },
      },
      this._headers()
    )

    return response.addInvestor
  }

  async addCompanyInvestment(input: AddCompanyInvestmentRequestInput): Promise<IInvestmentBase> {
    const response = await this.sdk.addCompanyInvestment(
      {
        input: {
          investingCompanyId: input.investingCompanyId,
          attributes: input.investment,
        },
      },
      this._headers()
    )

    return response.addCompanyInvestment
  }

  async updateInvestment(input: UpdateInvestmentRequestInput): Promise<IInvestmentBase> {
    const response = await this.sdk.updateInvestment(
      {
        input: {
          id: input.id,
          attributes: input.investment,
        },
      },
      this._headers()
    )

    return response.updateInvestment
  }

  async updateInvestor(input: UpdateInvestorRequestInput): Promise<IInvestmentBase> {
    const response = await this.sdk.updateInvestor(
      {
        input: {
          id: input.id,
          attributes: input.investment,
        },
      },
      this._headers()
    )

    return response.updateInvestor
  }

  async removeInvestment(input: RemoveInvestmentRequestInput): Promise<IInvestmentBase> {
    const response = await this.sdk.removeInvestment(
      {
        input,
      },
      this._headers()
    )

    return response.removeInvestment
  }

  async removeInvestor(input: RemoveInvestorRequestInput): Promise<IInvestmentBase> {
    const response = await this.sdk.removeInvestor(
      {
        input,
      },
      this._headers()
    )

    return response.removeInvestor
  }

  // ============================================================
  // Articles
  // ============================================================
  async fetchArticle(slug: string): Promise<IArticleBase> {
    const response = await this.sdk.fetchArticle(
      {
        slug,
      },
      this._headers()
    )

    return response.article
  }

  async fetchArticles(input: FetchArticleRequestInput): Promise<FetchArticleRequestOutput> {
    const response = await this.sdk.fetchArticles(
      {
        after: typeof window !== 'undefined' ? this._fetchArticlesCursors[input.cursorKey] : '',
        before: '',
        first: input.limit,
        categories: input.categories,
      },
      this._headers()
    )

    this._fetchArticlesCursors[input.cursorKey] = response.articles.pageInfo.endCursor

    return {
      articles: response.articles.nodes,
      hasNextPage: response.articles.pageInfo.hasNextPage,
    }
  }

  async fetchCompanyArticles(input: FetchCompanyArticleRequestInput): Promise<FetchCompanyArticleRequestOutput> {
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else {
      after = this._fetchCompanyArticlesCursors[input.slug]
    }

    const response = await this.sdk.fetchCompanyArticles(
      {
        after,
        before: '',
        first: input.limit,
        slug: input.slug,
      },
      this._headers()
    )

    this._fetchCompanyArticlesCursors[input.slug] = response.companyArticles.pageInfo.endCursor

    return {
      articles: response.companyArticles.nodes,
      hasNextPage: response.companyArticles.pageInfo.hasNextPage,
    }
  }

  async fetchMyCompanyArticles(
    input: FetchMyCompanyArticlesRequestInput
  ): Promise<FetchMyCompanyArticlesRequestOutput> {
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else if (input.after) {
      after = input.after
    }

    const response = await this.sdk.fetchMyCompanyArticles(
      {
        after,
        before: '',
        first: input.limit,
        slug: input.slug,
      },
      this._headers()
    )

    return {
      articles: response.myCompany.articles.nodes,
      hasNextPage: response.myCompany.articles.pageInfo.hasNextPage,
      endCursor: response.myCompany.articles.pageInfo.endCursor, // Company の entity で endCursor を管理
    }
  }

  async fetchFeaturedStories(): Promise<IArticleBase[]> {
    const response = await this.sdk.fetchFeaturedStories({}, this._headers())

    return response.featuredStories
  }

  async createCompanyArticle(input: CreateCompanyArticleRequestInput): Promise<IArticleBase> {
    const response = await this.sdk.createCompanyArticle(
      {
        input: {
          companyId: input.companyId,
          attributes: input.article,
        },
      },
      this._headers()
    )

    return response.createCompanyArticle
  }

  async updateCompanyArticle(input: UpdateCompanyArticleRequestInput): Promise<IArticleBase> {
    const response = await this.sdk.updateCompanyArticle(
      {
        input: {
          id: input.id,
          attributes: input.article,
        },
      },
      this._headers()
    )

    return response.updateCompanyArticle
  }

  async deleteArticle(input: DeleteArticleRequestInput): Promise<IArticleBase> {
    const response = await this.sdk.deleteArticle(
      {
        input,
      },
      this._headers()
    )

    return response.deleteArticle
  }

  async fetchUserArticles(input: FetchUserArticlesRequestInput): Promise<FetchUserArticlesRequestOutput> {
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else {
      after = this._fetchUserArticlesCursors[input.username]
    }

    const response = await this.sdk.fetchUserArticles(
      {
        after,
        before: '',
        first: input.limit,
        username: input.username,
      },
      this._headers()
    )

    this._fetchUserArticlesCursors[input.username] = response.userArticles.pageInfo.endCursor

    return {
      articles: response.userArticles.nodes,
      hasNextPage: response.userArticles.pageInfo.hasNextPage,
    }
  }

  async fetchMyArticles(input: FetchMyArticlesRequestInput): Promise<IArticleBase[]> {
    // pageNation を使っていないので一旦 after, before を常に空文字で指定
    const response = await this.sdk.fetchMyArticles(
      {
        after: '',
        before: '',
        first: input.limit,
      },
      this._headers()
    )

    return response.myArticles.nodes
  }

  async createMyArticle(input: CreateMyArticleRequestInput): Promise<IArticleBase> {
    const response = await this.sdk.createMyArticle(
      {
        input: {
          attributes: input.article,
        },
      },
      this._headers()
    )

    return response.createMyArticle
  }

  async updateMyArticle(input: UpdateMyArticleRequestInput): Promise<IArticleBase> {
    const response = await this.sdk.updateMyArticle(
      {
        input: {
          id: input.id,
          attributes: input.article,
        },
      },
      this._headers()
    )

    return response.updateMyArticle
  }

  async deleteMyArticle(input: DeleteArticleRequestInput): Promise<IArticleBase> {
    const response = await this.sdk.deleteMyArticle(
      {
        input,
      },
      this._headers()
    )

    return response.deleteMyArticle
  }

  async deleteCompanyArticle(input: DeleteArticleRequestInput): Promise<IArticleBase> {
    const response = await this.sdk.deleteCompanyArticle(
      {
        input,
      },
      this._headers()
    )

    return response.deleteCompanyArticle
  }

  async toggleArticleLikedState(input: ToggleArticleLikedStateRequestInput): Promise<IArticleBase> {
    const response = await this.sdk.toggleArticleLikedState(
      {
        input,
      },
      this._headers()
    )

    return response.toggleArticleLikedState
  }

  async fetchPickedUpFeatures(): Promise<IArticleBase[]> {
    const response = await this.sdk.fetchPickedUpFeatures({}, this._headers())

    return response.pickedUpFeatures
  }

  // ============================================================
  // ArticleAttachment
  // ============================================================

  async createArticleAttachment(input: CreateArticleAttachmentRequestInput): Promise<IArticleAttachmentBase> {
    const response = await this.sdk.createArticleAttachment(
      {
        input: {
          attributes: input.articleAttachment,
        },
      },
      this._headers()
    )

    return response.createArticleAttachment
  }

  // ============================================================
  // Chat
  // ============================================================

  /**
   * 各スレッドの Subscription を初期化して格納
   * @param input
   */
  createChatMessageThreadSubscription(input: CreateChatMessageThreadsSubscriptionRequestInput): void {
    this._actionCableSubscriptions[input.chatMessageTreadSlug] = this._actionCableConsumer.subscriptions.create(
      {
        channel: 'ChatChannel',
        message_thread_slug: input.chatMessageTreadSlug,
      },
      {
        connected: input?.onConnected,
        disconnected: input?.onDisconnected,
        received: input.onReceived,
      }
    )
  }

  async fetchChatMessages(input: FetchChatMessagesRequestInput): Promise<FetchChatMessagesRequestOutput> {
    const response = await this.sdk.fetchMessageThread(
      {
        slug: input.chatMessageTreadSlug,
        after: input.shouldRefresh ? '' : this._chatMessagesCursors[input.chatMessageTreadSlug],
        before: '',
        first: 100,
      },
      this._headers()
    )

    // カーソル格納
    this._chatMessagesCursors[response.messageThread.slug] = response.messageThread.messages.pageInfo.endCursor

    return {
      messages: response.messageThread.messages.nodes,
      hasNextPage: response.messageThread.messages.pageInfo.hasNextPage,
    }
  }

  async fetchChatMessageThreads(input: FetchChatMessageThreadsRequestInput): Promise<IChatMessageThreadBase[]> {
    const response = await this.sdk.fetchMessageThreads(
      {
        after: '',
        before: '',
        first: input.limitOfMessages,
      },
      this._headers()
    )

    return response.messageThreads
  }

  async createChatMessageThread(input: CreateChatMessageThreadRequestInput): Promise<IChatMessageThreadBase> {
    const response = await this.sdk.createMessageThread(
      {
        input,
      },
      this._headers()
    )

    return response.createMessageThread
  }

  async markAllMessagesAsRead(input: MarkAllMessagesAsReadRequestInput): Promise<void> {
    await this.sdk.markAllMessagesAsRead(
      {
        input,
      },
      this._headers()
    )
  }

  // ============================================================
  // Markets
  // ============================================================

  async fetchMarkets(): Promise<IMarketBase[]> {
    const response = await this.sdk.fetchMarkets({}, this._headers())

    return response.markets
  }

  // ============================================================
  // Notifications
  // ============================================================

  async markNotificationsAsRead(
    input: MarkNotificationsAsReadRequestInput
  ): Promise<MarkNotificationsAsReadRequestOutput> {
    const response = await this.sdk.markNotificationsAsRead(
      {
        input,
      },
      this._headers()
    )

    return response.markNotificationsAsRead
  }

  // ============================================================
  // Locations
  // ============================================================
  async fetchLocations(input: FetchLocationsRequestInput): Promise<ILocationBase[]> {
    const response = await this.sdk.fetchLocations(
      {
        first: input.limit,
        query: input.searchWord,
      },
      this._headers()
    )

    return response.locations.nodes
  }

  async fetchCountries(): Promise<ILocationBase[]> {
    const response = await this.sdk.fetchCountries({}, this._headers())

    return response.countries
  }

  // ============================================================
  // JobCategories
  // ============================================================

  async fetchJobCategories(): Promise<JobCategoryBase[]> {
    const response = await this.sdk.fetchJobCategories({}, this._headers())

    return response.jobCategories
  }

  // ============================================================
  // JobTags
  // ============================================================

  async fetchJobTags(): Promise<IJobTagBase[]> {
    const response = await this.sdk.fetchJobTags({}, this._headers())

    return response.jobTags
  }

  // ============================================================
  // Skills
  // ============================================================
  async fetchSkills(input: FetchSkillsRequestInput): Promise<ISkillBase[]> {
    const response = await this.sdk.fetchSkills({
      first: input.limit,
      query: input.searchWord,
    })

    return response.skills.nodes
  }

  async createSkill(input: CreateSkillRequestInput): Promise<ISkillBase> {
    const response = await this.sdk.createSkill(
      {
        input: {
          attributes: input,
        },
      },
      this._headers()
    )

    return response.createSkill
  }

  // ============================================================
  // Jobs
  // ============================================================
  async fetchJobs(input: FetchJobsRequestInput): Promise<FetchJobsRequestOutput> {
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else {
      after = this._fetchJobsCursors
    }

    const variables = {
      after,
      before: '',
      first: input.limit,
      query: input.searchWord ? input.searchWord : '',
      currencyUnit: input.currencyUnit ? input.currencyUnit : null,
      minMonthlySalary: input.minMonthlySalary ? input.minMonthlySalary : null,
      maxMonthlySalary: input.maxMonthlySalary ? input.maxMonthlySalary : null,
      tokenGrant: input.tokenGrant ? input.tokenGrant : [],
      typeOfPosition: input.typeOfPosition ? input.typeOfPosition : [],
      workExperience: input.workExperience ? input.workExperience : null,
      workingForm: input.workingForm ? input.workingForm : [],
      jobCategoryIds: input.jobCategoryIds ? input.jobCategoryIds : [],
      skillIds: input.skillIds ? input.skillIds : [],
      locationIds: input.locationIds ? input.locationIds : [],
    }
    const response = await this.sdk.fetchJobs(variables, this._headers())

    this._fetchJobsCursors = response.jobs.pageInfo.endCursor

    return {
      jobs: response.jobs.nodes,
      hasNextPage: response.jobs.pageInfo.hasNextPage,
    }
  }

  async fetchCompanyJobs(input: FetchCompanyJobsRequestInput): Promise<FetchCompanyJobsRequestOutput> {
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else {
      after = this._fetchCompanyJobsCursors[input.companySlug]
    }

    const response = await this.sdk.fetchCompanyJobs(
      {
        after,
        before: '',
        first: input.limit,
        companySlug: input.companySlug,
      },
      this._headers()
    )

    this._fetchCompanyJobsCursors[input.companySlug] = response.companyJobs.pageInfo.endCursor

    return {
      jobs: response.companyJobs.nodes,
      hasNextPage: response.companyJobs.pageInfo.hasNextPage,
    }
  }

  async fetchMyJobs(input: FetchMyJobsRequestInput): Promise<FetchMyJobsRequestOutput> {
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else {
      after = this._fetchMyJobsCursors[input.companySlug]
    }

    const response = await this.sdk.fetchMyJobs(
      {
        after,
        before: '',
        first: input.limit,
        companySlug: input.companySlug,
      },
      this._headers()
    )

    this._fetchMyJobsCursors[input.companySlug] = response.myJobs.pageInfo.endCursor

    return {
      jobs: response.myJobs.nodes,
      hasNextPage: response.myJobs.pageInfo.hasNextPage,
    }
  }

  async fetchJob(input: FetchJobRequestInput): Promise<IJobBase> {
    const response = await this.sdk.fetchJob(
      {
        slug: input.slug,
      },
      this._headers()
    )

    return response.job
  }

  async createJob(input: CreateJobRequestInput): Promise<IJobBase> {
    const response = await this.sdk.createJob(
      {
        input: {
          companyId: input.companyId,
          attributes: input.job,
        },
      },
      this._headers()
    )

    return response.createJob
  }

  async duplicateJob(input: DuplicateJobRequestInput): Promise<IJobBase> {
    const response = await this.sdk.duplicateJob(
      {
        input: {
          id: input.id,
        },
      },
      this._headers()
    )

    return response.duplicateJob
  }

  async updateJob(input: UpdateJobRequestInput): Promise<IJobBase> {
    const response = await this.sdk.updateJob(
      {
        input: {
          id: input.id,
          attributes: input.job,
        },
      },
      this._headers()
    )

    return response.updateJob
  }

  async deleteJob(input: DeleteJobRequestInput): Promise<IJobBase> {
    const response = await this.sdk.deleteJob(
      {
        input: {
          id: input.id,
        },
      },
      this._headers()
    )

    return response.deleteJob
  }

  async addFootprintToJob(input: AddFootprintToJobInputBase): Promise<IJobBase> {
    const response = await this.sdk.addFootprintToJob(
      {
        input: {
          slug: input.slug,
          referer: input.referer,
        },
      },
      this._headers()
    )

    return response.addFootprintToJob
  }

  async fetchJobFootprints(input: FetchJobFootprintsRequestInput): Promise<FetchJobFootprintsRequestOutput> {
    // TODO: UI 変更時に jobSlug も考慮できるように調製
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else {
      after = input.jobSlug
        ? this._fetchJobFootprintsCursors[input.jobSlug]
        : this._fetchJobFootprintsCursors[input.companySlug]
    }

    const variables = {
      after,
      before: '',
      first: input.limit,
      companySlug: input.companySlug,
      jobSlug: input.jobSlug ? input.jobSlug : null,
    }
    const response = await this.sdk.fetchJobFootprints(variables, this._headers())

    if (input.jobSlug) {
      this._fetchJobFootprintsCursors[input.jobSlug] = response.jobFootprints.pageInfo.endCursor
    }
    this._fetchJobFootprintsCursors[input.companySlug] = response.jobFootprints.pageInfo.endCursor

    return {
      jobFootprints: response.jobFootprints.nodes,
      hasNextPage: response.jobFootprints.pageInfo.hasNextPage,
    }
  }

  async fetchFeaturedJobs(): Promise<IJobBase[]> {
    const response = await this.sdk.fetchFeaturedJobs()

    return response.featuredJobs
  }

  // ============================================================
  // JobAttachment
  // ============================================================

  async createJobAttachment(input: CreateJobAttachmentRequestInput): Promise<IJobAttachmentBase> {
    const response = await this.sdk.createJobAttachment(
      {
        input: {
          attributes: input.jobAttachment,
        },
      },
      this._headers()
    )

    return response.createJobAttachment
  }

  // ============================================================
  // JobApplication
  // ============================================================
  async fetchJobApplications(input: FetchJobApplicationsRequestInput): Promise<FetchJobApplicationsRequestOutput> {
    const response = await this.sdk.fetchJobApplications(
      {
        after: '',
        before: '',
        first: input.limitOfMessage,
        after1: '',
        before1: '',
        first1: input.limitOfJopApplication,
        companySlug: input.companySlug,
      },
      this._headers()
    )

    return {
      jobApplications: response.jobApplications.nodes,
      hasNextPage: response.jobApplications.pageInfo.hasNextPage,
    }
  }

  async applyForJob(input: ApplyForJobRequestInput): Promise<IJobApplicationBase> {
    const response = await this.sdk.applyForJob(
      {
        input,
      },
      this._headers()
    )

    return response.applyForJob
  }

  // ============================================================
  // JobBookmark
  // ============================================================
  async fetchMyJobBookmarks(input: FetchMyJobBookmarksRequestInput): Promise<FetchMyJobBookmarksRequestOutput> {
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else {
      after = this._fetchMyJobBookmarksCursors
    }

    const response = await this.sdk.fetchMyJobBookmarks(
      {
        after,
        before: '',
        first: input.limit,
      },
      this._headers()
    )

    this._fetchMyJobBookmarksCursors = response.myJobBookmarks.pageInfo.endCursor

    return {
      jobBookmarks: response.myJobBookmarks.nodes,
      hasNextPage: response.myJobBookmarks.pageInfo.hasNextPage,
    }
  }

  async addMyJobBookmark(input: AddMyJobBookmarkRequestInput): Promise<IJobBookmarkBase> {
    const response = await this.sdk.addMyJobBookmark(
      {
        input: {
          jobId: input.jobId,
        },
      },
      this._headers()
    )

    return response.addMyJobBookmark
  }

  async removeMyJobBookmark(input: RemoveMyJobBookmarkRequestInput): Promise<IJobBookmarkBase> {
    const response = await this.sdk.removeMyJobBookmark(
      {
        input: {
          jobId: input.jobId,
        },
      },
      this._headers()
    )

    return response.removeMyJobBookmark
  }

  async fetchJobBookmarks(input: FetchJobBookmarksRequestInput): Promise<FetchJobBookmarksRequestOutput> {
    const variable = {
      after: '',
      before: '',
      first: input.limit,
      companySlug: input.companySlug ? input.companySlug : null,
      jobSlug: input.jobSlug ? input.jobSlug : null,
    }

    const response = await this.sdk.fetchJobBookmarks(variable, this._headers())

    return {
      jobBookmarks: response.jobBookmarks.nodes,
      hasNextPage: response.jobBookmarks.pageInfo.hasNextPage,
    }
  }

  // ============================================================
  // Invitation
  // ============================================================
  async validInvitationToken(input: ValidInvitationTokenRequestInput): Promise<IInvitationBase> {
    const response = await this.sdk.validInvitationToken(
      {
        token: input.token,
      },
      this._headers()
    )

    return response.validInvitationToken
  }

  async addAngelInvitation(input: AddAngelInvitationRequestInput): Promise<IInvitationLinkBase> {
    const response = await this.sdk.addAngelInvitation(
      {
        input: {
          companyId: input.companyId,
          days: input.days,
          emails: input.emails ? input.emails : [],
        },
      },
      this._headers()
    )
    return response.addAngelInvitation
  }

  async addVcInvitation(input: AddVcInvitationRequestInput): Promise<IInvitationLinkBase> {
    const response = await this.sdk.addVcInvitation(
      {
        input: {
          companyId: input.companyId,
          days: input.days,
          emails: input.emails ? input.emails : [],
        },
      },
      this._headers()
    )

    return response.addVcInvitation
  }

  async addAngelInvestmentInvitation(input: AddAngelInvestmentInvitationRequestInput): Promise<IInvitationLinkBase> {
    const response = await this.sdk.addAngelInvestmentInvitation(
      {
        input: {
          days: input.days,
          emails: input.emails ? input.emails : [],
        },
      },
      this._headers()
    )

    return response.addAngelInvestmentInvitation
  }

  async addVcInvestmentInvitation(input: AddVcInvestmentInvitationRequestInput): Promise<IInvitationLinkBase> {
    const response = await this.sdk.addVcInvestmentInvitation(
      {
        input: {
          companyId: input.companyId,
          days: input.days,
          emails: input.emails ? input.emails : [],
        },
      },
      this._headers()
    )

    return response.addVcInvestmentInvitation
  }

  async addMemberInvitation(input: AddMemberInvitationRequestInput): Promise<IInvitationLinkBase> {
    const response = await this.sdk.addMemberInvitation(
      {
        input: {
          companyId: input.companyId,
          days: input.days,
          emails: input.emails ? input.emails : [],
        },
      },
      this._headers()
    )
    return response.addMemberInvitation
  }

  async addUserInvitation(input: AddUserInvitationRequestInput): Promise<IInvitationLinkBase> {
    const response = await this.sdk.addUserInvitation(
      {
        input: {
          days: input.days,
          emails: input.emails ? input.emails : [],
        },
      },
      this._headers()
    )
    return response.addUserInvitation
  }

  // ============================================================
  // Follow, Follower
  // ============================================================
  async toggleFollowCompany(input: ToggleFollowCompanyRequestInput): Promise<ICompanyBase> {
    const response = await this.sdk.toggleFollowCompany(
      {
        input,
      },
      this._headers()
    )

    return response.toggleFollowCompany
  }

  async toggleFollowUser(input: ToggleFollowUserRequestInput): Promise<IUserBase> {
    const response = await this.sdk.toggleFollowUser(
      {
        input,
      },
      this._headers()
    )

    return response.toggleFollowUser
  }

  async fetchFollowing(input: FetchFollowingRequestInput): Promise<FetchFollowingRequestOutput> {
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else {
      after = this._fetchFollowingCursors[input.username]
    }

    const variable = {
      after,
      before: '',
      first: input.limit,
      username: input.username,
    }

    const response = await this.sdk.fetchFollowing(variable, this._headers())

    this._fetchFollowingCursors[input.username] = response.following.pageInfo.endCursor

    return {
      following: response.following.nodes,
      hasNextPage: response.following.pageInfo.hasNextPage,
    }
  }

  async fetchFollowers(input: FetchFollowersRequestInput): Promise<FetchFollowersRequestOutput> {
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else if (input.entityType === FollowableEntityEntityType.COMPANY) {
      after = this._fetchCompanyFollowersCursors[input.slug]
    } else {
      after = this._fetchUserFollowersCursors[input.slug]
    }

    const variable = {
      after,
      before: '',
      first: input.limit,
      slug: input.slug,
      entityType: input.entityType,
    }

    const response = await this.sdk.fetchFollowers(variable, this._headers())

    if (input.entityType === FollowableEntityEntityType.COMPANY) {
      this._fetchCompanyFollowersCursors[input.slug] = response.followers.pageInfo.endCursor
    } else {
      this._fetchUserFollowersCursors[input.slug] = response.followers.pageInfo.endCursor
    }

    return {
      followers: response.followers.nodes,
      hasNextPage: response.followers.pageInfo.hasNextPage,
    }
  }

  // ============================================================
  // NotificationSettings
  // ============================================================
  async updateCompanyNotificationSettings(input: UpdateCompanyNotificationSettingsRequestInput): Promise<ICompanyBase> {
    const response = await this.sdk.updateCompanyNotificationSettings(
      {
        input,
      },
      this._headers()
    )

    return response.updateCompanyNotificationSettings
  }

  async updateUserNotificationSettings(input: UpdateUserNotificationSettingsRequestInput): Promise<IUserBase> {
    const response = await this.sdk.updateUserNotificationSettings(
      {
        input,
      },
      this._headers()
    )

    return response.updateUserNotificationSettings
  }

  // ============================================================
  // FetchNotifications
  // ============================================================
  async fetchNotifications(input: FetchNotificationsRequestInput): Promise<IViewerBase> {
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else {
      after = input.cursor
    }

    const variables = {
      after,
      before: '',
      first: input.limit,
    }

    const response = await this.sdk.fetchNotifications(variables, this._headers())

    return response.me
  }

  // ============================================================
  // UserReferences
  // ============================================================
  async addUserReference(input: AddUserReferenceRequestInput): Promise<IUserReferenceBase> {
    const response = await this.sdk.addUserReference(
      {
        input: {
          username: input.username,
          comment: input.comment,
        },
      },
      this._headers()
    )

    return response.addUserReference
  }

  async updateUserReference(input: UpdateUserReferenceRequestInput): Promise<IUserReferenceBase> {
    const response = await this.sdk.updateUserReference(
      {
        input: {
          id: input.id,
          comment: input.comment,
        },
      },
      this._headers()
    )

    return response.updateUserReference
  }

  async removeUserReference(input: RemoveUserReferenceRequestInput): Promise<IUserReferenceBase> {
    const response = await this.sdk.removeUserReference(
      {
        input: {
          id: input.id,
        },
      },
      this._headers()
    )

    return response.removeUserReference
  }

  async fetchMyReferences(input: FetchMyReferencesRequestInput): Promise<FetchMyReferencesRequestOutput> {
    // ページ送りしないので一旦 after には空文字を指定
    const variable = {
      after: '',
      before: '',
      first: input.limit,
    }

    const response = await this.sdk.fetchMyReferences(variable, this._headers())

    return {
      userReferences: response.myReferences.nodes,
      hasNextPage: response.myReferences.pageInfo.hasNextPage,
    }
  }

  async updateMyReference(input: UpdateMyReferenceRequestInput): Promise<IUserReferenceBase> {
    const response = await this.sdk.updateMyReference(
      {
        input: {
          id: input.id,
          status: input.status,
        },
      },
      this._headers()
    )

    return response.updateMyReference
  }

  // ============================================================
  // CompanyReferences
  // ============================================================
  async addCompanyReference(input: AddCompanyReferenceRequestInput): Promise<ICompanyReferenceBase> {
    const response = await this.sdk.addCompanyReference(
      {
        input: {
          slug: input.slug,
          comment: input.comment,
        },
      },
      this._headers()
    )

    return response.addCompanyReference
  }

  async updateCompanyReference(input: UpdateCompanyReferenceRequestInput): Promise<ICompanyReferenceBase> {
    const response = await this.sdk.updateCompanyReference(
      {
        input: {
          id: input.id,
          comment: input.comment,
        },
      },
      this._headers()
    )

    return response.updateCompanyReference
  }

  async removeCompanyReference(input: RemoveCompanyReferenceRequestInput): Promise<ICompanyReferenceBase> {
    const response = await this.sdk.removeCompanyReference(
      {
        input: {
          id: input.id,
        },
      },
      this._headers()
    )

    return response.removeCompanyReference
  }

  async fetchMyCompanyReferences(
    input: FetchMyCompanyReferencesRequestInput
  ): Promise<FetchMyCompanyReferencesRequestOutput> {
    // ページ送りしないので一旦 after には空文字を指定
    const variable = {
      after: '',
      before: '',
      first: input.limit,
      slug: input.slug,
    }

    const response = await this.sdk.fetchMyCompanyReferences(variable, this._headers())

    return {
      companyReferences: response.myCompanyReferences.nodes,
      hasNextPage: response.myCompanyReferences.pageInfo.hasNextPage,
    }
  }

  async updateMyCompanyReference(input: UpdateMyCompanyReferenceRequestInput): Promise<ICompanyReferenceBase> {
    const response = await this.sdk.updateMyCompanyReference(
      {
        input: {
          id: input.id,
          status: input.status,
        },
      },
      this._headers()
    )

    return response.updateMyCompanyReference
  }

  // ============================================================
  // Blockchains
  // ============================================================
  async fetchBlockchains(input: FetchBlockchainsRequestInput): Promise<IBlockchainBase[]> {
    const response = await this.sdk.fetchBlockchains({
      first: input.limit,
      query: input.searchWord,
    })

    return response.blockchains.nodes
  }

  // ============================================================
  // Tokens
  // ============================================================
  async fetchTokens(input: FetchTokensRequestInput): Promise<ITokenBase[]> {
    const response = await this.sdk.fetchTokens({
      first: input.limit,
      query: input.searchWord,
    })

    return response.tokens.nodes
  }

  async addToken(input: AddTokenRequestInput): Promise<ITokenBase> {
    const response = await this.sdk.addToken(
      {
        input: {
          attributes: input,
        },
      },
      this._headers()
    )

    return response.addToken
  }

  // ============================================================
  // Offers
  // ============================================================
  async fetchOffers(input: FetchOffersRequestInput): Promise<FetchOffersRequestOutput> {
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else {
      after = this._fetchOffersCursors
    }

    const variables = {
      after,
      before: '',
      first: input.limit,
      query: input.searchWord ? input.searchWord : '',
      currencyUnit: input.currencyUnit ? input.currencyUnit : null,
      salary: input.salary ? input.salary : null,
      jobType: input.jobType ? input.jobType : [],
      workingForm: input.workingForm ? input.workingForm : [],
      jobCategoryIds: input.jobCategoryIds ? input.jobCategoryIds : [],
      locationIds: input.locationIds ? input.locationIds : [],
    }
    const response = await this.sdk.fetchOffers(variables, this._headers())

    this._fetchOffersCursors = response.offers.pageInfo.endCursor

    return {
      offers: response.offers.nodes,
      hasNextPage: response.offers.pageInfo.hasNextPage,
    }
  }

  async fetchUserOffers(input: FetchUserOffersRequestInput): Promise<FetchUserOffersRequestOutput> {
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else {
      after = this._fetchUserOffersCursors[input.username]
    }

    const response = await this.sdk.fetchUserOffers(
      {
        after,
        before: '',
        first: input.limit,
        username: input.username,
      },
      this._headers()
    )

    this._fetchUserOffersCursors[input.username] = response.userOffers.pageInfo.endCursor

    return {
      offers: response.userOffers.nodes,
      hasNextPage: response.userOffers.pageInfo.hasNextPage,
    }
  }

  async fetchMyOffers(input: FetchMyOffersRequestInput): Promise<FetchMyOffersRequestOutput> {
    // TODO: pagenation
    const response = await this.sdk.fetchMyOffers(
      {
        after: '',
        before: '',
        first: input.limit,
      },
      this._headers()
    )

    return {
      offers: response.myOffers.nodes,
      hasNextPage: response.myOffers.pageInfo.hasNextPage,
    }
  }

  async fetchOffer(input: FetchOfferRequestInput): Promise<IOfferBase> {
    const response = await this.sdk.fetchOffer(
      {
        slug: input.slug,
      },
      this._headers()
    )

    return response.offer
  }

  async createMyOffer(input: CreateMyOfferRequestInput): Promise<IOfferBase> {
    const response = await this.sdk.createMyOffer(
      {
        input: {
          attributes: input.offer,
        },
      },
      this._headers()
    )

    return response.createMyOffer
  }

  async updateMyOffer(input: UpdateMyOfferRequestInput): Promise<IOfferBase> {
    const response = await this.sdk.updateMyOffer(
      {
        input: {
          id: input.id,
          attributes: input.offer,
        },
      },
      this._headers()
    )

    return response.updateMyOffer
  }

  async deleteMyOffer(input: DeleteMyOfferRequestInput): Promise<IOfferBase> {
    const response = await this.sdk.deleteMyOffer(
      {
        input: {
          id: input.id,
        },
      },
      this._headers()
    )

    return response.deleteMyOffer
  }

  // ============================================================
  // QuestionCategories
  // ============================================================
  async fetchQuestionCategories(): Promise<QuestionCategoryBase[]> {
    const response = await this.sdk.fetchQuestionCategories({}, this._headers())

    return response.questionCategories
  }

  // ============================================================
  // Questions
  // ============================================================
  async fetchQuestions(input: FetchQuestionsRequestInput): Promise<FetchQuestionsRequestOutput> {
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else {
      after = this._fetchQuestionsCursors
    }

    const variables = {
      after,
      before: '',
      first: input.limit,
      orderBy: input.orderBy ? input.orderBy : null,
      query: input.searchWord ? input.searchWord : '',
      questionCategories: input.questionCategories ? input.questionCategories : [],
    }
    const response = await this.sdk.fetchQuestions(variables, this._headers())

    this._fetchQuestionsCursors = response.questions.pageInfo.endCursor

    return {
      questions: response.questions.nodes,
      hasNextPage: response.questions.pageInfo.hasNextPage,
    }
  }

  async fetchQuestion(input: FetchQuestionRequestInput): Promise<IQuestionBase> {
    const response = await this.sdk.fetchQuestion(
      {
        slug: input.slug,
      },
      this._headers()
    )

    return response.question
  }

  async fetchUserQuestions(input: FetchUserQuestionsRequestInput): Promise<FetchUserQuestionsRequestOutput> {
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else {
      after = this._fetchUserQuestionsCursors[input.username]
    }

    const response = await this.sdk.fetchUserQuestions(
      {
        after,
        before: '',
        first: input.limit,
        username: input.username,
      },
      this._headers()
    )

    this._fetchUserQuestionsCursors[input.username] = response.userQuestions.pageInfo.endCursor

    return {
      questions: response.userQuestions.nodes,
      hasNextPage: response.userQuestions.pageInfo.hasNextPage,
    }
  }

  async createMyQuestion(input: CreateMyQuestionRequestInput): Promise<IQuestionBase> {
    const response = await this.sdk.createMyQuestion(
      {
        input: {
          attributes: input.question,
          choices: input.choices.length > 0 ? input.choices : [],
        },
      },
      this._headers()
    )

    return response.createMyQuestion
  }

  async updateMyQuestion(input: UpdateMyQuestionRequestInput): Promise<IQuestionBase> {
    const response = await this.sdk.updateMyQuestion(
      {
        input: {
          id: input.id,
          attributes: input.question,
        },
      },
      this._headers()
    )

    return response.updateMyQuestion
  }

  async deleteMyQuestion(input: DeleteMyQuestionRequestInput): Promise<IQuestionBase> {
    const response = await this.sdk.deleteMyQuestion(
      {
        input: {
          id: input.id,
        },
      },
      this._headers()
    )

    return response.deleteMyQuestion
  }

  async voteForChoice(input: VoteForChoiceRequestInput): Promise<IChoiceBase> {
    const response = await this.sdk.voteForChoice(
      {
        input,
      },
      this._headers()
    )

    return response.voteForChoice
  }

  // ============================================================
  // Answers
  // ============================================================
  async createMyAnswer(input: CreateMyAnswerRequestInput): Promise<IAnswerBase> {
    const response = await this.sdk.createMyAnswer(
      {
        input: {
          slug: input.slug,
          body: input.body,
        },
      },
      this._headers()
    )

    return response.createMyAnswer
  }

  async createMyReply(input: CreateMyReplyRequestInput): Promise<IAnswerBase> {
    const response = await this.sdk.createMyReply(
      {
        input: {
          slug: input.slug,
          body: input.body,
        },
      },
      this._headers()
    )

    return response.createMyReply
  }

  async updateMyAnswer(input: UpdateMyAnswerRequestInput): Promise<IAnswerBase> {
    const response = await this.sdk.updateMyAnswer(
      {
        input: {
          id: input.id,
          body: input.body,
        },
      },
      this._headers()
    )

    return response.updateMyAnswer
  }

  async deleteMyAnswer(input: DeleteMyAnswerRequestInput): Promise<IAnswerBase> {
    const response = await this.sdk.deleteMyAnswer(
      {
        input: {
          id: input.id,
        },
      },
      this._headers()
    )

    return response.deleteMyAnswer
  }

  async fetchReply(input: FetchReplyRequestInput): Promise<IAnswerBase> {
    const response = await this.sdk.fetchReply(
      {
        id: input.id,
      },
      this._headers()
    )

    return response.reply
  }

  // ============================================================
  // TokenTransactions
  // ============================================================
  async addTokenTransaction(input: AddTokenTransactionRequestInput): Promise<ITokenTransactionBase> {
    const response = await this.sdk.addTokenTransaction(
      {
        input: {
          answerId: input.answerId ? input.answerId : null,
          articleId: input.articleId ? input.articleId : null,
          questionId: input.questionId ? input.questionId : null,
          attributes: input.tokenTransaction,
        },
      },
      this._headers()
    )

    return response.addTokenTransaction
  }

  // ============================================================
  // Nfts
  // ============================================================
  async fetchNfts(input: FetchNftsRequestInput): Promise<FetchNftsRequestOutput> {
    let after = ''
    if (input.shouldRefresh) {
      after = ''
    } else {
      after = this._fetchNftsCursors
    }

    const variables = {
      after,
      before: '',
      first: input.limit,
    }

    const response = await this.sdk.fetchNfts(variables, this._headers())

    this._fetchNftsCursors = response.nfts.pageInfo.endCursor

    return {
      nfts: response.nfts.nodes,
      hasNextPage: response.nfts.pageInfo.hasNextPage,
    }
  }

  async fetchNft(input: FetchNftRequestInput): Promise<INftBase> {
    const response = await this.sdk.fetchNft(
      {
        mintAddress: input.mintAddress,
      },
      this._headers()
    )

    return response.nft
  }

  async fethNftsCount(): Promise<INftsCountBase> {
    const response = await this.sdk.fetchNftsCount({}, this._headers())

    return response.nftsCount
  }

  async changeSoldOut(input: ChangeSoldOutRequestInput): Promise<IListingBase> {
    const response = await this.sdk.changeSoldOut(
      {
        input,
      },
      this._headers()
    )

    return response.changeSoldOut
  }
}
