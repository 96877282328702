import { inject, injectable } from 'inversify'
import UserAnswer from '@/entities/UserAnswer'
import symbols from '@/symbols'
import { IErrorsStore, IUserAnswer, IUserAnswerFactory, UserAnswerFactoryInput } from '@/types'

@injectable()
export default class UserAnswerFactory implements IUserAnswerFactory {
  @inject(symbols.IErrorsStore) private errorStore: IErrorsStore

  public create(input: UserAnswerFactoryInput): IUserAnswer {
    const userAnswer = new UserAnswer(input.base)
    userAnswer.errorsStore = this.errorStore
    return userAnswer
  }
}
