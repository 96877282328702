import { action, computed, observable } from 'mobx'
import {
  IQuestionInputBase,
  IErrorsStore,
  IQuestionBase,
  IUpdateMyQuestionUseCase,
  IUserQuestion,
  QuestionStatusBase,
  UpdateMyQuestionUseCaseOutput,
  QuestionCategoryBase,
  IVoteForChoiceUseCase,
  IChoiceBase,
  QuestionTypeBase,
  IFetchQuestionUseCase,
} from '@/types'
import { DEFAULT_ARTICLE_THUMBNAIL_PATH } from '@/constants'

type Dependencies = {
  fetchQuestionUseCase: IFetchQuestionUseCase
  voteForChoiceUseCase: IVoteForChoiceUseCase
}

export default class UserQuestion implements IUserQuestion {
  @observable id = ''

  @observable title = ''

  @observable slug = ''

  @observable body = ''

  @observable status: QuestionStatusBase

  @observable _ogImage = ''

  @observable createdAt = ''

  @observable updatedAt = ''

  @observable questionCategory: QuestionCategoryBase

  @observable questionType: QuestionTypeBase

  @observable choices: IChoiceBase[]

  @observable pollDeadline = ''

  @observable votesCount = 0

  @observable isVotable: boolean

  @observable isVotedInBy: boolean

  @observable mostVotedChoices: IChoiceBase[]

  errorsStore: IErrorsStore

  updateMyQuestionUseCase: IUpdateMyQuestionUseCase

  voteForChoiceUseCase: IVoteForChoiceUseCase

  fetchQuestionUseCase: IFetchQuestionUseCase

  @computed
  get statusName(): string {
    if (this.status === QuestionStatusBase.DRAFT) {
      return 'draft'
    }
    if (this.status === QuestionStatusBase.PUBLISHED) {
      return 'published'
    }
    if (this.status === QuestionStatusBase.CLOSED) {
      return 'closed'
    }

    return ''
  }

  @computed
  get isPublished(): boolean {
    if (this.status === QuestionStatusBase.PUBLISHED) {
      return true
    }

    return false
  }

  @computed
  get ogImage(): string {
    if (this._ogImage) {
      return this._ogImage
    }

    return DEFAULT_ARTICLE_THUMBNAIL_PATH
  }

  @action
  _mapFromBase(base: IQuestionBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      if (key === 'ogImage') {
        this._ogImage = base[key]
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this[key] = base[key]
      }
    })
  }

  @action
  update(base: IQuestionBase): void {
    this._mapFromBase(base)
  }

  constructor(base: IQuestionBase, deps: Dependencies) {
    this._mapFromBase(base)
    this.fetchQuestionUseCase = deps.fetchQuestionUseCase
    this.voteForChoiceUseCase = deps.voteForChoiceUseCase
  }

  async save(question: IQuestionInputBase): Promise<UpdateMyQuestionUseCaseOutput> {
    const output = await this.updateMyQuestionUseCase.handle({ id: this.id, question })

    if (output.data.question) {
      this._mapFromBase(output.data.question)
    }

    return output
  }

  async voteForChoice(id: string): Promise<boolean> {
    const output = await this.voteForChoiceUseCase.handle({ id })

    if (output.choice) {
      this.update(output.choice.question)
      return true
    }

    return false
  }

  async fetch(): Promise<boolean> {
    const output = await this.fetchQuestionUseCase.handle({ slug: this.slug })

    if (output.question) {
      this.update(output.question)
      return true
    }

    return false
  }
}
