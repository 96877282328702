import { observable } from 'mobx'
import {
  CurrencyUnit,
  ILocationBase,
  IMarketBase,
  IRoundBase,
  ISkillBase,
  IUserProfile,
  IUserProfileBase,
  JobCategoryBase,
  UserProfileDesiredCareerPathBase,
  UserProfileDesiredCommunicationStyleBase,
  UserProfileDesiredDecisionCultureBase,
  UserProfileDesiredEvaluationCultureBase,
  UserProfileDesiredManagementCultureBase,
  UserProfileDesiredOrientationCultureBase,
  UserProfileDesiredProgressionStyleBase,
  UserProfileDesiredRoleOnTeamBase,
  UserProfileDesiredTakingOnWorkStyleBase,
  UserProfileDesiredTrainingCultureBase,
  UserProfileDesiredWorkplaceCultureBase,
  UserProfileDesiredWorkStyleBase,
  UserProfileJobHuntingStatus,
  UserProfileYearsOfExperience,
  UserProfileBirthdayAccessScopeBase,
  UserProfileAllowMessageRequestsFrom,
  UserProfileDelayOfEmailNotificationsForMessages,
  UserProfileDelayOfEmailNotificationsForFollower,
  ITokenBase,
  UserProfileDelayOfEmailNotificationsForAnswersBase,
  UserProfileDelayOfEmailNotificationsForTokenTransactionsBase,
} from '@/types'

export default class UserProfile implements IUserProfile {
  @observable avatar: string

  @observable headerImage: string

  @observable bio: string

  @observable jobTitle: string

  @observable twitterUrl: string

  @observable facebookUrl: string

  @observable instagramUrl: string

  @observable linkedinUrl: string

  @observable githubUrl: string

  @observable websiteUrl: string

  @observable telegramUrl: string

  @observable openseaUrl: string

  @observable discordUrl: string

  @observable isAngel: boolean

  @observable isInvestor: boolean

  @observable isJobSeeker: boolean

  @observable isJobSeekerMailMagazineAccepted: boolean

  @observable isVerifiedAccount: boolean

  @observable maxInvestmentAmount: string

  @observable minInvestmentAmount: string

  @observable currencyUnit: CurrencyUnit

  @observable investmentTargetMarkets: IMarketBase[] = []

  @observable investmentTargetRounds: IRoundBase[] = []

  @observable locations: ILocationBase[] = []

  @observable yearsOfExperience: UserProfileYearsOfExperience

  @observable jobHuntingStatus: UserProfileJobHuntingStatus

  @observable isFulltimeEmployeeDesired: boolean

  @observable isContractorDesired: boolean

  @observable isCofounderDesired: boolean

  @observable isInternDesired: boolean

  @observable primaryJobCategory: JobCategoryBase

  @observable jobCategories: JobCategoryBase[] = []

  @observable skills: ISkillBase[] = []

  @observable hasInvestmentsWithJobs: boolean

  @observable desiredThingsInNextJob: string

  @observable desiredCareerPath: UserProfileDesiredCareerPathBase

  @observable desiredCommunicationStyle: UserProfileDesiredCommunicationStyleBase

  @observable desiredDecisionCulture: UserProfileDesiredDecisionCultureBase

  @observable desiredEvaluationCulture: UserProfileDesiredEvaluationCultureBase

  @observable desiredManagementCulture: UserProfileDesiredManagementCultureBase

  @observable desiredOrientationCulture: UserProfileDesiredOrientationCultureBase

  @observable desiredProgressionStyle: UserProfileDesiredProgressionStyleBase

  @observable desiredRoleOnTeam: UserProfileDesiredRoleOnTeamBase

  @observable desiredTakingOnWorkStyle: UserProfileDesiredTakingOnWorkStyleBase

  @observable desiredTrainingCulture: UserProfileDesiredTrainingCultureBase

  @observable desiredWorkStyle: UserProfileDesiredWorkStyleBase

  @observable desiredWorkplaceCulture: UserProfileDesiredWorkplaceCultureBase

  @observable birthday: string

  @observable birthdayAccessScope: UserProfileBirthdayAccessScopeBase

  @observable allowMessageRequestsFrom: UserProfileAllowMessageRequestsFrom

  @observable receiveEmailNotificationsForMessages: boolean

  @observable delayOfEmailNotificationsForMessages: UserProfileDelayOfEmailNotificationsForMessages

  @observable receiveEmailNotificationsForFollower: boolean

  @observable delayOfEmailNotificationsForFollower: UserProfileDelayOfEmailNotificationsForFollower

  @observable receiveEmailNotificationsForAnswers: boolean

  @observable delayOfEmailNotificationsForAnswers: UserProfileDelayOfEmailNotificationsForAnswersBase

  @observable receiveEmailNotificationsForTokenTransactions: boolean

  @observable
  delayOfEmailNotificationsForTokenTransactions: UserProfileDelayOfEmailNotificationsForTokenTransactionsBase

  @observable solanaAddress: string

  @observable tokens: ITokenBase[]

  constructor(base: IUserProfileBase) {
    this.avatar = base.avatar
    this.headerImage = base.headerImage
    this.bio = base.bio
    this.birthday = base.birthday as string
    this.birthdayAccessScope = base.birthdayAccessScope
    this.jobTitle = base.jobTitle
    this.twitterUrl = base.twitterUrl
    this.facebookUrl = base.facebookUrl
    this.instagramUrl = base.instagramUrl
    this.linkedinUrl = base.linkedinUrl
    this.githubUrl = base.githubUrl
    this.websiteUrl = base.websiteUrl
    this.telegramUrl = base.telegramUrl
    this.openseaUrl = base.openseaUrl
    this.discordUrl = base.discordUrl
    this.isAngel = base.isAngel
    this.isInvestor = base.isInvestor
    this.isJobSeeker = base.isJobSeeker
    this.isJobSeekerMailMagazineAccepted = base.isJobSeekerMailMagazineAccepted
    this.isVerifiedAccount = base.isVerifiedAccount
    this.maxInvestmentAmount = base.maxInvestmentAmount
    this.minInvestmentAmount = base.minInvestmentAmount
    this.currencyUnit = base.currencyUnit
    this.investmentTargetMarkets = base.investmentTargetMarkets
    this.investmentTargetRounds = base.investmentTargetRounds
    this.locations = base.locations
    this.yearsOfExperience = base.yearsOfExperience
    this.jobHuntingStatus = base.jobHuntingStatus
    this.isFulltimeEmployeeDesired = base.isFulltimeEmployeeDesired
    this.isContractorDesired = base.isContractorDesired
    this.isCofounderDesired = base.isCofounderDesired
    this.isInternDesired = base.isInternDesired
    this.primaryJobCategory = base.primaryJobCategory
    this.jobCategories = base.jobCategories
    this.skills = base.skills
    this.hasInvestmentsWithJobs = base.hasInvestmentsWithJobs
    this.desiredThingsInNextJob = base.desiredThingsInNextJob
    this.desiredCareerPath = base.desiredCareerPath
    this.desiredCommunicationStyle = base.desiredCommunicationStyle
    this.desiredDecisionCulture = base.desiredDecisionCulture
    this.desiredEvaluationCulture = base.desiredEvaluationCulture
    this.desiredManagementCulture = base.desiredManagementCulture
    this.desiredOrientationCulture = base.desiredOrientationCulture
    this.desiredProgressionStyle = base.desiredProgressionStyle
    this.desiredRoleOnTeam = base.desiredRoleOnTeam
    this.desiredTakingOnWorkStyle = base.desiredTakingOnWorkStyle
    this.desiredTrainingCulture = base.desiredTrainingCulture
    this.desiredWorkStyle = base.desiredWorkStyle
    this.desiredWorkplaceCulture = base.desiredWorkplaceCulture
    this.allowMessageRequestsFrom = base.allowMessageRequestsFrom
    this.receiveEmailNotificationsForMessages = base.receiveEmailNotificationsForMessages
    this.delayOfEmailNotificationsForMessages = base.delayOfEmailNotificationsForMessages
    this.receiveEmailNotificationsForFollower = base.receiveEmailNotificationsForFollower
    this.delayOfEmailNotificationsForFollower = base.delayOfEmailNotificationsForFollower
    this.receiveEmailNotificationsForAnswers = base.receiveEmailNotificationsForAnswers
    this.delayOfEmailNotificationsForAnswers = base.delayOfEmailNotificationsForAnswers
    this.receiveEmailNotificationsForTokenTransactions = base.receiveEmailNotificationsForTokenTransactions
    this.delayOfEmailNotificationsForTokenTransactions = base.delayOfEmailNotificationsForTokenTransactions
    this.solanaAddress = base.solanaAddress
    this.tokens = base.tokens
  }
}
