export type SaleToken = {
  version: '0.1.0'
  name: 'sale_token'
  instructions: [
    {
      name: 'initialize'
      accounts: [
        {
          name: 'owner'
          isMut: true
          isSigner: true
        },
        {
          name: 'ownerTokenAccount'
          isMut: false
          isSigner: false
        },
        {
          name: 'mint'
          isMut: false
          isSigner: false
        },
        {
          name: 'solVault'
          isMut: false
          isSigner: false
        },
        {
          name: 'vaultAuthority'
          isMut: false
          isSigner: false
        },
        {
          name: 'tokenVault'
          isMut: true
          isSigner: false
        },
        {
          name: 'salesState'
          isMut: true
          isSigner: false
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
        },
        {
          name: 'associatedTokenProgram'
          isMut: false
          isSigner: false
        }
      ]
      args: [
        {
          name: 'rate'
          type: 'u64'
        }
      ]
    },
    {
      name: 'withdrawTokens'
      accounts: [
        {
          name: 'owner'
          isMut: true
          isSigner: true
        },
        {
          name: 'ownerTokenAccount'
          isMut: true
          isSigner: false
        },
        {
          name: 'mint'
          isMut: false
          isSigner: false
        },
        {
          name: 'vaultAuthority'
          isMut: false
          isSigner: false
        },
        {
          name: 'tokenVault'
          isMut: true
          isSigner: false
        },
        {
          name: 'salesState'
          isMut: true
          isSigner: false
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
        }
      ]
      args: [
        {
          name: 'amount'
          type: 'u64'
        }
      ]
    },
    {
      name: 'withdrawAllTokens'
      accounts: [
        {
          name: 'owner'
          isMut: true
          isSigner: true
        },
        {
          name: 'ownerTokenAccount'
          isMut: true
          isSigner: false
        },
        {
          name: 'mint'
          isMut: false
          isSigner: false
        },
        {
          name: 'vaultAuthority'
          isMut: false
          isSigner: false
        },
        {
          name: 'tokenVault'
          isMut: true
          isSigner: false
        },
        {
          name: 'salesState'
          isMut: true
          isSigner: false
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
        }
      ]
      args: []
    },
    {
      name: 'withdrawSol'
      accounts: [
        {
          name: 'owner'
          isMut: true
          isSigner: true
        },
        {
          name: 'solVault'
          isMut: true
          isSigner: false
        },
        {
          name: 'salesState'
          isMut: true
          isSigner: false
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
        }
      ]
      args: [
        {
          name: 'lamports'
          type: 'u64'
        }
      ]
    },
    {
      name: 'withdrawAllSol'
      accounts: [
        {
          name: 'owner'
          isMut: true
          isSigner: true
        },
        {
          name: 'solVault'
          isMut: true
          isSigner: false
        },
        {
          name: 'salesState'
          isMut: true
          isSigner: false
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
        }
      ]
      args: []
    },
    {
      name: 'swapSolToToken'
      accounts: [
        {
          name: 'purchaser'
          isMut: true
          isSigner: true
        },
        {
          name: 'purchaserTokenAccount'
          isMut: true
          isSigner: false
        },
        {
          name: 'mint'
          isMut: false
          isSigner: false
        },
        {
          name: 'solVault'
          isMut: true
          isSigner: false
        },
        {
          name: 'vaultAuthority'
          isMut: false
          isSigner: false
        },
        {
          name: 'tokenVault'
          isMut: true
          isSigner: false
        },
        {
          name: 'salesState'
          isMut: true
          isSigner: false
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
        }
      ]
      args: [
        {
          name: 'lamports'
          type: 'u64'
        }
      ]
    },
    {
      name: 'cancel'
      accounts: [
        {
          name: 'owner'
          isMut: true
          isSigner: true
        },
        {
          name: 'ownerTokenAccount'
          isMut: true
          isSigner: false
        },
        {
          name: 'mint'
          isMut: false
          isSigner: false
        },
        {
          name: 'solVault'
          isMut: true
          isSigner: false
        },
        {
          name: 'vaultAuthority'
          isMut: false
          isSigner: false
        },
        {
          name: 'tokenVault'
          isMut: true
          isSigner: false
        },
        {
          name: 'salesState'
          isMut: true
          isSigner: false
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
        }
      ]
      args: []
    },
    {
      name: 'changeRate'
      accounts: [
        {
          name: 'owner'
          isMut: true
          isSigner: true
        },
        {
          name: 'salesState'
          isMut: true
          isSigner: false
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
        }
      ]
      args: [
        {
          name: 'rate'
          type: 'u64'
        }
      ]
    }
  ]
  accounts: [
    {
      name: 'salesAccount'
      type: {
        kind: 'struct'
        fields: [
          {
            name: 'ownerKey'
            type: 'publicKey'
          },
          {
            name: 'ownerTokenAccount'
            type: 'publicKey'
          },
          {
            name: 'solVault'
            type: 'publicKey'
          },
          {
            name: 'solVaultBump'
            type: 'u8'
          },
          {
            name: 'tokenVault'
            type: 'publicKey'
          },
          {
            name: 'vaultAuthorityBump'
            type: 'u8'
          },
          {
            name: 'rate'
            type: 'u64'
          }
        ]
      }
    }
  ]
  errors: [
    {
      code: 6000
      name: 'NotEnoughSol'
      msg: 'You do not have enough SOL'
    },
    {
      code: 6001
      name: 'NotEnoughAQA'
      msg: 'You do not have enough AQA'
    },
    {
      code: 6002
      name: 'NotEnoughSolInProgram'
      msg: 'It is not enough SOL in program'
    },
    {
      code: 6003
      name: 'NotEnoughAQAInProgram'
      msg: 'It is not enough AQA in program'
    }
  ]
}

export const IDL: SaleToken = {
  version: '0.1.0',
  name: 'sale_token',
  instructions: [
    {
      name: 'initialize',
      accounts: [
        {
          name: 'owner',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'ownerTokenAccount',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'mint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'solVault',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'vaultAuthority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenVault',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'salesState',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'associatedTokenProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'rate',
          type: 'u64',
        },
      ],
    },
    {
      name: 'withdrawTokens',
      accounts: [
        {
          name: 'owner',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'ownerTokenAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'mint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'vaultAuthority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenVault',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'salesState',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'amount',
          type: 'u64',
        },
      ],
    },
    {
      name: 'withdrawAllTokens',
      accounts: [
        {
          name: 'owner',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'ownerTokenAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'mint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'vaultAuthority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenVault',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'salesState',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'withdrawSol',
      accounts: [
        {
          name: 'owner',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'solVault',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'salesState',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'lamports',
          type: 'u64',
        },
      ],
    },
    {
      name: 'withdrawAllSol',
      accounts: [
        {
          name: 'owner',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'solVault',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'salesState',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'swapSolToToken',
      accounts: [
        {
          name: 'purchaser',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'purchaserTokenAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'mint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'solVault',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'vaultAuthority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenVault',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'salesState',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'lamports',
          type: 'u64',
        },
      ],
    },
    {
      name: 'cancel',
      accounts: [
        {
          name: 'owner',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'ownerTokenAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'mint',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'solVault',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'vaultAuthority',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenVault',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'salesState',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'changeRate',
      accounts: [
        {
          name: 'owner',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'salesState',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'rate',
          type: 'u64',
        },
      ],
    },
  ],
  accounts: [
    {
      name: 'salesAccount',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'ownerKey',
            type: 'publicKey',
          },
          {
            name: 'ownerTokenAccount',
            type: 'publicKey',
          },
          {
            name: 'solVault',
            type: 'publicKey',
          },
          {
            name: 'solVaultBump',
            type: 'u8',
          },
          {
            name: 'tokenVault',
            type: 'publicKey',
          },
          {
            name: 'vaultAuthorityBump',
            type: 'u8',
          },
          {
            name: 'rate',
            type: 'u64',
          },
        ],
      },
    },
  ],
  errors: [
    {
      code: 6000,
      name: 'NotEnoughSol',
      msg: 'You do not have enough SOL',
    },
    {
      code: 6001,
      name: 'NotEnoughAQA',
      msg: 'You do not have enough AQA',
    },
    {
      code: 6002,
      name: 'NotEnoughSolInProgram',
      msg: 'It is not enough SOL in program',
    },
    {
      code: 6003,
      name: 'NotEnoughAQAInProgram',
      msg: 'It is not enough AQA in program',
    },
  ],
}
