import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchQuestionsRequestInput,
  FetchQuestionsUseCaseOutput,
  IAppErrorFactory,
  IFetchQuestionsUseCase,
  IQuestionsService,
} from '../../types'

@injectable()
export default class FetchQuestionsInteractor implements IFetchQuestionsUseCase {
  @inject(symbols.IQuestionsService) private questionsService: IQuestionsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchQuestionsRequestInput): Promise<FetchQuestionsUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const { questions, hasNextPage } = await this.questionsService.fetchQuestions(input)

      output.questions = questions
      output.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchQuestionsUseCaseOutput {
    return {
      questions: [],
      hasNextPage: true,
      error: null,
    }
  }
}
