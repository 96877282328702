import { inject, injectable } from 'inversify'
import {
  FetchFeaturedAngelsUseCaseInput,
  FetchFeaturedAngelsUseCaseOutput,
  IAppErrorFactory,
  IFetchFeaturedAngelsUseCase,
  IUsersService,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FetchFeaturedAngelsInteractor implements IFetchFeaturedAngelsUseCase {
  @inject(symbols.IUsersService) private usersService: IUsersService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchFeaturedAngelsUseCaseInput): Promise<FetchFeaturedAngelsUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      // 現在、fetchFeaturedAngels メソッドは引数不要。しかし今後、データの取得数を指定することを考慮して、引数（input）を記述しておく。
      output.featuredAngels = await this.usersService.fetchFeaturedAngels(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchFeaturedAngelsUseCaseOutput {
    return {
      featuredAngels: [],
      error: null,
    }
  }
}
