import { NextPage } from 'next'
import { AppProps } from 'next/app'
import { observer, Provider } from 'mobx-react'
import { useEffect } from 'react'
import { NextRouter, useRouter } from 'next/router'
import { appWithTranslation } from 'next-i18next'
import { Language, OnInitializedHandler, OnInitializedHandlerInput } from '@/types'
import * as gtag from '@/lib/gtag'
import stores from '@/stores'
import '../styles/globals.scss'
import { WalletContextProvider } from '@/components/organisms/WalletContextProvider'

const MyApp: NextPage<AppProps> = observer(({ Component, pageProps }) => {
  const router = useRouter()
  const { viewer } = stores

  useEffect(() => {
    void initialize()
  }, [])

  const initialize = async () => {
    await viewer.initialize(router.locale as Language, onInitialized)
  }
  const onInitialized: OnInitializedHandler = async (input): Promise<void> => {
    await redirectIfNeeded(input, router)
  }

  // client側でのページ遷移時にpageviewが発火される
  useEffect(() => {
    const handleRouteChange = (path) => {
      gtag.pageview(path)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  // ルートだしデフォルトのコードなのでここだけ許容
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Provider stores={stores}>
      <WalletContextProvider>
        <Component {...pageProps} />
      </WalletContextProvider>
    </Provider>
  )
  /* eslint-enable react/jsx-props-no-spreading */
})

/**
 * リダイレクト処理
 *
 * @param input
 * @param router
 */
const redirectIfNeeded = async (input: OnInitializedHandlerInput, router: NextRouter): Promise<void> => {
  const pathName = router.pathname
  const { isSignedIn } = input
  // ログインしてないのにログインが必要なルートにいる場合はリダイレクト
  const isInCredentialsRequiredPaths = getWhetherViewerIsInPaths(credentialsRequiredPaths, pathName)
  if (!isSignedIn && isInCredentialsRequiredPaths) {
    await router.push('/')
  }

  // ログイン済みなのにログインページやサインアップページなどにいる場合はリダイレクト
  const isInSessionPaths = getWhetherViewerIsInPaths(noCredentialsPaths, pathName)
  if (isSignedIn && isInSessionPaths) {
    await router.push('/')
  }
}

const noCredentialsPaths = ['/signin', '/signup', '/password-recovery']
const credentialsRequiredPaths = ['/account', '/companies/new', '/messages'] // '/account' で '/companies/companySlug/account/profile' とかも対象になる
const getWhetherViewerIsInPaths = (targetPaths: string[], pathName: string) => {
  const paths = targetPaths.filter((path) => pathName.indexOf(path) !== -1)
  if (paths.length > 0) {
    return true
  }

  return false
}

export default appWithTranslation(MyApp)
