import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  AddFootprintToJobUseCaseInput,
  AddFootprintToJobUseCaseOutput,
  IAddFootprintToJobUseCase,
  IAppErrorFactory,
  IJobsService,
} from '../../types'

@injectable()
export default class AddFootprintToJobInteractor implements IAddFootprintToJobUseCase {
  @inject(symbols.IJobsService) private jobsService: IJobsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: AddFootprintToJobUseCaseInput): Promise<AddFootprintToJobUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.job = await this.jobsService.addFootprintToJob(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): AddFootprintToJobUseCaseOutput {
    return {
      job: null,
      error: null,
    }
  }
}
