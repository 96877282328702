import { action, observable } from 'mobx'
import {
  IArticleBase,
  IArticleCategory,
  IArticleTopic,
  ICompanyBase,
  IFetchArticleUseCase,
  IFundraisingManualBase,
  IPublicArticle,
  IToggleArticleLikedStateUseCase,
  IUserBase,
} from '@/types'

type Dependencies = {
  fetchArticleUseCase: IFetchArticleUseCase
  toggleArticleLikedStateUseCase: IToggleArticleLikedStateUseCase
}

export default class PublicArticle implements IPublicArticle {
  @observable id = ''

  @observable title = ''

  @observable slug = ''

  @observable content = ''

  @observable description = ''

  @observable ogImage = ''

  @observable status = null

  @observable thumbnail = ''

  @observable createdAt = ''

  @observable updatedAt = ''

  @observable originalArticleUrl = ''

  @observable originalSiteName = ''

  @observable category: IArticleCategory

  @observable topics: IArticleTopic[] = []

  @observable company: ICompanyBase

  @observable fundraisingManualFields: IFundraisingManualBase

  @observable isLiked = false

  @observable isDraftLiked = false

  @observable draftIsLiked = false

  @observable likedUsersCount = 0

  @observable opinionsUser: IUserBase

  toggleArticleLikedStateUseCase!: IToggleArticleLikedStateUseCase

  fetchArticleUseCase!: IFetchArticleUseCase

  @action
  _mapFromBase(base: IArticleBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  @action
  update(base: IArticleBase): void {
    this._mapFromBase(base)
  }

  constructor(base: IArticleBase, deps: Dependencies) {
    this._mapFromBase(base)
    this.fetchArticleUseCase = deps.fetchArticleUseCase
    this.toggleArticleLikedStateUseCase = deps.toggleArticleLikedStateUseCase
  }

  @action
  draftToggleArticleLikedState(): void {
    this.isDraftLiked = !this.isLiked
  }

  async toggleArticleLikedState(): Promise<boolean> {
    const output = await this.toggleArticleLikedStateUseCase.handle({ slug: this.slug })

    if (output.article) {
      this.update(output.article)
      return true
    }

    return false
  }

  async fetch(): Promise<boolean> {
    const output = await this.fetchArticleUseCase.handle({ slug: this.slug })

    if (output.article) {
      this.update(output.article)
      return true
    }

    return false
  }
}
