import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  ApplyForJobUseCaseInput,
  ApplyForJobUseCaseOutput,
  IAppErrorFactory,
  IApplyForJobUseCase,
  IJobApplicationFactory,
  IJobApplicationsService,
} from '../../types'

@injectable()
export default class ApplyForJobInteractor implements IApplyForJobUseCase {
  @inject(symbols.IJobApplicationsService) private jobApplicationsService: IJobApplicationsService

  @inject(symbols.IJobApplicationFactory) private jobApplicationFactory: IJobApplicationFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: ApplyForJobUseCaseInput): Promise<ApplyForJobUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const base = await this.jobApplicationsService.applyForJob(input)
      output.jobApplication = this.jobApplicationFactory.create({ base })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: '応募に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): ApplyForJobUseCaseOutput {
    return {
      jobApplication: null,
      error: null,
    }
  }
}
