import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  IAppErrorFactory,
  IOffersService,
  IOfferBase,
  IUseCaseOutputFactory,
  IUpdateMyOfferUseCase,
  UpdateMyOfferUseCaseInput,
  UpdateMyOfferUseCaseOutput,
} from '../../types'

@injectable()
export default class UpdateMyOfferInteractor implements IUpdateMyOfferUseCase {
  @inject(symbols.IOffersService) private offersService: IOffersService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    offer: null | IOfferBase
  }>

  async handle(input: UpdateMyOfferUseCaseInput): Promise<UpdateMyOfferUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        offer: null,
      },
    })
    try {
      output.data.offer = await this.offersService.updateMyOffer(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
