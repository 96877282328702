import { inject, injectable } from 'inversify'
import {
  DeleteMyQuestionUseCaseInput,
  DeleteMyQuestionUseCaseOutput,
  IAppErrorFactory,
  IQuestionsService,
  IDeleteMyQuestionUseCase,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class DeleteMyQuestionInteractor implements IDeleteMyQuestionUseCase {
  @inject(symbols.IQuestionsService) private questionsService: IQuestionsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: DeleteMyQuestionUseCaseInput): Promise<DeleteMyQuestionUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.question = await this.questionsService.deleteMyQuestion({
        id: input.id,
      })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): DeleteMyQuestionUseCaseOutput {
    return {
      question: null,
      error: null,
    }
  }
}
