import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  IChatService,
  IMarkAllMessagesAsReadUseCase,
  MarkAllMessagesAsReadUseCaseInput,
  MarkAllMessagesAsReadUseCaseOutput,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class MarkAllMessagesAsReadInteractor implements IMarkAllMessagesAsReadUseCase {
  @inject(symbols.IChatService) private chatService: IChatService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: MarkAllMessagesAsReadUseCaseInput): Promise<MarkAllMessagesAsReadUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      await this.chatService.markAllMessagesAsRead(input)
      output.isSuccessful = true
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): MarkAllMessagesAsReadUseCaseOutput {
    return {
      isSuccessful: false,
      error: null,
    }
  }
}
