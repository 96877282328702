import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchJobBookmarksRequestInput,
  FetchJobBookmarksUseCaseOutput,
  IAppErrorFactory,
  IFetchJobBookmarksUseCase,
  IJobBookmarksService,
} from '../../types'

@injectable()
export default class FetchJobBookmarksInteractor implements IFetchJobBookmarksUseCase {
  @inject(symbols.IJobBookmarksService) private jobBookmarksService: IJobBookmarksService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchJobBookmarksRequestInput): Promise<FetchJobBookmarksUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const { jobBookmarks, hasNextPage } = await this.jobBookmarksService.fetchJobBookmarks(input)

      output.jobBookmarks = jobBookmarks
      output.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchJobBookmarksUseCaseOutput {
    return {
      jobBookmarks: [],
      hasNextPage: true,
      error: null,
    }
  }
}
