import { action, observable } from 'mobx'
import remotedev, { RemoteDevConfig } from 'mobx-remotedev'
import { inject, injectable } from 'inversify'
import {
  IErrorsStore,
  IFetchUserOffersUseCase,
  IFetchOffersUseCase,
  IFetchOfferUseCase,
  IOfferBase,
  IOffersStore,
  FetchOffersRequestInput,
  FetchUserOffersStoreInput,
  FetchOfferStoreInput,
} from '@/types'
import symbols from '../symbols'

const remoteDevConfig: RemoteDevConfig = {
  name: 'OffersStore',
  global: true,
  remote: false,
}

@remotedev(remoteDevConfig)
@injectable()
export default class OffersStore implements IOffersStore {
  @observable offers: IOfferBase[] = []

  @observable hasNextOffersPage = true

  @observable userOffers: Record<string, IOfferBase[]> = {}

  @observable hasNextUserOffersPage: Record<string, boolean> = {}

  constructor(
    @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore,
    @inject(symbols.IFetchOffersUseCase) private fetchOffersUseCase: IFetchOffersUseCase,
    @inject(symbols.IFetchUserOffersUseCase) private fetchUserOffersUseCase: IFetchUserOffersUseCase,
    @inject(symbols.IFetchOfferUseCase) private fetchOfferUseCase: IFetchOfferUseCase
  ) {
    //
  }

  // =========== offers ===========
  @action
  _updateOffers(offers: IOfferBase[]): void {
    this.offers = offers
  }

  @action
  _addOffers(offers: IOfferBase[]): void {
    offers.forEach((newOffer) => {
      // 重複していたら処理をスキップ
      if (this.offers.some((o) => o.slug === newOffer.slug)) {
        return
      }

      // 末尾に追加
      this.offers = this.offers.concat(newOffer)
    })
  }

  @action
  updateHasNextOffersPage(hasNextPage: boolean): void {
    this.hasNextOffersPage = hasNextPage
  }
  // =========== offers ===========

  // =========== userOffersIndexPage ===========
  @action
  _addUserOffers(slug: string, offers: IOfferBase[]): void {
    offers.forEach((newOffer) => {
      if (this.userOffers[slug].some((j) => j.slug === newOffer.slug)) {
        return
      }

      this.userOffers[slug] = this.userOffers[slug].concat(newOffer)
    })
  }

  @action
  _updateUserOffers(slug: string, offers: IOfferBase[]): void {
    this.userOffers[slug] = offers
  }

  @action
  updateHasNextUserOffersPage(slug: string, hasNextPage: boolean): void {
    this.hasNextUserOffersPage[slug] = hasNextPage
  }

  async fetchOffers(input: FetchOffersRequestInput): Promise<IOfferBase[]> {
    const output = await this.fetchOffersUseCase.handle(input)
    if (output.error) {
      this.errorsStore.handle(output.error)

      return []
    }

    if (input.shouldRefresh) {
      this._updateOffers(output.offers)
    } else {
      this._addOffers(output.offers)
    }
    this.updateHasNextOffersPage(output.hasNextPage)

    return output.offers
  }

  async fetchUserOffers(input: FetchUserOffersStoreInput): Promise<IOfferBase[]> {
    const output = await this.fetchUserOffersUseCase.handle(input)

    if (output.error) {
      this.errorsStore.handle(output.error)

      return []
    }

    if (input.shouldRefresh) {
      this._updateUserOffers(input.username, output.offers)
    } else {
      this._addUserOffers(input.username, output.offers)
    }

    this.updateHasNextUserOffersPage(input.username, output.hasNextPage)
    return output.offers
  }

  async fetchOffer(input: FetchOfferStoreInput): Promise<IOfferBase> {
    const output = await this.fetchOfferUseCase.handle(input)
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output.offer
  }
}
