import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchCompanyForViewerUseCaseInput,
  FetchCompanyForViewerUseCaseOutput,
  IAppErrorFactory,
  ICompaniesService,
  ICompanyBase,
  IFetchCompanyForViewerUseCase,
  IUseCaseOutputFactory,
} from '../../types'

@injectable()
export default class FetchCompanyForViewerInteractor implements IFetchCompanyForViewerUseCase {
  @inject(symbols.ICompaniesService) private companiesService: ICompaniesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{ company: null | ICompanyBase }>

  async handle(input: FetchCompanyForViewerUseCaseInput): Promise<FetchCompanyForViewerUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        company: null,
      },
    })
    try {
      output.data.company = await this.companiesService.fetchCompanyForViewer(input.slug)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
