import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchBlockchainsUseCaseInput,
  FetchBlockchainsUseCaseOutput,
  IAppErrorFactory,
  IFetchBlockchainsUseCase,
  IBlockchainsService,
} from '../../types'

@injectable()
export default class FetchBlockchainsInteractor implements IFetchBlockchainsUseCase {
  @inject(symbols.IBlockchainsService) private blockchainService: IBlockchainsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchBlockchainsUseCaseInput): Promise<FetchBlockchainsUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.blockchains = await this.blockchainService.fetchBlockchains(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchBlockchainsUseCaseOutput {
    return {
      blockchains: [],
      error: null,
    }
  }
}
