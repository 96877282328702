import { inject, injectable } from 'inversify'
import {
  FetchFeaturedCompaniesWithJobsUseCaseInput,
  FetchFeaturedCompaniesWithJobsUseCaseOutput,
  IAppErrorFactory,
  ICompaniesService,
  IFetchFeaturedCompaniesWithJobsUseCase,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FetchFeaturedCompaniesWithJobsInteractor implements IFetchFeaturedCompaniesWithJobsUseCase {
  @inject(symbols.ICompaniesService) private companiesService: ICompaniesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(
    input: FetchFeaturedCompaniesWithJobsUseCaseInput
  ): Promise<FetchFeaturedCompaniesWithJobsUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      // 現在、fetchFeaturedCompaniesWithJobs メソッドは引数不要。しかし今後、データの取得数を指定することを考慮して、引数（input）を記述しておく。
      output.companiesWithJobs = await this.companiesService.fetchFeaturedCompaniesWithJobs(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchFeaturedCompaniesWithJobsUseCaseOutput {
    return {
      companiesWithJobs: [],
      error: null,
    }
  }
}
