import { action, observable } from 'mobx'
import remotedev, { RemoteDevConfig } from 'mobx-remotedev'
import { inject, injectable } from 'inversify'
import {
  IErrorsStore,
  IFetchQuestionsUseCase,
  IFetchQuestionUseCase,
  IQuestionBase,
  IQuestionsStore,
  FetchQuestionsRequestInput,
  FetchQuestionStoreInput,
  IFetchQuestionCategoriesUseCase,
  IQuestionCategoryBase,
  IAnswerBase,
  FetchReplyStoreInput,
  IFetchReplyUseCase,
  IUserQuestion,
  IUserQuestionFactory,
} from '@/types'
import symbols from '../symbols'

const remoteDevConfig: RemoteDevConfig = {
  name: 'QuestionsStore',
  global: true,
  remote: false,
}

@remotedev(remoteDevConfig)
@injectable()
export default class QuestionsStore implements IQuestionsStore {
  @observable questionCategories: IQuestionCategoryBase[] = []

  @observable questions: IQuestionBase[] = []

  @observable hasNextQuestionsPage = true

  @observable categorizedQuestions: Record<string, IQuestionBase[]> = {}

  @observable hasNextUserQuestionsPage: Record<string, boolean> = {}

  @observable hasNextCategorizedQuestionsPage: Record<string, boolean> = {}

  @observable userQuestions: Record<string, IUserQuestion> = {}

  constructor(
    @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore,
    @inject(symbols.IFetchQuestionCategoriesUseCase)
    private fetchQuestionCategoriesUseCase: IFetchQuestionCategoriesUseCase,
    @inject(symbols.IFetchQuestionsUseCase) private fetchQuestionsUseCase: IFetchQuestionsUseCase,
    @inject(symbols.IFetchQuestionUseCase) private fetchQuestionUseCase: IFetchQuestionUseCase,
    @inject(symbols.IFetchReplyUseCase) private fetchReplyUseCase: IFetchReplyUseCase,
    @inject(symbols.IUserQuestionFactory) private userQuestionFactory: IUserQuestionFactory
  ) {
    //
  }

  // =========== questionCategories ===========
  @action
  _updateQuestionCategories(questionCategories: IQuestionCategoryBase[]): void {
    this.questionCategories = questionCategories
  }
  // =========== questionCategories ===========

  // =========== questions ===========
  @action
  _updateQuestions(questions: IQuestionBase[]): void {
    this.questions = questions
  }

  @action
  _addQuestions(questions: IQuestionBase[]): void {
    questions.forEach((newQuestion) => {
      // 重複していたら処理をスキップ
      if (this.questions.some((o) => o.slug === newQuestion.slug)) {
        return
      }

      // 末尾に追加
      this.questions = this.questions.concat(newQuestion)
    })
  }

  @action
  updateHasNextQuestionsPage(hasNextPage: boolean): void {
    this.hasNextQuestionsPage = hasNextPage
  }

  @action
  _updateCategorizedQuestions(slug: string, questions: IQuestionBase[]): void {
    this.categorizedQuestions[slug] = questions
  }

  @action
  _addCategorizedQuestions(slug: string, questions: IQuestionBase[]): void {
    questions.forEach((newQuestion) => {
      // 重複していたら処理をスキップ
      if (this.categorizedQuestions[slug].some((q) => q.slug === newQuestion.slug)) {
        return
      }

      // 末尾に追加
      this.categorizedQuestions[slug] = this.categorizedQuestions[slug].concat(newQuestion)
    })
  }

  @action
  updateHasNextCategorizedQuestionsPage(slug: string, hasNextPage: boolean): void {
    this.hasNextCategorizedQuestionsPage[slug] = hasNextPage
  }
  // =========== questions ===========

  async fetchQuestionCategories(): Promise<void> {
    const output = await this.fetchQuestionCategoriesUseCase.handle()

    if (output.error) {
      this.errorsStore.handle(output.error)
      return
    }

    this._updateQuestionCategories(output.questionCategories)
  }

  async fetchQuestions(input: FetchQuestionsRequestInput): Promise<IQuestionBase[]> {
    const output = await this.fetchQuestionsUseCase.handle(input)
    if (output.error) {
      this.errorsStore.handle(output.error)

      return []
    }

    if (input.shouldRefresh) {
      this._updateQuestions(output.questions)
    } else {
      this._addQuestions(output.questions)
    }
    this.updateHasNextQuestionsPage(output.hasNextPage)

    return output.questions
  }

  async fetchCategorizedQuestions(input: FetchQuestionsRequestInput): Promise<IQuestionBase[]> {
    const output = await this.fetchQuestionsUseCase.handle(input)
    if (output.error) {
      this.errorsStore.handle(output.error)

      return []
    }

    // Mobx の warning 解消のため questionCategories の配列を確認
    if (input.shouldRefresh && input.questionCategories.length > 0) {
      this._updateCategorizedQuestions(input.questionCategories[0], output.questions)
    } else {
      this._addCategorizedQuestions(input.questionCategories[0], output.questions)
    }
    this.updateHasNextCategorizedQuestionsPage(input.questionCategories[0], output.hasNextPage)

    return output.questions
  }

  async fetchQuestion(input: FetchQuestionStoreInput): Promise<IQuestionBase> {
    const output = await this.fetchQuestionUseCase.handle(input)
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output.question
  }

  async fetchReply(input: FetchReplyStoreInput): Promise<IAnswerBase> {
    const output = await this.fetchReplyUseCase.handle(input)
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output.reply
  }

  // 質問の Base を受け取って Instance にした上で Store で管理する
  createQuestionInstance(base: IQuestionBase): IUserQuestion {
    if (!base) {
      return null
    }

    if (this.userQuestions[base?.slug]) {
      return this.userQuestions[base?.slug]
    }

    const instance = this.userQuestionFactory.create({ base })
    this.userQuestions[instance.slug] = instance

    return instance
  }
}
