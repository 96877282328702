import { inject, injectable } from 'inversify'
import {
  FetchCompanyJobsUseCaseInput,
  FetchCompanyJobsUseCaseOutput,
  IAppErrorFactory,
  IFetchCompanyJobsUseCase,
  IJobsService,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FetchCompanyJobsInteractor implements IFetchCompanyJobsUseCase {
  @inject(symbols.IJobsService) private jobService: IJobsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchCompanyJobsUseCaseInput): Promise<FetchCompanyJobsUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const { jobs, hasNextPage } = await this.jobService.fetchCompanyJobs(input)

      output.jobs = jobs
      output.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchCompanyJobsUseCaseOutput {
    return {
      jobs: [],
      hasNextPage: true,
      error: null,
    }
  }
}
