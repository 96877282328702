import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  DuplicateJobUseCaseInput,
  DuplicateJobUseCaseOutput,
  IAppErrorFactory,
  IDuplicateJobUseCase,
  IJobFactory,
  IJobsService,
} from '../../types'

@injectable()
export default class DuplicateJobInteractor implements IDuplicateJobUseCase {
  @inject(symbols.IJobsService) private jobsService: IJobsService

  @inject(symbols.IJobFactory) private jobFactory: IJobFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: DuplicateJobUseCaseInput): Promise<DuplicateJobUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const base = await this.jobsService.duplicateJob(input)
      output.job = this.jobFactory.create({ base })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: '募集の複製に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): DuplicateJobUseCaseOutput {
    return {
      job: null,
      error: null,
    }
  }
}
