import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  CreateJobAttachmentRequestInput,
  CreateJobAttachmentUseCaseOutput,
  IAppErrorFactory,
  ICreateJobAttachmentUseCase,
  IJobsService,
} from '../../types'

@injectable()
export default class CreateJobAttachmentInteractor implements ICreateJobAttachmentUseCase {
  @inject(symbols.IJobsService) private jobService: IJobsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: CreateJobAttachmentRequestInput): Promise<CreateJobAttachmentUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.jobAttachment = await this.jobService.createJobAttachment(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: '画像のアップロードに失敗しました。1MB以下のファイルを選択してください。',
      })
    }

    return output
  }

  private getDefaultOutputData(): CreateJobAttachmentUseCaseOutput {
    return {
      jobAttachment: null,
      error: null,
    }
  }
}
