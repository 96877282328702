import { action, computed, observable } from 'mobx'
import {
  ArticleConnection,
  CompanyMemberRole,
  CompanyReferenceStatus,
  CompanySize,
  CreateJobUseCaseOutput,
  CurrencyUnit,
  FetchJobFootprintsUseCaseInput,
  FetchJobFootprintsUseCaseOutput,
  FetchMyCompanyArticlesUseCaseOutput,
  FetchMyJobsUseCaseInput,
  FetchMyJobsUseCaseOutput,
  IAddAngelInvitationUseCase,
  IAddCompanyInvestmentUseCase,
  IAddCompanyMemberUseCase,
  IAddInvestorUseCase,
  IAddMemberInvitationUseCase,
  IAddProductUseCase,
  IAddVcInvestmentInvitationUseCase,
  IAddVcInvitationUseCase,
  IAngelInvitationInputBase,
  IArticleAttachmentBase,
  IArticleAttachmentInputBase,
  IArticleBase,
  IArticleInputBase,
  ICompanyArticle,
  ICompanyInputBase,
  ICompanyMemberInputBase,
  ICreateArticleAttachmentUseCase,
  ICreateCompanyArticleUseCase,
  ICreateJobAttachmentUseCase,
  ICreateJobUseCase,
  IDeleteArticleUseCase,
  IDeleteCompanyArticleUseCase,
  IDeleteJobUseCase,
  IDuplicateJobUseCase,
  IErrorsStore,
  IFetchJobBookmarksUseCase,
  IFetchJobFootprintsUseCase,
  IFetchMyCompanyArticlesUseCase,
  IFetchMyCompanyReferencesUseCase,
  IFetchMyJobsUseCase,
  IInvestment,
  IInvestmentInputBase,
  IInvestorInputBase,
  IJob,
  IJobAttachmentBase,
  IJobAttachmentInputBase,
  IJobBase,
  IJobBookmarkBase,
  IJobFootprint,
  IJobFootprintBase,
  IJobInputBase,
  ILocationBase,
  IMarketBase,
  IMemberInvitationInputBase,
  IMyCompany,
  IMyCompanyBase,
  IMyCompanyMember,
  IMyCompanyReference,
  IProduct,
  IProductBase,
  IProductInputBase,
  IRemoveCompanyMemberUseCase,
  IRemoveInvestmentUseCase,
  IRemoveInvestorUseCase,
  IRemoveProductUseCase,
  IRound,
  IRoundBase,
  IUpdateCompanyUseCase,
  IUser,
  IVcInvestmentInvitationInputBase,
  IVcInvitationInputBase,
  JobConnection,
} from '@/types'

export default class Company implements IMyCompany {
  @observable id = ''

  @observable logo = ''

  @observable headerImage = ''

  @observable aboutUs = ''

  @observable conceptPitch = ''

  @observable denyNewContact = false

  @observable isVc = false

  @observable facebookUrl = ''

  @observable instagramUrl = ''

  @observable linkedinUrl = ''

  @observable twitterUrl = ''

  @observable youtubeUrl = ''

  @observable telegramUrl = ''

  @observable openseaUrl = ''

  @observable discordUrl = ''

  @observable name = ''

  @observable slug = ''

  @observable url = ''

  @observable companySize: CompanySize

  @observable maxInvestmentAmount: string

  @observable minInvestmentAmount: string

  @observable currencyUnit: CurrencyUnit

  @observable round: IRound

  @observable products: IProduct[] = []

  @observable investments: IInvestment[] = []

  @observable companyMembers: IMyCompanyMember[] = []

  @observable allArticles: ICompanyArticle[] = []

  @observable markets: IMarketBase[] = []

  @observable receivedInvestments: IInvestment[] = []

  @observable investors: IUser[] = []

  @observable investmentTargetMarkets: IMarketBase[] = []

  @observable investmentTargetRounds: IRoundBase[] = []

  @observable locations: ILocationBase[] = []

  @observable excludeFromSearch = false

  @observable allJobs: IJob[] = []

  // 現在使われないけど、今後使うかもしれないので、残しておく
  @observable allJobFootprints: IJobFootprintBase[] = []

  @observable uniqueJobFootprints: IJobFootprint[] = []

  @observable allJobBookmarks: IJobBookmarkBase[] = []

  @observable status = null

  @observable totalFundingAmount = '0'

  @observable hasInvestmentsWithJobs = false

  @observable myCompanyReferences: IMyCompanyReference[] = []

  @observable jobApplicationsCount = 0

  @observable jobBookmarksCount = 0

  @observable jobFootprintsCount = 0

  @observable hasNextMyCompanyArticlesPage = true

  @observable articlesEndCursor = ''

  @observable hasNextMyJobsPage = true

  @observable hasNextJobFootprintsPage = true

  // 基本使わないけど Base からマップする用の箱だけ用意しておく
  articles: ArticleConnection = null

  jobs: JobConnection = null

  errorsStore: IErrorsStore

  updateCompanyUseCase: IUpdateCompanyUseCase

  addProductUseCase: IAddProductUseCase

  removeProductUseCase: IRemoveProductUseCase

  addCompanyInvestmentUseCase: IAddCompanyInvestmentUseCase

  addInvestorUseCase: IAddInvestorUseCase

  removeInvestmentUseCase: IRemoveInvestmentUseCase

  removeInvestorUseCase: IRemoveInvestorUseCase

  addCompanyMemberUseCase: IAddCompanyMemberUseCase

  removeCompanyMemberUseCase: IRemoveCompanyMemberUseCase

  createCompanyArticleUseCase: ICreateCompanyArticleUseCase

  deleteArticleUseCase: IDeleteArticleUseCase

  deleteCompanyArticleUseCase: IDeleteCompanyArticleUseCase

  createArticleAttachmentUseCase: ICreateArticleAttachmentUseCase

  fetchMyJobsUseCase: IFetchMyJobsUseCase

  createJobUseCase: ICreateJobUseCase

  duplicateJobUseCase: IDuplicateJobUseCase

  deleteJobUseCase: IDeleteJobUseCase

  createJobAttachmentUseCase: ICreateJobAttachmentUseCase

  fetchJobBookmarksUseCase: IFetchJobBookmarksUseCase

  fetchJobFootprintsUseCase: IFetchJobFootprintsUseCase

  fetchMyCompanyReferencesUseCase: IFetchMyCompanyReferencesUseCase

  addAngelInvitationUseCase: IAddAngelInvitationUseCase

  addVcInvitationUseCase: IAddVcInvitationUseCase

  addVcInvestmentInvitationUseCase: IAddVcInvestmentInvitationUseCase

  addMemberInvitationUseCase: IAddMemberInvitationUseCase

  fetchMyCompanyArticlesUseCase: IFetchMyCompanyArticlesUseCase

  constructor(base: IMyCompanyBase, investments: IInvestment[]) {
    this._mapBase(base)
    this._mapInitialArticlesPageInfo()
    this.investments = investments
  }

  @action
  _mapBase(base: IMyCompanyBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  @action
  _mapInitialArticlesPageInfo(): void {
    this.articlesEndCursor = this.articles?.pageInfo.endCursor
    this.hasNextMyCompanyArticlesPage = this.articles?.pageInfo.hasNextPage
  }

  @action
  _addProduct(product: IProduct): void {
    this.products.push(product)
  }

  @action
  _removeProduct(product: IProductBase): void {
    this.products = this.products.filter((p) => p.id !== product.id)
  }

  @action
  _addInvestment(investment: IInvestment): void {
    this.investments.push(investment)
  }

  @action
  _addInvestor(investment: IInvestment): void {
    this.receivedInvestments.push(investment)
  }

  @action
  _removeInvestment(investment: IInvestment): void {
    this.investments = this.investments.filter((i) => i.id !== investment.id)
  }

  @action
  _removeInvestor(investment: IInvestment): void {
    this.receivedInvestments = this.receivedInvestments.filter((i) => i.id !== investment.id)
  }

  @action
  _addMember(companyMember: IMyCompanyMember): void {
    this.companyMembers.push(companyMember)
  }

  @action
  _removeMember(companyMember: IMyCompanyMember): void {
    this.companyMembers = this.companyMembers.filter((m) => m.id !== companyMember.id)
  }

  @action
  _addCompanyArticles(articles: ICompanyArticle[]): void {
    articles.forEach((newArticle) => {
      // 重複していたら処理をスキップ
      if (this.allArticles.some((a) => a.slug === newArticle.slug)) {
        return
      }

      // 末尾に追加
      this.allArticles = this.allArticles.concat(newArticle)
    })
  }

  @action
  _addCompanyArticle(article: ICompanyArticle): void {
    this.allArticles.push(article)
  }

  @action
  _updateCompanyArticles(articles: ICompanyArticle[]): void {
    this.allArticles = articles
  }

  @action
  _removeArticle(article: IArticleBase): void {
    this.allArticles = this.allArticles.filter((a) => a.id !== article.id)
  }

  @action
  _updateJobFootprints(jobFootprints: IJobFootprintBase[]): void {
    this.allJobFootprints = jobFootprints
  }

  @action
  _addUniqueJobFootprints(jobFootprints: IJobFootprint[]): void {
    jobFootprints.forEach((newJobFootprint) => {
      // 重複していたら処理をスキップ
      if (this.uniqueJobFootprints.some((u) => u.id === newJobFootprint.id)) {
        return
      }

      // 末尾に追加
      this.uniqueJobFootprints = this.uniqueJobFootprints.concat(newJobFootprint)
    })
  }

  @action
  _updateUniqueJobFootprints(jobFootprints: IJobFootprint[]): void {
    this.uniqueJobFootprints = jobFootprints
  }

  @action
  _addJobs(jobs: IJob[]): void {
    jobs.forEach((newJob) => {
      // 重複していたら処理をスキップ
      if (this.allJobs.some((j) => j.slug === newJob.slug)) {
        return
      }

      // 末尾に追加
      this.allJobs = this.allJobs.concat(newJob)
    })
  }

  @action
  _addJob(job: IJob): void {
    this.allJobs.push(job)
  }

  @action
  _updateJobs(jobs: IJob[]): void {
    this.allJobs = jobs
  }

  @action
  _deleteJob(job: IJobBase): void {
    this.allJobs = this.allJobs.filter((j) => j.id !== job.id)
  }

  @action
  _updateJobBookmarks(jobBookmarks: IJobBookmarkBase[]): void {
    this.allJobBookmarks = jobBookmarks
  }

  // =========== myCompanyReferences ===========
  @action
  _updateMyCompanyReferences(myCompanyReferences: IMyCompanyReference[]): void {
    this.myCompanyReferences = myCompanyReferences
  }

  @computed
  get shownStatusReferences(): IMyCompanyReference[] {
    return this.myCompanyReferences.filter((r) => r.status === CompanyReferenceStatus.SHOWN)
  }

  @computed
  get hiddenStatusReferences(): IMyCompanyReference[] {
    return this.myCompanyReferences.filter((r) => r.status === CompanyReferenceStatus.HIDDEN)
  }

  @action
  updateHasNextMyCompanyArticlesPage(hasNextPage: boolean): void {
    this.hasNextMyCompanyArticlesPage = hasNextPage
  }

  @action
  updateArticlesEndCursor(endCursor: string): void {
    this.articlesEndCursor = endCursor
  }

  @action
  updateHasNextMyJobsPage(hasNextPage: boolean): void {
    this.hasNextMyJobsPage = hasNextPage
  }

  @action
  updateHasNextJobFootprintsPage(hasNextPage: boolean): void {
    this.hasNextJobFootprintsPage = hasNextPage
  }

  @computed
  get ownerAndRecruiters(): IMyCompanyMember[] {
    return this.companyMembers.filter((companyMember) => companyMember.role !== CompanyMemberRole.EDITOR)
  }

  @computed
  get hasSNSAccount(): boolean {
    if (
      this.facebookUrl ||
      this.instagramUrl ||
      this.linkedinUrl ||
      this.twitterUrl ||
      this.telegramUrl ||
      this.openseaUrl ||
      this.discordUrl
    ) {
      return true
    }
    return false
  }

  @computed
  get basicProfileCompletionRate(): number {
    let count = 0
    if (this.logo) {
      count++
    }
    if (this.conceptPitch) {
      count++
    }
    if (this.aboutUs) {
      count++
    }
    if (this.url) {
      count++
    }
    if (this.locations.length > 0) {
      count++
    }
    if (!(this.companySize === CompanySize.UNKNOWN)) {
      count++
    }
    if (this.hasSNSAccount) {
      count++
    }
    return Math.floor((count * 100) / 7)
  }

  @computed
  get startupProfileCompletionRate(): number {
    let count = 0
    if (this.markets.length > 0) {
      count++
    }
    if (!(this.round.slug === 'unknown')) {
      count++
    }
    if (this.products.length > 0) {
      count++
    }
    if (this.receivedInvestments.length > 0) {
      count++
    }
    return Math.floor((count * 100) / 4)
  }

  @computed
  get vcProfileCompletionRate(): number {
    let count = 0
    if (this.minInvestmentAmount && this.maxInvestmentAmount) {
      count++
    }
    if (this.investmentTargetRounds.length > 0) {
      count++
    }
    if (this.investmentTargetMarkets.length > 0) {
      count++
    }
    if (this.investments.length > 0) {
      count++
    }
    return Math.floor((count * 100) / 4)
  }

  @computed
  get companyProfileCompletionRate(): number {
    if (this.isVc) {
      return Math.floor((this.basicProfileCompletionRate + this.vcProfileCompletionRate) / 2)
    }
    return Math.floor((this.basicProfileCompletionRate + this.startupProfileCompletionRate) / 2)
  }

  async addProduct(product: IProductInputBase): Promise<boolean> {
    const output = await this.addProductUseCase.handle({
      product,
      companyId: this.id,
    })

    if (output.product) {
      this._addProduct(output.product)
      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async removeProduct(product: IProduct): Promise<boolean> {
    const output = await this.removeProductUseCase.handle({
      id: product.id,
    })

    if (output.product) {
      this._removeProduct(output.product)
      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async save(company: ICompanyInputBase): Promise<boolean> {
    const output = await this.updateCompanyUseCase.handle({
      company,
      id: this.id,
    })

    if (output.company) {
      this._mapBase(output.company)
      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async addCompanyInvestment(investment: IInvestmentInputBase): Promise<boolean> {
    const output = await this.addCompanyInvestmentUseCase.handle({
      investment,
      investingCompanyId: this.id,
    })
    if (output.investment) {
      this._addInvestment(output.investment)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async addInvestor(investment: IInvestorInputBase): Promise<boolean> {
    const output = await this.addInvestorUseCase.handle({
      investment,
    })
    if (output.investment) {
      this._addInvestor(output.investment)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async removeInvestment(investment: IInvestment): Promise<boolean> {
    const output = await this.removeInvestmentUseCase.handle({
      id: investment.id,
    })
    if (output.investment) {
      this._removeInvestment(investment)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async removeInvestor(investment: IInvestment): Promise<boolean> {
    const output = await this.removeInvestorUseCase.handle({
      id: investment.id,
    })
    if (output.investment) {
      this._removeInvestor(investment)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async addMember(companyMember: ICompanyMemberInputBase): Promise<boolean> {
    const output = await this.addCompanyMemberUseCase.handle({
      companyId: this.id,
      role: companyMember.role,
      userId: companyMember.userId,
    })
    if (output.companyMember) {
      this._addMember(output.companyMember)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async removeMember(companyMember: IMyCompanyMember): Promise<boolean> {
    const output = await this.removeCompanyMemberUseCase.handle({
      id: companyMember.id,
    })
    if (output.companyMember) {
      this._removeMember(companyMember)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async addCompanyArticle(article: IArticleInputBase): Promise<boolean> {
    const output = await this.createCompanyArticleUseCase.handle({ companyId: this.id, article })

    if (output.article) {
      this._addCompanyArticle(output.article)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async removeArticle(article: IArticleBase): Promise<boolean> {
    const output = await this.deleteArticleUseCase.handle({ id: article.id })

    if (output.article) {
      this._removeArticle(output.article)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async removeCompanyArticle(id: string): Promise<boolean> {
    const output = await this.deleteCompanyArticleUseCase.handle({ id })

    if (output.article) {
      this._removeArticle(output.article)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async addArticleAttachment(input: IArticleAttachmentInputBase): Promise<IArticleAttachmentBase> {
    const output = await this.createArticleAttachmentUseCase.handle({ articleAttachment: input })

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return output.articleAttachment
  }

  async fetchMyJobs(input: FetchMyJobsUseCaseInput): Promise<FetchMyJobsUseCaseOutput> {
    const output = await this.fetchMyJobsUseCase.handle({
      companySlug: this.slug,
      shouldRefresh: input.shouldRefresh,
      limit: input.limit,
    })

    if (input.shouldRefresh) {
      this._updateJobs(output.data.jobs)
    } else {
      this._addJobs(output.data.jobs)
    }

    this.updateHasNextMyJobsPage(output.data.hasNextPage)

    return output
  }

  async createJob(job: IJobInputBase): Promise<CreateJobUseCaseOutput> {
    const output = await this.createJobUseCase.handle({ companyId: this.id, job })

    if (output.data.job) {
      this._addJob(output.data.job)
    }

    return output
  }

  async duplicateJob(jobId: string): Promise<boolean> {
    const output = await this.duplicateJobUseCase.handle({ id: jobId })

    if (output.job) {
      this._addJob(output.job)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async deleteJob(jobId: string): Promise<boolean> {
    const output = await this.deleteJobUseCase.handle({ id: jobId })

    if (output.job) {
      this._deleteJob(output.job)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async addJobAttachment(input: IJobAttachmentInputBase): Promise<IJobAttachmentBase> {
    const output = await this.createJobAttachmentUseCase.handle({ jobAttachment: input })

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return output.jobAttachment
  }

  async fetchJobFootprints(input: FetchJobFootprintsUseCaseInput): Promise<FetchJobFootprintsUseCaseOutput> {
    const output = await this.fetchJobFootprintsUseCase.handle({
      companySlug: this.slug,
      shouldRefresh: input.shouldRefresh,
      limit: input.limit,
    })

    if (input.shouldRefresh) {
      this._updateUniqueJobFootprints(output.data.jobFootprints)
    } else {
      this._addUniqueJobFootprints(output.data.jobFootprints)
    }

    this.updateHasNextJobFootprintsPage(output.data.hasNextPage)

    return output
  }

  async fetchJobBookmarks(): Promise<boolean> {
    const output = await this.fetchJobBookmarksUseCase.handle({ companySlug: this.slug, limit: 100 })

    if (output.error) {
      this.errorsStore.handle(output.error)
      return false
    }

    this._updateJobBookmarks(output.jobBookmarks)

    return true
  }

  async fetchMyCompanyReferences(limit: number): Promise<boolean> {
    const output = await this.fetchMyCompanyReferencesUseCase.handle({ slug: this.slug, limit })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    this._updateMyCompanyReferences(output.companyReferences)
    return true
  }

  async addAngelInvitation(input: IAngelInvitationInputBase): Promise<string> {
    const output = await this.addAngelInvitationUseCase.handle({
      companyId: this.id,
      days: input.days || 7,
      emails: input.emails,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)
      return ''
    }

    return output.invitationLink.url
  }

  async addVcInvitation(input: IVcInvitationInputBase): Promise<string> {
    const output = await this.addVcInvitationUseCase.handle({
      companyId: this.id,
      days: input.days || 7,
      emails: input.emails,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)
      return ''
    }

    return output.invitationLink.url
  }

  async addVcInvestmentInvitation(input: IVcInvestmentInvitationInputBase): Promise<string> {
    const output = await this.addVcInvestmentInvitationUseCase.handle({
      companyId: this.id,
      days: input.days || 7,
      emails: input.emails,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)
      return ''
    }

    return output.invitationLink.url
  }

  async addMemberInvitation(input: IMemberInvitationInputBase): Promise<string> {
    const output = await this.addMemberInvitationUseCase.handle({
      companyId: this.id,
      days: input.days || 7,
      emails: input.emails,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)
      return ''
    }

    return output.invitationLink.url
  }

  async fetchArticles(input: { shouldRefresh: boolean; limit: number }): Promise<FetchMyCompanyArticlesUseCaseOutput> {
    const output = await this.fetchMyCompanyArticlesUseCase.handle({
      slug: this.slug,
      shouldRefresh: input.shouldRefresh,
      limit: input.limit,
      after: this.articlesEndCursor,
    })

    if (input.shouldRefresh) {
      this._updateCompanyArticles(output.data.articles)
    } else if (output.data.articles.length > 0) {
      // 取得した記事の件数が0件のときは追加しない
      this._addCompanyArticles(output.data.articles)
    }

    this.updateHasNextMyCompanyArticlesPage(output.data.hasNextPage)
    this.updateArticlesEndCursor(output.data.endCursor)

    return output
  }
}
