import { inject, injectable } from 'inversify'
import UserOffer from '@/entities/UserOffer'
import symbols from '@/symbols'
import { IErrorsStore, IUpdateMyOfferUseCase, IUserOffer, IUserOfferFactory, UserOfferFactoryInput } from '@/types'

@injectable()
export default class UserOfferFactory implements IUserOfferFactory {
  @inject(symbols.IUpdateMyOfferUseCase) private updateMyOfferUseCase: IUpdateMyOfferUseCase

  @inject(symbols.IErrorsStore) private errorStore: IErrorsStore

  public create(input: UserOfferFactoryInput): IUserOffer {
    const userOffer = new UserOffer(input.base)
    userOffer.errorsStore = this.errorStore
    userOffer.updateMyOfferUseCase = this.updateMyOfferUseCase
    return userOffer
  }
}
