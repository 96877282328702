import { AuctionHouseType, IAppErrorFactory, IUseCaseOutputFactory } from '@/types'
import {
  FindAuctionHouseUseCaseInput,
  FindAuctionHouseUseCaseOutput,
  IFindAuctionHouseUseCase,
} from '@/types/useCases/nfts'
import { PublicKey } from '@solana/web3.js'
import { inject, injectable } from 'inversify'
import symbols from '../../symbols'

@injectable()
export default class FindAuctionHouseInteractor implements IFindAuctionHouseUseCase {
  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    auctionHouse: AuctionHouseType
  }>

  async handle(input: FindAuctionHouseUseCaseInput): Promise<FindAuctionHouseUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        auctionHouse: null,
      },
    })

    try {
      const auctionHouseAddress = new PublicKey(process.env.NEXT_PUBLIC_AUCTION_HOUSE_PUBLICKEY)
      const auctionHouse = await input.metaplex.auctionHouse().findByAddress({
        address: auctionHouseAddress,
      })
      output.data.auctionHouse = auctionHouse
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
