import { inject, injectable } from 'inversify'
import {
  FetchMagazineTopPageContentUseCaseOutput,
  IAppErrorFactory,
  IArticlesService,
  IFetchMagazineTopPageContentUseCase,
} from '@/types'
import symbols from '@/symbols'

@injectable()
export default class FetchMagazineTopPageContentInteractor implements IFetchMagazineTopPageContentUseCase {
  @inject(symbols.IArticlesService) private articlesService: IArticlesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(): Promise<FetchMagazineTopPageContentUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const [stories, opinions, features, news] = await Promise.all([
        this.articlesService.fetchArticles({ categories: ['stories'], limit: 9 }),
        this.articlesService.fetchArticles({ categories: ['opinions'], limit: 9 }),
        this.articlesService.fetchArticles({ categories: ['feature'], limit: 9 }),
        this.articlesService.fetchArticles({ categories: ['news'], limit: 9 }),
      ])
      output.stories = stories.articles
      output.opinions = opinions.articles
      output.features = features.articles
      output.news = news.articles
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchMagazineTopPageContentUseCaseOutput {
    return {
      pickups: [],
      stories: [],
      opinions: [],
      features: [],
      news: [],
      error: null,
    }
  }
}
