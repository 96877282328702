import { IAppErrorFactory, INftBase, INftsService, IUseCaseOutputFactory } from '@/types'
import { FetchNftUseCaseInput, FetchNftUseCaseOutput, IFetchNftUseCase } from '@/types/useCases/nfts'
import { inject, injectable } from 'inversify'
import symbols from '../../symbols'

@injectable()
export default class FetchNftInteractor implements IFetchNftUseCase {
  @inject(symbols.INftsService) private nftsService: INftsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    nft: null | INftBase
  }>

  async handle(input: FetchNftUseCaseInput): Promise<FetchNftUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        nft: null,
      },
    })

    try {
      const nft = await this.nftsService.fetchNft(input)

      output.data.nft = nft
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
