import { GraphQLResponse } from 'graphql-request/dist/types'
import {
  AnswerBase,
  ArticleAttachmentBase,
  ArticleAttachmentInputBase,
  ArticleBase,
  ArticleInputBase,
  BlockchainBase,
  CategoryBase,
  ChoiceBase,
  ChoiceInputBase,
  CompanyBase,
  CompanyInputBase,
  CompanyMemberBase,
  CompanyMemberInputBase,
  CompanyNotificationBase,
  CompanyReferenceBase,
  CompanyReferenceStatus,
  ExperienceBase,
  ExperienceInputBase,
  FollowableEntityBase,
  FundraisingManualBase,
  InvestmentBase,
  InvestmentCommentBase,
  InvestmentInputBase,
  InvestorInputBase,
  InvitationBase,
  InvitationLinkBase,
  JobApplicationBase,
  JobAttachmentBase,
  JobAttachmentInputBase,
  JobBase,
  JobBookmarkBase,
  JobCategoryBase,
  JobFootprintBase,
  JobInputBase,
  JobTagBase,
  ListingBase,
  LocationBase,
  MarketBase,
  MeBase,
  MessageAttachmentBase,
  MessageBase,
  MessageThreadBase,
  MyCompanyBase,
  MyCompanyMemberBase,
  NewMessageNotificationBase,
  NftAttributeBase,
  NftBase,
  NftsCountBase,
  NotificationBase,
  OfferBase,
  OfferInputBase,
  ProductBase,
  ProductInputBase,
  QuestionBase,
  QuestionCategoryBase,
  QuestionInputBase,
  RoundBase,
  SkillBase,
  TokenBase,
  TokenInputBase,
  TokenTransactionBase,
  TokenTransactionInputBase,
  TopicBase,
  UserAttachmentBase,
  UserAttachmentInputBase,
  UserBase,
  UserNotificationBase,
  UserProfileBase,
  UserProfileInputBase,
  UserReferenceBase,
  UserReferenceStatus,
} from '@/lib/generated/sdk'
import { Connection, PublicKey, RpcResponseAndContext, TokenAmount, Transaction } from '@solana/web3.js'
import { Mint, Pda } from '@metaplex-foundation/js'
import { AuthorityScope } from '@metaplex-foundation/mpl-auction-house'
import { Wallet } from '@coral-xyz/anchor'
import {
  AddTokenTransactionUseCaseInput,
  AddTokenTransactionUseCaseOutput,
  AddUserAttachmentUseCaseOutput,
  CreateJobUseCaseOutput,
  CreateMyAnswerUseCaseOutput,
  CreateMyOfferUseCaseOutput,
  CreateMyQuestionUseCaseOutput,
  CreateMyReplyUseCaseOutput,
  FetchJobFootprintsUseCaseInput,
  FetchJobFootprintsUseCaseOutput,
  FetchMyCompanyArticlesUseCaseOutput,
  FetchMyJobBookmarksUseCaseInput,
  FetchMyJobsUseCaseInput,
  FetchMyJobsUseCaseOutput,
  FetchMyOffersUseCaseInput,
  FetchMyOffersUseCaseOutput,
  UpdateJobUseCaseOutput,
  UpdateMyAnswerUseCaseOutput,
  UpdateMyOfferUseCaseOutput,
  UpdateMyQuestionUseCaseOutput,
  UpdateUserAttachmentUseCaseOutput,
} from './useCases'

export * from '@/lib/generated/sdk'

export type Primitive = number | string | boolean | bigint | symbol | null | undefined

export type IOriginalErrorInstance = Error & {
  response?: GraphQLResponse
}

export interface IBaseAppError {
  // エラー集計などに使う際のための箱を用意
  originalInstance: null | IOriginalErrorInstance
  // UIに表示したいエラーメッセージがあれば設定する
  messageForUI: null | string
  tags?: {
    [key: string]: Primitive
  }
  extra?: Record<string, unknown>
}

export enum ErrorCode {
  DEFAULT = 'DEFAULT',
  NO_SUBSCRIPTION_ERROR = 'NO_SUBSCRIPTION_ERROR',
  STRIPE_INVALID_REQUEST = 'STRIPE_INVALID_REQUEST',
  STRIPE_INVALID_PROMOTION_CODE_NOT_FIRST_TIME_PAYMENT = 'STRIPE_INVALID_PROMOTION_CODE_NOT_FIRST_TIME_PAYMENT',
  NOT_AUTHORIZED_ERROR = 'NOT_AUTHORIZED_ERROR',
  NOT_SIGNED_IN = 'NOT_SIGNED_IN',
  EXISTING_RECORD_ERROR = 'EXISTING_RECORD_ERROR',
  ACTIVE_RECORD_ERROR = 'ACTIVE_RECORD_ERROR',
}

export interface IAppError extends IBaseAppError {
  code: ErrorCode
  message: string
}

export interface IUseCaseOutput<T> {
  data: null | T
  error: null | IAppError
  isSuccessful: boolean
}

export interface IAppCredentialsBase {
  accessToken: string
  client: string
  expiry: number
  tokenType: string
  uid: string
}

export interface IAppCredentials extends IAppCredentialsBase {
  isSignedIn: boolean

  update(base: IAppCredentialsBase): void
  updateAccessToken(accessToken: string): void
  restore(): boolean
  sync(): void
  clear(): void
  getLatestCredentials(): IAppCredentialsBase
}

export enum MessageType {
  Info,
  Error,
}

export enum MessageState {
  Visible,
  Invisible,
}

export interface IMessageBase {
  key?: string
  type: MessageType
  body: string
  ttl?: number // 表示時間（msec）
  isTranslated?: boolean
  isDismissable?: boolean
  state?: MessageState
}

export interface IMessage extends IMessageBase {
  hide(): void
}

export enum Language {
  EN = 'en',
}

export interface IPreferencesBase {
  language: Language
}

export interface IPreferences extends IPreferencesBase {
  restore(): boolean

  changeLanguage(language: Language): void

  update(base: IPreferencesBase): void
}

export type IViewerBase = MeBase

export type FetchNotificationsInput = {
  shouldRefresh: boolean
  limit: number
}

export type SalesTokenState = {
  totalSol: number
  totalToken: number
  rate: number
}

export interface IViewer extends IViewerBase {
  investments: IInvestment[]
  experiences: IExperience[]
  allNotifications: INotificationBase[] // ページ送りして取得してきた全ての要素を貯めるので `all` という接頭辞をつける
  hasAllNotificationsNextPage: boolean
  allNotificationsCursor: string
  articles: IUserArticle[]
  myJobBookmarks: IJobBookmarkBase[]
  myReferences: IMyReference[]
  offers: IUserOffer[]
  userAttachments: IUserAttachment[]
  solBalance: string
  associatedTokenAddresses: Record<string, ATAInfoType>
  tokenBalances: Record<string, string>
  tokenInfo: TokenInfoType
  exchangeRate: number

  userBase: IUserBase

  hasMyJobBookmarksNextPage: boolean

  shownStatusReferences: IMyReference[]

  hiddenStatusReferences: IMyReference[]

  hasSNSAccount: boolean
  hasDesiredEmploymentStatus: boolean
  basicProfileCompletionRate: number
  investorProfileCompletionRate: number
  tokensCompletionRate: number
  jobSeekerProfileCompletionRate: number
  myProfileCompletionRate: number
  latestNotifications: INotificationBase[]
  resumes: IUserAttachment[]
  portfolios: IUserAttachment[]

  update(base: IViewerBase): void

  updateProfile(profile: IUserProfile): void

  updateName(name: string): void

  updateUsername(username: string): void

  addMyCompany(companyMember: IMyCompanyMember): void

  getMyCompanyBySlug(slug: string): IMyCompany

  isCompanyMember(slug: string): boolean

  addExperience(experience: IExperienceInputBase): Promise<boolean>

  removeExperience(experience: IExperience): Promise<boolean>

  addMyInvestment(investment: IInvestmentInputBase): Promise<boolean>

  removeInvestment(investment: IInvestment): Promise<boolean>

  markAllNotificationsAsRead(): Promise<boolean>

  fetchMyArticles(): Promise<boolean>

  addMyArticle(article: IArticleInputBase): Promise<boolean>

  removeMyArticle(id: string): Promise<boolean>

  addArticleAttachment(input: IArticleAttachmentInputBase): Promise<IArticleAttachmentBase>

  fetchMyJobBookmarks(input: FetchMyJobBookmarksInput): Promise<boolean>

  addMyJobBookmark(jobId: string): Promise<boolean>

  removeMyJobBookmark(jobId: string): Promise<boolean>

  isInMyJobBookmarks(jobId: string): boolean

  addUserReference(username: string, comment: string): Promise<IUserReferenceBase>

  updateUserReference(id: string, comment: string): Promise<IUserReferenceBase>

  removeUserReference(id: string): Promise<IUserReferenceBase>

  addCompanyReference(slug: string, comment: string): Promise<ICompanyReferenceBase>

  updateCompanyReference(id: string, comment: string): Promise<ICompanyReferenceBase>

  removeCompanyReference(id: string): Promise<ICompanyReferenceBase>

  fetchMyReferences(limit: number): Promise<boolean>

  addAngelInvestmentInvitation(input: IAngelInvestmentInvitationInputBase): Promise<string>

  addUserInvitation(input: IUserInvitationInputBase): Promise<string>

  toggleFollowCompany(slug: string): Promise<ICompanyBase>

  toggleFollowUser(username: string): Promise<IUserBase>

  updateCompanyNotificationSettings(companySlug: string, notificationIds: string[]): Promise<ICompanyBase>

  updateUserNotificationSettings(username: string, notificationIds: string[]): Promise<IUserBase>

  fetchNotifications(input: FetchNotificationsInput): Promise<boolean>

  fetchMyOffers(input: FetchMyOffersUseCaseInput): Promise<FetchMyOffersUseCaseOutput>

  addMyOffer(offer: IOfferInputBase): Promise<CreateMyOfferUseCaseOutput>

  removeMyOffer(id: string): Promise<boolean>

  addUserAttachment(userAttachment: IUserAttachmentInputBase): Promise<AddUserAttachmentUseCaseOutput>

  deleteUserAttachment(id: string): Promise<boolean>

  addMyQuestion(question: IQuestionInputBase, choices: IChoiceInputBase[]): Promise<CreateMyQuestionUseCaseOutput>

  updateMyQuestion(id: string, question: IQuestionInputBase): Promise<UpdateMyQuestionUseCaseOutput>

  removeMyQuestion(id: string): Promise<boolean>

  addMyAnswer(slug: string, body: string): Promise<CreateMyAnswerUseCaseOutput>

  addMyReply(slug: string, body: string): Promise<CreateMyReplyUseCaseOutput>

  updateMyAnswer(id: string, body: string): Promise<UpdateMyAnswerUseCaseOutput>

  removeMyAnswer(id: string): Promise<IAnswerBase>

  getSolBalance(userSolanaAddress: PublicKey, connection: Connection): Promise<boolean>

  updateSolBalance(amount: string): void

  findAssociatedTokenAddress(userSolanaAddress: PublicKey, connection: Connection): Promise<boolean>

  getTokenAcccountBalance(
    associatedTokenAddress: PublicKey,
    connection: Connection
  ): Promise<RpcResponseAndContext<TokenAmount>>

  updateTokenAcccountBalance(
    publicKey: PublicKey,
    associatedTokenAddress: PublicKey,
    connection: Connection
  ): Promise<boolean>

  findATAOrUpdateBalance(userSolanaAddress: PublicKey, connection: Connection): Promise<boolean>

  getTokenInfo(connection: Connection): Promise<boolean>

  existATA(connection: Connection, ata: PublicKey): Promise<boolean>

  transferToken(
    connection: Connection,
    toAddress: string,
    fromPublicKey: PublicKey,
    amount: string
  ): Promise<Transaction>

  addTokenTransaction(tokenTransaction: AddTokenTransactionUseCaseInput): Promise<AddTokenTransactionUseCaseOutput>

  fetchSalesTokenState(connection: Connection, wallet: Wallet): Promise<boolean>

  swapSolToToken(connection: Connection, wallet: Wallet, publicKey: PublicKey, lamports: string): Promise<Transaction>

  // swapTokenToSol(connection: Connection, wallet: Wallet, publicKey: PublicKey, amount: string): Promise<Transaction>
}

// ============================================================
// User
// ============================================================
export type IUserProfileInputBase = UserProfileInputBase
export type IUserProfileBase = UserProfileBase
export type IUserProfile = IUserProfileBase
export type IUserBase = UserBase
export type IUser = IUserBase

export interface IPublicUser extends IUserBase {
  hasSNSAccount: boolean
  update(base: IUserBase): void
  fetch(): Promise<boolean>
}

// ============================================================
// Company
// ============================================================
export enum RoundType {
  UNKNOWN = '1',
  PRESEED = '2',
  SEED = '3',
  SERIES_A = '4',
  SERIES_B = '5',
  SERIES_C = '6',
  SERIES_D = '7',
  SERIES_E = '8',
  SERIES_F = '9',
  SERIES_G = '10',
  ACQUIRED = '11',
  IPO = '12',
  CLOSED = '13',
}

export type IRoundBase = RoundBase
export type IRound = IRoundBase

export type ICompanyBase = CompanyBase
export type ICompanyInputBase = CompanyInputBase
export type IMyCompanyBase = MyCompanyBase

export interface IPublicCompany extends ICompanyBase {
  update(base: ICompanyBase): void
  fetch(): Promise<boolean>
}

export interface IMyCompany extends IMyCompanyBase {
  products: IProduct[]
  investments: IInvestment[]
  companyMembers: IMyCompanyMember[]
  allArticles: ICompanyArticle[]
  markets: IMarketBase[]
  investmentTargetMarkets: IMarketBase[]
  investmentTargetRounds: IRoundBase[]
  locations: ILocationBase[]
  allJobs: IJob[]
  allJobFootprints: IJobFootprintBase[]
  uniqueJobFootprints: IJobFootprint[]
  hasNextJobFootprintsPage: boolean
  receivedInvestments: IInvestment[]
  allJobBookmarks: IJobBookmarkBase[]
  myCompanyReferences: IMyCompanyReference[]
  hasNextMyCompanyArticlesPage: boolean
  articlesEndCursor: string
  hasNextMyJobsPage: boolean

  ownerAndRecruiters: IMyCompanyMember[]

  shownStatusReferences: IMyCompanyReference[]

  hiddenStatusReferences: IMyCompanyReference[]

  hasSNSAccount: boolean
  basicProfileCompletionRate: number
  startupProfileCompletionRate: number
  vcProfileCompletionRate: number
  companyProfileCompletionRate: number

  save(company: ICompanyInputBase): Promise<boolean>

  addProduct(productBase: IProductInputBase): Promise<boolean>

  removeProduct(product: IProduct): Promise<boolean>

  addCompanyInvestment(input: IInvestmentInputBase): Promise<boolean>

  addInvestor(input: IInvestorInputBase): Promise<boolean>

  removeInvestment(product: IInvestment): Promise<boolean>

  removeInvestor(product: IInvestment): Promise<boolean>

  addMember(companyMember: ICompanyMemberInputBase): Promise<boolean>

  removeMember(companyMember: IMyCompanyMember): Promise<boolean>

  addCompanyArticle(article: IArticleInputBase): Promise<boolean>

  removeArticle(article: IArticleBase): Promise<boolean>

  removeCompanyArticle(id: string): Promise<boolean>

  addArticleAttachment(input: IArticleAttachmentInputBase): Promise<IArticleAttachmentBase>

  fetchMyJobs(input: FetchMyJobsUseCaseInput): Promise<FetchMyJobsUseCaseOutput>

  fetchJobFootprints(input: FetchJobFootprintsUseCaseInput): Promise<FetchJobFootprintsUseCaseOutput>

  createJob(jobBase: IJobInputBase): Promise<CreateJobUseCaseOutput>

  duplicateJob(jobId: string): Promise<boolean>

  deleteJob(jobId: string): Promise<boolean>

  fetchJobBookmarks(): Promise<boolean>

  addJobAttachment(input: IJobAttachmentInputBase): Promise<IJobAttachmentBase>

  addAngelInvitation(input: IAngelInvitationInputBase): Promise<string>

  addVcInvitation(input: IVcInvitationInputBase): Promise<string>

  addVcInvestmentInvitation(input: IVcInvestmentInvitationInputBase): Promise<string>

  addMemberInvitation(input: IMemberInvitationInputBase): Promise<string>

  fetchMyCompanyReferences(limit: number): Promise<boolean>

  fetchArticles(input: { shouldRefresh: boolean; limit: number }): Promise<FetchMyCompanyArticlesUseCaseOutput>
}

export type ICompanyMemberBase = CompanyMemberBase
export type ICompanyMemberInputBase = CompanyMemberInputBase
export type IMyCompanyMemberBase = MyCompanyMemberBase

export interface IMyCompanyMember extends IMyCompanyMemberBase {
  roleName: string

  company: IMyCompany

  save(companyMember: ICompanyMemberInputBase): Promise<boolean>
}

// ============================================================
// Experience
// ============================================================
// TODO: サーバー・フロント両方 status を enum に変更
export type IExperienceInputBase = ExperienceInputBase
export type IExperienceBase = ExperienceBase

export interface IExperience extends IExperienceBase {
  base: IExperienceBase
  inputBase: IExperienceInputBase

  save(experience: IExperienceInputBase): Promise<boolean>
}

// ============================================================
// Product
// ============================================================
export type IProductBase = ProductBase
export type IProductInputBase = ProductInputBase

export interface IProduct extends IProductBase {
  id: string

  save(product: IProductInputBase): Promise<boolean>
}

// ============================================================
// Investment
// ============================================================
export type IInvestmentBase = InvestmentBase
export type IInvestmentInputBase = InvestmentInputBase

export type IInvestorInputBase = InvestorInputBase
export enum InvestorType {
  ANGEL = 'angel',
  VC = 'vc',
}

export interface IInvestment extends IInvestmentBase {
  save(investment: IInvestmentInputBase): Promise<boolean>
  saved_by_startup(investment: IInvestorInputBase): Promise<boolean>
}

// ============================================================
// InvestmentComment
// ============================================================
export type IInvestmentCommentBase = InvestmentCommentBase
export type IInvestmentComment = IInvestmentCommentBase

// ============================================================
// Market
// ============================================================
export type IMarketBase = MarketBase
export type IMarket = IMarketBase

// ============================================================
// Article
// ============================================================
export type IArticleCategoryBase = CategoryBase
export type IArticleTopicBase = TopicBase
export type IFundraisingManualBase = FundraisingManualBase
export type IArticleBase = ArticleBase
export type IArticleInputBase = ArticleInputBase
export type IArticleCategory = IArticleCategoryBase
export type IArticleTopic = IArticleTopicBase
export type IArticle = IArticleBase

export interface IPublicArticle extends IArticleBase {
  isDraftLiked: boolean

  toggleArticleLikedState(): Promise<boolean>
  update(base: IArticleBase): void
  draftToggleArticleLikedState(): void
  fetch(): Promise<boolean>
}

export interface ICompanyArticle extends IArticleBase {
  statusName: string
  isPublished: boolean

  save(article: IArticleInputBase): Promise<boolean>
}

export interface IUserArticle extends IArticleBase {
  statusName: string
  isPublished: boolean

  save(article: IArticleInputBase): Promise<boolean>
}

export type MagazineAsideContents = {
  dailyRanking: IArticle[]
  weeklyRanking: IArticle[]
  monthlyRanking: IArticle[]
}

export type MagazineTopPageContents = {
  pickups: IArticle[]
  stories: IArticle[]
  opinions: IArticle[]
  news: IArticle[]
  features: IArticle[]
}

export type MagazineArchivePageContents = {
  articles: IArticle[]
}

export type MagazineSinglePageContents = {
  article: IArticle
  recommendedArticles: IArticle[]
}

export type CompanyArticleSinglePageContents = {
  article: IArticle
  recommendedArticles: IArticle[]
}

export type UserArticleSinglePageContents = {
  article: IArticle
  recommendedArticles: IArticle[]
}

// ============================================================
// ArticleAttachment
// ============================================================
export type IArticleAttachmentBase = ArticleAttachmentBase
export type IArticleAttachmentInputBase = ArticleAttachmentInputBase

// ============================================================
// Chat
// ============================================================
// Message という名前は Toast で使ってしまってるので Chat を接頭辞につける
export type IChatMessageBase = MessageBase
export type IChatMessage = IChatMessageBase

export interface QueueForSendingMessage {
  deduplicationId: string
  body: string
  messageAttachments?: IMessageAttachment[]
}

export type IChatMessageThreadBase = MessageThreadBase

export interface IChatMessageThread extends IChatMessageThreadBase {
  allMessages: IChatMessage[] // ページ送りして取得してきた全ての要素を貯めるので `all` という接頭辞をつける
  hasUnreadMessage: boolean
  latestMessage: IChatMessage

  // 送信するメッセージは一旦ここに格納し、webSocket でデータが返ったときに削除する
  queueForSendingMessages: QueueForSendingMessage[]

  hasNextMessagesPage: boolean
  isInitialized: boolean // 最初の追加読み込みが終わっているか(ページ送りではなく)

  draftText: string
  draftAttachments: IMessageAttachment[]

  viewer: IViewer

  updateDraftText(draftText: string): void
  resetDraftText(): void

  addDraftAttachment(draftAttachment: IMessageAttachment): void
  removeDraftAttachment(draftAttachment: IMessageAttachment): void
  resetDraftAttachments(): void

  updateIsInitialized(isInitialized: boolean): void

  sendMessage(input: IChatMessageInputBase): boolean

  fetchChatMessages(input: { shouldRefresh: boolean }): Promise<boolean>

  getUserToTalkWith(myUsername: string): IUserBase

  markAllMessagesAsRead(input: { reader: IUserBase }): Promise<boolean>
}

export enum ChatMessageBroadCastingType {
  CREATED = 'created',
  UPDATED = 'updated',
}

export type IMessageAttachment = MessageAttachmentBase

export interface IChatMessageInputBase {
  messageBody: string
  messageFiles: IMessageAttachment[]
}

// ============================================================
// Notifications
// ============================================================
export type INotificationBase = NotificationBase
export type INotification = INotificationBase
export type NewMessageNotification = NewMessageNotificationBase

// ============================================================
// Locations
// ============================================================
export type ILocationBase = LocationBase

export type ILocation = ILocationBase

// ============================================================
// JobCategories
// ============================================================
export type IJobCategoryBase = JobCategoryBase
export type IJobCategory = IJobCategoryBase

// ============================================================
// JobTag
// ============================================================
export type IJobTagBase = JobTagBase
export type IJobTag = IJobTagBase

// ============================================================
// Skills
// ============================================================
export type ISkillBase = SkillBase
export type ISkill = ISkillBase

// ============================================================
// Jobs
// ============================================================
export enum TokenGrant {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

export type IJobBase = JobBase
export type IJobInputBase = JobInputBase
export interface IJob extends IJobBase {
  statusName: string
  isPublished: boolean

  save(job: IJobInputBase): Promise<UpdateJobUseCaseOutput>
}

// ============================================================
// JobAttachment
// ============================================================
export type IJobAttachmentBase = JobAttachmentBase
export type IJobAttachmentInputBase = JobAttachmentInputBase

// ============================================================
// JobApplication
// ============================================================
export type IJobApplicationBase = JobApplicationBase
export interface IJobApplication extends IJobApplicationBase {
  messageThread: IChatMessageThread
}

export type PaymentInfo = {
  couponCode: string
  companyName: string
  representativeName: string
  representativeRuby: string
  representativePosition: string
  email: string
  phoneNumber: string
}

// ============================================================
// JobBookmark
// ============================================================
export type IJobBookmarkBase = JobBookmarkBase
export type FetchMyJobBookmarksInput = FetchMyJobBookmarksUseCaseInput

// ============================================================
// Invitation
// ============================================================
export type IInvitationBase = InvitationBase
export type IInvitationLinkBase = InvitationLinkBase
export type IAngelInvitationInputBase = {
  days?: number
  emails?: string[]
}
export type IVcInvitationInputBase = {
  days?: number
  emails?: string[]
}
export type IAngelInvestmentInvitationInputBase = {
  days?: number
  emails?: string[]
}
export type IVcInvestmentInvitationInputBase = {
  days?: number
  emails?: string[]
}
export type IMemberInvitationInputBase = {
  days?: number
  emails?: string[]
}
export type IUserInvitationInputBase = {
  days?: number
  emails?: string[]
}

// ============================================================
// JobFootprint
// ============================================================
export type IJobFootprintBase = JobFootprintBase
export interface IJobFootprint extends IJobFootprintBase {
  children: IJobFootprintBase[]
}

// ============================================================
// UserReference
// ============================================================
export type IUserReferenceBase = UserReferenceBase
export interface IMyReference extends IUserReferenceBase {
  save(status: UserReferenceStatus): Promise<boolean>
}

// ============================================================
// CompanyReference
// ============================================================
export type ICompanyReferenceBase = CompanyReferenceBase
export interface IMyCompanyReference extends ICompanyReferenceBase {
  save(status: CompanyReferenceStatus): Promise<boolean>
}

// ============================================================
// Follow, Follower
// ============================================================
export type IFollowableEntityBase = FollowableEntityBase
export interface IPublicFollowableEntity extends IFollowableEntityBase {
  update(base: IFollowableEntityBase): void
  updateFromUser(base: IUserBase): void
  updateFromCompany(base: ICompanyBase): void
}

// ============================================================
// UserNotification
// ============================================================
export type IUserNotificationBase = UserNotificationBase

export enum UserNotificationType {
  JobSeekingStatusUpdated = '1',
}

// ============================================================
// CompanyNotification
// ============================================================
export type ICompanyNotificationBase = CompanyNotificationBase

export enum CompanyNotificationType {
  FundraisingStatusUpdated = '1',
  NewJobOpening = '2',
}

// ============================================================
// Blockchain
// ============================================================
export type IBlockchainBase = BlockchainBase
export type IBlockchain = IBlockchainBase

// ============================================================
// Token
// ============================================================
export type ITokenBase = TokenBase
export type IToken = ITokenBase
export type ITokenInputBase = TokenInputBase

// ============================================================
// Offer
// ============================================================
export type IOfferBase = OfferBase
export type IOfferInputBase = OfferInputBase
export interface IUserOffer extends IOfferBase {
  statusName: string
  isPublished: boolean

  save(offer: IOfferInputBase): Promise<UpdateMyOfferUseCaseOutput>
}

// ============================================================
// UserAttachment
// ============================================================
export type IUserAttachmentBase = UserAttachmentBase
export type IUserAttachmentInputBase = UserAttachmentInputBase

export interface IUserAttachment extends IUserAttachmentBase {
  save(userAttachment: IUserAttachmentInputBase): Promise<UpdateUserAttachmentUseCaseOutput>
}

// ============================================================
// QuestionCategories
// ============================================================
export type IQuestionCategoryBase = QuestionCategoryBase
export type IQuestionCategory = IQuestionCategoryBase

// ============================================================
// Question
// ============================================================
export type IQuestionBase = QuestionBase
export type IQuestionInputBase = QuestionInputBase
export interface IUserQuestion extends IQuestionBase {
  statusName: string
  isPublished: boolean

  save(question: IQuestionInputBase): Promise<UpdateMyQuestionUseCaseOutput>
  voteForChoice(id: string): Promise<boolean>
  fetch(): Promise<boolean>
}

// ============================================================
// Choice
// ============================================================
export type IChoiceBase = ChoiceBase
export type IChoiceInputBase = ChoiceInputBase

// ============================================================
// Answer
// ============================================================
export type IAnswerBase = AnswerBase
export interface IUserAnswer extends IAnswerBase {
  statusName: string
}

// ============================================================
// Token
// ============================================================
export type TokenInfoType = {
  image?: string
  symbol?: string
  decimals?: number
}

// その associatedTokenAddress が存在するかしないかを含む type
export type ATAInfoType = {
  ata?: PublicKey
  isExist: boolean
}

export type ITokenTransactionBase = TokenTransactionBase
export type ITokenTransactionInputBase = TokenTransactionInputBase

// ============================================================
// Nfts
// ============================================================
export type INftBase = NftBase
export type INftsCountBase = NftsCountBase
export type IListingBase = ListingBase
export type INftAttributeBase = NftAttributeBase

// ============================================================
// AuctionHouse
// ============================================================
// AuctionHouse の型定義がされていないので metaplex.auctionHouse().findByAddress のアウトプットを AuctionHouse として定義
export type AuctionHouseType =
  | Readonly<
      {
        model: 'auctionHouse'
        address: Pda
        creatorAddress: PublicKey
        authorityAddress: PublicKey
        treasuryMint: Mint
        feeAccountAddress: Pda
        treasuryAccountAddress: Pda
        feeWithdrawalDestinationAddress: PublicKey
        treasuryWithdrawalDestinationAddress: PublicKey
        sellerFeeBasisPoints: number
        requiresSignOff: boolean
        canChangeSalePrice: boolean
        isNative: boolean
        scopes: AuthorityScope[]
      } & {
        hasAuctioneer: false
      }
    >
  | Readonly<
      {
        model: 'auctionHouse'
        address: Pda
        creatorAddress: PublicKey
        authorityAddress: PublicKey
        treasuryMint: Mint
        feeAccountAddress: Pda
        treasuryAccountAddress: Pda
        feeWithdrawalDestinationAddress: PublicKey
        treasuryWithdrawalDestinationAddress: PublicKey
        sellerFeeBasisPoints: number
        requiresSignOff: boolean
        canChangeSalePrice: boolean
        isNative: boolean
        scopes: AuthorityScope[]
      } & {
        hasAuctioneer: true
        auctioneer: {
          address: PublicKey
          authority: PublicKey
        }
      }
    >
