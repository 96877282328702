import { inject, injectable } from 'inversify'
import {
  CreateArticleAttachmentUseCaseInput,
  CreateArticleAttachmentUseCaseOutput,
  IAppErrorFactory,
  IArticleAttachmentService,
  ICreateArticleAttachmentUseCase,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class CreateArticleAttachmentInteractor implements ICreateArticleAttachmentUseCase {
  @inject(symbols.IArticleAttachmentService) private articleAttachmentService: IArticleAttachmentService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: CreateArticleAttachmentUseCaseInput): Promise<CreateArticleAttachmentUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.articleAttachment = await this.articleAttachmentService.createArticleAttachment(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: '画像のアップロードに失敗しました。1MB以下のファイルを選択してください。',
      })
    }

    return output
  }

  private getDefaultOutputData(): CreateArticleAttachmentUseCaseOutput {
    return {
      articleAttachment: null,
      error: null,
    }
  }
}
