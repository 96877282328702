import { IAppErrorFactory, INftBase, INftsService, IUseCaseOutputFactory } from '@/types'
import { FetchNftsUseCaseInput, FetchNftsUseCaseOutput, IFetchNftsUseCase } from '@/types/useCases/nfts'
import { inject, injectable } from 'inversify'
import symbols from '../../symbols'

@injectable()
export default class FetchNftsInteractor implements IFetchNftsUseCase {
  @inject(symbols.INftsService) private nftsService: INftsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    nfts: null | INftBase[]
    hasNextPage: null | boolean
  }>

  async handle(input: FetchNftsUseCaseInput): Promise<FetchNftsUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        nfts: null,
        hasNextPage: null,
      },
    })

    try {
      const { nfts, hasNextPage } = await this.nftsService.fetchNfts(input)

      output.data.nfts = nfts
      output.data.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
